import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import UserService from '@service/useService';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectRole, setRoles, setRoleSelected } from '../store/roleSlice';


export const SelectRole = () => {
    const dispatch = useDispatch();

    const { list, selected } = useSelector(selectRole);

    const onRoleSelected = (index) => {
        dispatch(setRoleSelected(null));
        if (index === -1) {
        } else {
            let role = list[index];
            dispatch(setRoleSelected(role));
        }
    }

    useEffect(() => {
        UserService.getRoles(dispatch).then(({ data: res }) => {
            if (res.data) {
                dispatch(setRoles(res.data))
            }
        });
    }, []);

    return (
        <FormControl className="sm:w-216 -translate-y-6" variant="outlined" size='small'>
            <InputLabel id="bank-select-label">Role</InputLabel>
            <Select
                labelId="bank-select-label"
                id="bank-select"
                label="Role"
                value={selected?.name ?? "ALL"}
            >
                <MenuItem value="ALL" onClick={() => onRoleSelected(-1)}>
                    Tất cả
                </MenuItem>
                {list.map((b, index) => (
                    <MenuItem value={b.name} key={b.name} onClick={() => onRoleSelected(index)}>
                        {b.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl >
    )
}