import React from 'react';
import { TableCell, TableHead, TableRow, lighten } from '@mui/material';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { selectColFilter } from '../../store/colFilterSlice';
import { selectRowFilter } from '../../store/rowFilterSlice';
import { selectBank } from '../../store/bankSlice';

export const colsHistory = [
	{
		id: 'contractId',
		align: 'left',
		label: 'Số KU',
		display: true,
		disablePadding: true,
		sort: false,
		className: "",
		moreStyle: { width: 160 },
		requireFilter: false,
		isConditionCol: true,
		valueType: 'text',
		customList: null,
	},
	{
		id: 'productId',
		align: 'left',
		label: 'Mã hàng',
		display: true,
		disablePadding: true,
		sort: false,
		className: "",
		moreStyle: { width: 180 },
		requireFilter: false,
		isConditionCol: true,
		valueType: 'text',
		customList: null,
	},
	{
		id: 'sourceStorageName',
		align: 'center',
		label: 'Từ kho',
		display: true,
		disablePadding: true,
		sort: false,
		className: "",
		moreStyle: { width: 130 },
		requireFilter: false,
		isConditionCol: true,
		valueType: 'text',
		customList: null,
	},
	{
		id: 'targetStorageName',
		align: 'center',
		label: 'Đến kho',
		display: true,
		disablePadding: true,
		sort: false,
		className: "",
		moreStyle: { width: 130 },
		requireFilter: false,
		isConditionCol: true,
		valueType: 'text',
		customList: null,
	},
	{
		id: 'transactionCreatedAt',
		align: 'center',
		label: 'Ngày tạo',
		display: true,
		disablePadding: true,
		sort: true,
		className: "",
		moreStyle: { width: 95 },
		requireFilter: false,
		isConditionCol: true,
		valueType: 'datetime',
		customList: null,
	},
	{
		id: 'actualTransactionReleaseQuantity',
		align: 'center',
		label: "Số lượng",
		sublabel: "(Tấn)",
		display: true,
		disablePadding: true,
		sort: false,
		className: "",
		moreStyle: { width: 90 },
		requireFilter: false,
		isConditionCol: true,
		valueType: 'number',
		customList: null,
		sum: true,
	},
	{
		id: 'transactionType',
		align: 'center',
		label: 'Loại giao dịch',
		display: true,
		disablePadding: true,
		sort: false,
		className: "",
		moreStyle: { width: 120 },
		requireFilter: false,
		isConditionCol: true,
		valueType: 'text',
		customList: [
			{
				key: "PAID_EXPORT",
				name: "Giải chấp",
			},
			{
				key: "ONLY_PAID",
				name: "Trả giải chấp",
			},
			{
				key: "ONLY_EXPORT",
				name: "Nợ giải chấp",
			},
			{
				key: "IMPORT_TO_STORAGE",
				name: "Hàng cảng",
			},
			{
				key: "INIT_ORDER",
				name: "Nhập Cảng"
			}
		],
	},
	{
		id: 'transactionUpdatedBy',
		display: true,
		align: 'center',
		disablePadding: false,
		label: "Người cập nhật",
		sort: true,
		requireFilter: false,
		moreStyle: { width: 130 },
		isConditionCol: true,
		valueType: 'text'
	},
	{
		id: 'transactionStatus',
		align: 'center',
		label: "Trạng thái",
		display: true,
		disablePadding: true,
		sort: false,
		className: "",
		moreStyle: { width: 90, },
		requireFilter: false,
		isConditionCol: true,
		valueType: 'text',
		customList: [
			{
				key: "ACTIVE",
				name: "Hoạt động",
			},
			{
				key: "CANCELED",
				name: "Đã hủy",
			},
		],
	},
	{
		id: 'note',
		align: 'left',
		label: "Ghi chú",
		display: true,
		disablePadding: false,
		sort: false,
		className: "",
		moreStyle: { width: 200 },
		requireFilter: false,
		isConditionCol: false,
		valueType: 'text',
		customList: null,
	},
];

const HistoryTableHead = () => {
	const { selected: bankSelected } = useSelector(selectBank);
	const filterCol = useSelector(selectColFilter("history-" + bankSelected?.bankId));
	const filterRow = useSelector(selectRowFilter("history-" + bankSelected?.bankId));

	return (
		<TableHead>
			<TableRow className={clsx("sticky h-40 z-20", (filterRow ?? []).length > 0 ? "top-48" : "top-0")}>
				{colsHistory.map((row, index) => {
					if (!row.display) return null;
					if (filterCol != null && Object.keys(filterCol ?? {}).length > 0) {
						if (filterCol[row.id] !== true && row.requireFilter == false) return null;
					}
					return (
						<TableCell
							key={index}
							sx={{
								backgroundColor: (theme) => lighten(theme.palette.background.default, 0.4),
							}}
							className={clsx("py-0 px-8 m-0 font-semibold", row.className != null ? "z-50" : "", row.className)}
							style={row.moreStyle}
							align={row.align}
							scope='row'
						>
							<div style={{ ...row.moreStyle, textAlign: row.align }}>
								{row.label.split("").map((c, i) => c === "\n" ? <br key={i} /> : c)}
								{!!row.sublabel && <><br />{row.sublabel}</>}
							</div>
						</TableCell>
					)
				})}
			</TableRow>
		</TableHead>
	);
}

export default HistoryTableHead;
