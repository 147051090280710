
import React, { useState } from 'react';
import moment from 'moment';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    TableCell,
    TableRow,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';

import { selectColFilter } from '../../store/colFilterSlice';
import { colsHistory as colsHistory } from './HistoryTableHead';
import { selectStorages } from '../../store/storageSlice';
import { closeDialog, openDialog } from 'app/store/adg/dialogSlice';
import OrderTransactionService from '@service/orderTransactionService';
import { MESSAGE_STATUS, showMessageWithStatus } from 'app/store/adg/messageSlice';
import { selectRowFilter, setRowFilter } from '../../store/rowFilterSlice';
import { selectBank } from '../../store/bankSlice';
import { FilterInCell } from '../../components/FilterInCell';
import SvgIcon from '@adg/core/SvgIcon';

const TRANSACTION_TYPE = {
    PAID_EXPORT: "Giải chấp",
    ONLY_PAID: "Trả giải chấp",
    ONLY_EXPORT: "Nợ giải chấp",
    IMPORT_TO_STORAGE: "Hàng cảng",
    INIT_ORDER: "Nhập Cảng"
}

const HistoryTableRow = ({ item, forceUpdate, isEmptyRow = false, onOpenChangeDialog, className, style }) => {
    const dispatch = useDispatch();
    const { selected: bankSelected } = useSelector(selectBank);
    const filterCol = useSelector(selectColFilter("history-" + bankSelected?.bankId));
    const filterRow = useSelector(selectRowFilter("history-" + bankSelected?.bankId));

    const [isShowSub, hadShowSub] = useState(false);
    const [note, setNote] = useState(item.transactionNote);

    // const filter = useSelector(selectFilter);
    const storageSelected = useSelector(selectStorages);

    const defineFilter = filterCol ? filterCol : {};

    const onCancelCommand = () => {
        dispatch(closeDialog());
        OrderTransactionService.cancelStatus(item.transactionId, dispatch).then((res) => {
            dispatch(showMessageWithStatus("Đã hủy lệnh cho mã hàng: " + item.productId, MESSAGE_STATUS.success));
            forceUpdate();
        });
    }

    const onConfirmChange = () => {
        dispatch(openDialog({
            children: (
                <>
                    <DialogTitle id="alert-dialog-title">Xác nhận</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Bạn có muốn hủy lệnh?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => dispatch(closeDialog())} color="primary">
                            Không
                        </Button>
                        <Button onClick={onCancelCommand} color="primary" autoFocus >
                            Có
                        </Button>
                    </DialogActions>
                </>
            )
        }));
    }

    const onUpdateNotePress = (e) => {
        if (e?.keyCode === 13) {
            onUpdateNote();
        }
    }

    const onUpdateNote = () => {
        let body = {
            orderTransactions: [{
                orderTransactionId: item.transactionId,
                note
            }]
        };
        if (item.note != note) {
            OrderTransactionService.updateNote(body, dispatch).then((res) => {
                dispatch(showMessageWithStatus("Đã cập nhật ghi chú", MESSAGE_STATUS.success));
            });
        }
    }

    const onAddRowFilter = (columnKey, type, val, objectList) => {
        if (type === "datetime") {
            val = moment(val).format("YYYY-MM-DD");
        }
        let data = {
            columnId: columnKey,
            columnType: type,
            customList: objectList ? Object.entries(objectList).map(([key, name]) => ({ key, name })) : null,
            condition: {
                id: objectList ? "là" : type === "text" ? "bằng" : "=",
                numVal: 1,
                operator: "equal",
                vals: [
                    val
                ]
            }
        };
        let rootFilter = filterRow ?? [];
        let newFilter = [...rootFilter, data];
        dispatch(setRowFilter({
            id: "history-" + bankSelected.bankId,
            list: newFilter,
        }));
    }

    const cells = [
        {
            textStyle: "font-bold",
            content: isEmptyRow ? "Tổng:" :
                <FilterInCell className='font-bold' name="số KU" onClick={() => onAddRowFilter("contractId", "text", item.contractId)}>
                    <Tooltip title={item.contractId} placement='top'>
                        <span>{item.contractId}</span>
                    </Tooltip>
                </FilterInCell>
        },
        {
            textStyle: isEmptyRow ? "font-bold" : "",
            content: isEmptyRow ? "" :
                <FilterInCell className='font-bold' name="mã sản phẩm" onClick={() => onAddRowFilter("productId", "text", item.productId)}>
                    <Tooltip title={item.productId} placement='top'>
                        <span>{item.productId}</span>
                    </Tooltip>
                </FilterInCell>
        },
        {
            textStyle: isEmptyRow ? "font-bold" : "",
            content: isEmptyRow ? "" :
                <FilterInCell name="từ kho" onClick={() => onAddRowFilter("sourceStorageName", "text", item.sourceStorageName)}>
                    {item.sourceStorageName}
                </FilterInCell>
        },
        {
            textStyle: isEmptyRow ? "font-bold" : "",
            content: isEmptyRow ? "" :
                <FilterInCell name="đến kho" onClick={() => onAddRowFilter("targetStorageName", "text", item.targetStorageName)}>
                    {item.targetStorageName}
                </FilterInCell>
        },
        {
            textStyle: isEmptyRow ? "font-bold" : "",
            content: isEmptyRow ? "" :
                <FilterInCell name="ngày tạo" onClick={() => onAddRowFilter("transactionCreatedAt", "datetime", item.transactionCreatedAt)}>
                    {moment(item.transactionCreatedAt).format("DD/MM/YYYY")}
                </FilterInCell>
        },
        {
            textStyle: (isEmptyRow ? "font-bold" : "") + " text-center",
            content: isEmptyRow ? (item.actualTransactionReleaseQuantity / 1000).toLocaleString("vi-VN") :
                <Box className="flex items-center justify-center">
                    <div className='flex-1 text-right'>
                        {(item.actualTransactionReleaseQuantity / 1000).toLocaleString("vi-VN")}
                    </div>
                    <div className='flex-1'>
                        {item.transactionType === 'PAID_EXPORT' && item.transactionStatus == "ACTIVE" &&
                            <Tooltip title="Thay đổi giá trị">
                                <IconButton
                                    className='ml-8 p-8 rounded-full border-1 shadow-0 text-dar hover:text-white hover:bg-gray-800'
                                    onClick={(e) => { e.stopPropagation(); onOpenChangeDialog(); }}
                                >
                                    <SvgIcon size={18}>
                                        heroicons-outline:pencil
                                    </SvgIcon>
                                </IconButton>
                            </Tooltip>
                        }
                    </div>
                </Box>
        },
        {
            textStyle: isEmptyRow ? "font-bold" : "",
            content: isEmptyRow ? "" : <>
                <FilterInCell className='' name="loại giao dịch" onClick={() => onAddRowFilter("transactionType", "text", item.transactionType, TRANSACTION_TYPE)}>
                    {TRANSACTION_TYPE[item.transactionType]}
                    {!Object.keys(TRANSACTION_TYPE).includes(item.transactionType) &&
                        <span className='font-bold text-red italic text-left'>Chưa rõ<br />giao dịch</span>
                    }
                </FilterInCell>
            </>
        },
        {
            textStyle: isEmptyRow ? "font-bold" : "",
            content: item.transactionUpdatedBy
        },
        {
            textStyle: "text-center",
            content: isEmptyRow ? "" :
                <Box className='h-24 flex items-center justify-center' >
                    {item.transactionStatus == "ACTIVE" ?
                        <Button className='bg-dark-theme px-4 lg:px-16' variant='contained' color='primary' size="small"
                            onClick={onConfirmChange}>Hủy lệnh</Button>
                        : "Đã hủy"
                    }
                </Box>
        },
        {
            content: isEmptyRow ? "" :
                <TextField
                    id="note"
                    size="small"
                    value={note ?? ""}
                    onChange={({ target: { value } }) => setNote(value)}
                    fullWidth
                    onBlur={onUpdateNote}
                    onKeyDown={onUpdateNotePress}
                />
        },
    ]


    return [
        <TableRow
            key={item.transactionId}
            className={"h-8 cursor-pointer"}
            hover
            onClick={() => { if (!isEmptyRow) hadShowSub(!isShowSub); }}>
            {colsHistory.map((row, index) => (defineFilter[row.id] || filterCol == null || row.requireFilter) && row.display &&
                <TableCell key={index}
                    className={clsx("px-8 py-4", className, row.className, row.textStyle, isEmptyRow && "z-10")}
                    align={row.align} component="td" scope="row" style={{ ...row.moreStyle, ...style }}
                >
                    <div style={row.moreStyle} className={clsx(`text-${row.align}`, cells[index].textStyle)}>
                        {cells[index].content}
                    </div>
                </TableCell>
            )}
        </TableRow>
        ,
        <TableRow
            key={item.transactionId + "-sub"}>
            <TableCell colSpan={filterCol != null ? Object.keys(filterCol).length : 9} className="bg-gray-100 h-0 p-0" component="td" scope="row" align="left">
                <Box className={clsx('flex w-5/6 xl:w-full lg:w-full overflow-hidden transition-all ease-out duration-300', isShowSub ? "h-auto p-8" : "h-0")}>
                    <Typography className='text-grey-600 text-13'>ID: {item.transactionId}</Typography>
                </Box>
            </TableCell>
        </TableRow>
    ];
}

export default HistoryTableRow;
