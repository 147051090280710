
import React, { useState, useEffect } from 'react';
import { DataViewPNK } from './DataViewPNK';
import { Table } from '@mui/material';


export const PNK = ({ info }) => {
    return (
        <div >
            <h3 className='my-4 py-4 font-bold'>{info["nhaCungCap"]}  -  {info["soHoaDon"]} </h3>
            <Table>
                <thead>
                    <tr className='border-b-1 border-gray-700'>
                        <th className="p-10 min-w-120 text-center">Tên hàng hóa</th>
                        <th className="p-10 min-w-120 text-center">Mã số</th>
                        <th className="p-10 min-w-120 text-center">Đơn vị</th>
                        <th className="p-10 min-w-120 text-center">Số lượng</th>
                        <th className="p-10 min-w-120 text-center">Đơn giá </th>
                        <th className="p-10 min-w-120 text-center">Thành tiền</th>

                    </tr>
                </thead>
                <tbody >

                    {info.thongtin?.map((data, index) => (
                        <tr key={index} className='odd:bg-blue-600 odd:bg-opacity-10 h-40 hover:bg-grey-100'>
                            {/* <td className="text-center px-4">
                                <DataViewPNK originValue={data["a"]} />
                            </td> */}

                            <td className="text-center px-4">
                                <DataViewPNK originValue={data["b"]} />
                            </td>

                            <td className="text-center px-4">
                                <DataViewPNK originValue={data["c"]} />
                            </td>

                            <td className="text-center px-4">
                                <DataViewPNK originValue={data["d"]} />
                            </td>

                            <td className="text-center px-4">
                                <DataViewPNK originValue={(data["1"] - 0).toLocaleString("vi-VN")} />
                            </td>

                            <td className="text-right px-4">
                                <DataViewPNK originValue={(data["3"] - 0).toLocaleString("vi-VN") + " VND"} />
                            </td>
                            <td className="text-right px-4">
                                <DataViewPNK originValue={(data["4"] - 0).toLocaleString("vi-VN") + " VND"} />
                            </td>
                        </tr>
                    ))}


                </tbody>
            </Table >
        </div>
    )
}
