import { ThemeProvider } from '@mui/material/styles';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { selectNavbarTheme } from 'app/store/adg/settingsSlice';
import NavbarStyle from './navbar/NavbarStyle';

const NavbarWrapperLayout = (props) => {

  const navbarTheme = useSelector(selectNavbarTheme);

  return (
    <>
      <ThemeProvider theme={navbarTheme}>
        <NavbarStyle />
      </ThemeProvider>
    </>
  );
}

export default memo(NavbarWrapperLayout);
