import { default as AdapterMoment } from "@date-io/moment";

export default class AdapterDate extends AdapterMoment {

  constructor(props) {
    super(props);
    const cusWeekdays = this.getWeekdays();
    this.getWeekdays = function () {
      const defineDay = (day) => ({ charAt: () => day });
      return cusWeekdays.map((day) => defineDay(day));
    }
  };
  
};

