import { Navigate } from 'react-router-dom';
import DocumentPage from '.';

const DocumentConfig = {
    settings: {
        layout: {
            config: {
                title: "",
                navbar: {
                    display: true,
                },
                toolbar: {
                    display: true,
                },
                footer: {
                    display: true,
                },
                leftSidePanel: {
                    display: true,
                },
            },
        },
    },
    roles: ["ROLE_SYSTEM_ADMIN", "ROLE_EMPLOYEE"],
    routes: [
        {
            path: 'app/management',
            children: [
                {
                    path: '',
                    element: <Navigate to="bidv" replace />,
                },
                {
                    path: 'bidv',
                    element: <DocumentPage bank="bidv" />,
                    settings: {
                        layout: {
                            config: {
                                title: "Hồ sơ giải ngân BIDV",
                            }
                        }
                    }
                },
                {
                    path: 'vietinbank',
                    element: <DocumentPage bank="vietin" />,
                    settings: {
                        layout: {
                            config: {
                                title: "Hồ sơ thuế VietinBank",
                            }
                        }
                    }
                },
                {
                    path: 'mb',
                    element: <DocumentPage bank="mb" />,
                    settings: {
                        layout: {
                            config: {
                                title: "Theo dõi đơn hàng MB",
                            }
                        }
                    }
                }
            ]
        },
    ],
};

export default DocumentConfig;
