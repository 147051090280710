import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import PageCarded from '@adg/core/PageCarded';
import withReducer from 'app/store/withReducer';

import reducer from './store';
import DashboardHeader from './DashboardHeader';
import DashboardTable from './DashboardTable';
import { setColFilterAll } from './store/colFilterSlice';
import { setBank } from './store/bankSlice';
import BankService from '@service/bankService';
import StorageService from '@service/storageService';
import { setStorages } from './store/storageSlice';
import { initFilterWithBank } from './store/rowFilterSlice';
import { useParams } from 'react-router-dom';
import { display } from '@mui/system';

const DashboardPage = () => {
	const params = useParams();
	const [updated, set_] = useState(true);


	const forceUpdate = () => {
		set_(!updated);
	}
	localStorage.setItem("TI_GIA", 23500);

	const dispatch = useDispatch();

	useEffect(() => {
		let filterList = localStorage.getItem('filterColumns');
		if (filterList) {
			let originFilter = {};
			try {
				originFilter = JSON.parse(filterList);
			} catch { }
			dispatch(setColFilterAll(originFilter));
		}
		let bankInLocal = localStorage.getItem("BANK_STORAGE");
		if (bankInLocal) {
			bankInLocal = JSON.parse(bankInLocal);
		} else {
			bankInLocal = {}
		}

		let currentbank = localStorage.getItem("CURRENT_BANK") - 0;
		Promise.all([
			BankService.getBanks(dispatch),
			StorageService.getStorages(dispatch),
		]).then(([{ data: bankRes }, { data: storagesRes }]) => {
			const { data: listBank } = bankRes;
			const { data: listStorage } = storagesRes;
			if (listBank && listStorage) {
				let newList = listBank.map(b => {
					let storageOfBank = listStorage.filter(s => s.bankId === b.id && s.port == true);
					let allStorages = listStorage.filter(s => s.bankId === b.id);
					return {
						bankId: b.id,
						bankName: b.name,
						storageOfBank: storageOfBank.length < 1 ? null : {
							name: storageOfBank[0].name,
							id: storageOfBank[0].id
						},
						allStorages: allStorages
					}
				});

				let bank = {
					selected: newList[currentbank],
					list: newList
				}
				let selectedList = bank.selected.allStorages.filter(val => {
					return bankInLocal[bank.selected.bankId]?.find(item => item.id === val.id) != null;
				});
				let listStorages = bank.selected.allStorages.map(val => {
					let hadDisplay = bankInLocal[bank.selected.bankId]?.find(item => item.id === val.id) != null;
					return {
						...val,
						display: hadDisplay
					}
				});
				dispatch(setBank(bank));
				dispatch(setStorages({
					bankId: newList[currentbank].bankId,
					list: listStorages,
					selectedList
				}));
				dispatch(initFilterWithBank(newList.map(val => val.bankId)));
			} else {
				alert("Lỗi các ngân hàng và kho")
			}
		});
	}, []);

	return (
		<PageCarded
			header={<DashboardHeader forceUpdate={forceUpdate} />}
			content={<DashboardTable updated={updated} forceUpdate={forceUpdate} />}
			scroll={'content'}
		/>
	);
}

export default withReducer('homeDashboard', reducer)(DashboardPage);
