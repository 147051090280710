
const navigationConfig = [
  {
    id: 'dashboards-group',
    title: 'Quản lý hàng hóa',
    subtitle: '',
    type: 'group',
    icon: 'heroicons-outline:home',
    roles: ["ROLE_SYSTEM_ADMIN", "ROLE_EMPLOYEE"],
    children: [
      {
        id: 'dashboard',
        title: 'Theo dõi hàng hóa',
        type: 'item',
        icon: 'heroicons-outline:clipboard-check',
        url: 'app/dashboard',
        roles: ["ROLE_SYSTEM_ADMIN", "ROLE_EMPLOYEE"],
      },
    ]
  },
  {
    id: 'users-group',
    title: 'Quản lý user',
    subtitle: '',
    type: 'group',
    icon: '',
    roles: ["ROLE_SYSTEM_ADMIN"],
    children: [
      {
        id: 'user-management',
        title: 'Danh sách người dùng',
        type: 'item',
        icon: 'heroicons-outline:identification',
        url: 'app/user',
        roles: ["ROLE_SYSTEM_ADMIN"],
      },
    ]
  },
  {
    id: 'managerments-group',
    title: 'Quản lý hồ sơ',
    subtitle: '',
    type: 'group',
    icon: 'heroicons-outline:home',
    roles: ["ROLE_SYSTEM_ADMIN", "ROLE_EMPLOYEE"],
    children: [
      {
        id: 'bidv',
        title: 'Hồ sơ giải ngân BIDV',
        type: 'item',
        icon: 'heroicons-outline:document-text',
        url: 'app/management/bidv',
        roles: ["ROLE_SYSTEM_ADMIN", "ROLE_EMPLOYEE"],
      },
      {
        id: 'vietinbank',
        title: 'Hồ sơ thuế VietinBank',
        type: 'item',
        icon: 'heroicons-outline:document-text',
        url: 'app/management/vietinbank',
        roles: ["ROLE_SYSTEM_ADMIN", "ROLE_EMPLOYEE"],
      },
      {
        id: 'mb',
        title: 'Theo dõi đơn hàng MB',
        type: 'item',
        icon: 'heroicons-outline:document-text',
        url: 'app/management/mb',
        roles: ["ROLE_SYSTEM_ADMIN", "ROLE_EMPLOYEE"],
      },
    ]
  },
];

export default navigationConfig;
