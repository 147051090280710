import axios from 'axios';

const GET_BANKS_URL = "/international-payment/tracking/bank";

const getBanks = async (dispatch = () => { }) => {
    return axios.get(GET_BANKS_URL).catch((errRes) => {
        axios.mapError(dispatch,errRes.response);
        throw errRes;
    });
}

const BankService = {
    getBanks,
}

export default BankService;