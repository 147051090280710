import AdgNavigation from '@adg/core/Navigation';
import clsx from 'clsx';
import { memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectNavigation } from 'app/store/adg/navigationSlice';
import useThemeMediaQuery from '@adg/hooks/useThemeMediaQuery';
import { navbarCloseMobile } from 'app/store/adg/navbarSlice';

const Navigation = (props) => {
  const navigation = useSelector(selectNavigation);
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));

  const dispatch = useDispatch();

  return useMemo(() => {
    const handleItemClick = (item) => {
      if (isMobile) {
        dispatch(navbarCloseMobile());
      }
    }

    return (
      <AdgNavigation
        className={clsx('navigation', props.className)}
        navigation={navigation}
        active={props.active}
        onItemClick={handleItemClick}
      />
    );
  },
    [dispatch, isMobile, navigation, props.active, props.className]);
}

export default memo(Navigation);
