import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import _ from '@lodash';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import jwtService from '../../auth/jwtService';
import { Hidden } from '@mui/material';
import { useDispatch } from 'react-redux';
import { MESSAGE_STATUS, showMessage, showMessageWithStatus } from 'app/store/adg/messageSlice';

const schema = yup.object().shape({
	username: yup.string().required('Vui lòng điền tên tài khoản'),
	password: yup.string().required('Vui lòng điền mật khẩu')
});

const defaultValues = {
	username: '',
	password: '',
	remember: true,
};

const LoginPage = () => {
	const dispatch = useDispatch();

	const { control, formState, handleSubmit, setError } = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(schema),
	});

	const { isValid, dirtyFields, errors } = formState;

	const onSubmit = ({ username, password, remember }) => {
		jwtService.loginWithUsernameAndPassword(username, password, remember).catch((error) => {
			console.error(error)
			dispatch(showMessageWithStatus(typeof (error) === "string" ? error : "Mật khẩu không đúng", MESSAGE_STATUS.error));
		});
	}

	return (
		<div className="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-1 min-w-0">
			<Paper className="h-full sm:h-auto md:flex md:items-center md:justify-end w-full sm:w-auto md:h-full md:w-1/3 py-8 px-16 sm:p-48 md:p-64 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none ltr:border-r-1 rtl:border-l-1">
				<div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">

					<Hidden lgUp>
						<img className="w-96" src="assets/images/logo/Logo_ADong.png" alt="logo" />
					</Hidden>

					<Typography className="mt-32 text-4xl font-extrabold tracking-tight leading-tight">
						Đăng nhập
					</Typography>

					<form
						name="loginForm"
						noValidate
						className="flex flex-col justify-center w-full mt-32"
						onSubmit={handleSubmit(onSubmit)}
					>
						<Controller
							name="username"
							control={control}
							render={({ field }) => (
								<TextField
									{...field}
									className="mb-24"
									label="Tài khoản"
									autoFocus
									type="text"
									error={!!errors.email}
									helperText={errors?.email?.message}
									variant="outlined"
									required
									fullWidth
								/>
							)}
						/>

						<Controller
							name="password"
							control={control}
							render={({ field }) => (
								<TextField
									{...field}
									className="mb-24"
									label="Mật khẩu"
									type="password"
									error={!!errors.password}
									helperText={errors?.password?.message}
									variant="outlined"
									required
									fullWidth
								/>
							)}
						/>

						<div className="flex flex-col sm:flex-row items-center justify-center sm:justify-between">
							<Controller
								name="remember"
								control={control}
								render={({ field }) => (
									<FormControl>
										<FormControlLabel
											label="Nhớ tài khoản"
											control={<Checkbox size="small" {...field} checked={field.value} />}
										/>
									</FormControl>
								)}
							/>
						</div>

						<Button
							variant="outlined"
							color="secondary"
							className=" w-full mt-16"
							aria-label="ĐĂNG NHẬP"
							disabled={_.isEmpty(dirtyFields) || !isValid}
							type="submit"
							size="large"
						>
							ĐĂNG NHẬP
						</Button>
					</form>
				</div>
			</Paper>

			<Box
				className="relative hidden md:flex flex-auto items-center justify-center h-full p-64 lg:px-112 overflow-hidden"
				sx={{ backgroundColor: 'primary.main' }}
			>
				<svg
					className="absolute inset-0 pointer-events-none"
					viewBox="0 0 960 540"
					width="100%"
					height="100%"
					preserveAspectRatio="xMidYMax slice"
					xmlns="http://www.w3.org/2000/svg"
				>
					<Box
						component="g"
						sx={{ color: 'primary.light' }}
						className="opacity-20"
						fill="none"
						stroke="currentColor"
						strokeWidth="100"
					>
						<circle r="234" cx="196" cy="23" />
						<circle r="234" cx="790" cy="491" />
					</Box>
				</svg>
				<Box
					component="svg"
					className="absolute -top-64 -right-64 opacity-20"
					sx={{ color: 'primary.light' }}
					viewBox="0 0 220 192"
					width="220px"
					height="192px"
					fill="none"
				>
					<defs>
						<pattern
							id="837c3e70-6c3a-44e6-8854-cc48c737b659"
							x="0"
							y="0"
							width="20"
							height="20"
							patternUnits="userSpaceOnUse"
						>
							<rect x="0" y="0" width="4" height="4" fill="currentColor" />
						</pattern>
					</defs>
					<rect width="220" height="192" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)" />
				</Box>

				<div className="z-10 relative w-full max-w-2xl flex items-center flex-col">
					<img style={{ width: "40rem" }} src="assets/images/logo/Logo_ADong-white-xs.png" alt="logo" />
					<div className="text-6xl font-bold leading-none text-gray-100 mt-16">
						<div>ADG Internal Tools</div>
					</div>
				</div>
			</Box>
		</div>
	);
}

export default LoginPage;
