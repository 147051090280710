import _ from '@lodash';
import {
	Table,
	TablePagination,
	Typography
} from '@mui/material';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react'

import withRouter from '@adg/core/withRouter';
import Loading from '@adg/core/Loading';
import UserService from '@service/useService';

import TblHead from './table-components/TblHead';
import TblBody from './table-components/TblBody';

const UserManagementTable = ({ updated, forceUpdate = () => { } }) => {
	const dispatch = useDispatch();
	const [data, setData] = useState([]);
	const [page, setPage] = useState({ pageIndex: 0, totalRecords: 0 });
	const [rowsPerPage, setRowsPerPage] = useState(25);
	const [order, setOrder] = useState({
		direction: 'desc',
		id: "username",
	});
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		_loadData();
	}, [updated]);

	const _loadData = () => {
		setLoading(true);
		UserService.getUsers(dispatch).then(({ data: res }) => {
			let list = res.data;
			setPage({ pageIndex: 0, totalRecords: list.length });
			setData(list);
			setLoading(false);
		});
	}

	const handleChangePage = (_, page) => {

	}
	const handleChangeRowsPerPage = ({ target: { value: numRow } }) => {

	}

	return (
		<div className='flex flex-col relative w-full overflow-auto' style={{ height: "calc(100vh - 155px)" }}>
			<div className='flex-1'>
				<Table stickyHeader className="table_baocao" aria-labelledby="tableTitle">
					<TblHead
						onRequestSort={() => { }}
						order={order}
						rowCount={data.length}
					/>
					{!loading &&
						<TblBody data={data} forceUpdate={forceUpdate} />
					}
				</Table>

			</div>
			{loading ?
				<div className="flex items-center justify-center h-full">
					<Loading />
				</div>
				:
				<>
					<ShowEmptyValue arr={data} />
					<div className='w-full flex flex-row items-center border-t-1 sticky left-0 bottom-0 bg-white z-20'>
						<TablePagination
							className="flex-1 shrink-0"
							component="div"
							count={page.totalRecords}
							rowsPerPage={rowsPerPage}
							labelRowsPerPage="Số hàng mỗi trang"
							labelDisplayedRows={({ from, to, count }) => {
								return `${from}–${to} trong tổng ${count !== -1 ? count : `hơn ${to}`}`;
							}}
							page={page.pageIndex}
							backIconButtonProps={{
								'aria-label': 'Trước',
							}}
							nextIconButtonProps={{
								'aria-label': 'Sau',
							}}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					</div>
				</>
			}

		</div>
	);
}

export const ShowEmptyValue = ({ arr = [] }) => {
	if (arr.length === 0) {
		return (
			<motion.div
				initial={{ opacity: 0 }}
				animate={{ opacity: 1, transition: { delay: 0.1 } }}
				className="flex flex-1 items-center justify-center h-full py-24 "
			>
				<Typography color="text.secondary" variant="h5">
					Chưa có thông tin!
				</Typography>
			</motion.div>
		);
	}
	return null;
}

export default withRouter(UserManagementTable);
