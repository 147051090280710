import DashboardPage from '.';
import { DashboardRelease } from './DashboardRelease';

const DashboardConfig = {
  settings: {
    layout: {
      config: {
        title: "XUẤT NHẬP TỒN KHO HÀNG HÓA",
        navbar: {
          display: true,
        },
        toolbar: {
          display: true,
        },
        footer: {
          display: true,
        },
        leftSidePanel: {
          display: true,
        },
      },
    },
  },
  roles: ["ROLE_SYSTEM_ADMIN", "ROLE_EMPLOYEE"],
  routes: [
    {
      path: 'app/dashboard',
      element: <DashboardPage />,
    },
    {
      path: 'app/dashboard/release',
      element: <DashboardRelease />,
    },
  ],
};

export default DashboardConfig;
