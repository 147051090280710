import { Chip, lighten, Stack, Table, TablePagination } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import Loading from '@adg/core/Loading';
import StorageService from '@service/storageService';

import { ShowEmptyValue } from '../../DashboardTable';
import OtherTableHead, { colsOther } from './OtherTableHead';
import OtherTableBody from './OtherTableBody';
import { selectRowFilter, setRowFilter } from '../../store/rowFilterSlice';

export const currentOtherTableInfo = {
    direction: null,
    id: null,
    pageIndex: null,
    rowsPerPage: null,
}

const OtherTable = ({ storage, updated, forceUpdate }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const otherFilter = useSelector(selectRowFilter(storage.id));
    const [data, setData] = useState([]);
    const [page, setPage] = useState({ pageIndex: 0, totalRecords: 0 });
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [order, setOrder] = useState({
        direction: 'desc',
        id: "createdAt",
    });

    useEffect(() => {
        _loadDataForByFilter(page.pageIndex, rowsPerPage);
    }, [updated, otherFilter, order]);

    currentOtherTableInfo.id = order.id;
    currentOtherTableInfo.direction = order.direction;
    currentOtherTableInfo.pageIndex = page.pageIndex;
    currentOtherTableInfo.rowsPerPage = rowsPerPage;

    const _loadDataForByFilter = (pageIndex, pageSize) => {

        let filterParams = [];
        otherFilter?.forEach(val => {
            filterParams.push({
                field: val.columnId,
                dataType: val.columnType == "text" ? "string" : val.columnType,
                operator: val.condition.operator,
                value: val.unit == "(Tấn)" ? val.condition.vals.map(v => v * 1000) : val.condition.vals
            });
        })

        let body = {
            sortField: order.id,
            sortDirection: order.direction.toUpperCase(),
            pageIndex,
            pageSize,
            filters: {
                and: filterParams
            },
            storageId: storage.id,
        }

        setLoading(true);
        StorageService.getStorageInfo(storage.id, body, dispatch).then(({ data: { data: res } }) => {
            setPage({
                pageIndex,
                totalRecords: res.totalRecords
            });
            setData(res.orders);
        }).finally(() => {
            setLoading(false);
        });
    }

    const handleRequestSort = (event, property) => {
        const id = property;
        let direction = 'desc';

        if (order.id === property && order.direction === 'desc') {
            direction = 'asc';
        }

        setOrder({
            direction,
            id,
        });
    }

    function handleChangePage(event, value) {
        _loadDataForByFilter(value, rowsPerPage);
    }
    function handleChangeRowsPerPage({ target: { value } }) {
        _loadDataForByFilter(0, value);
    }

    const generateTagFilter = () => {
        let tags = [];
        [...otherFilter].forEach((val, index) => {
            let colName = colsOther.find((r) => r.id == val.columnId).label;
            let values = val.condition.vals;
            if (val.columnType === "datetime") {
                values = values.map(date => moment(date).format("DD/MM/YYYY"));
            } else if (val.columnType === "text") {
                if (val.customList) {
                    values = values.map((key) => {
                        let newText = val.customList.filter(item => item.key == key)[0].name;
                        return `${newText}`;
                    });
                } else {
                    values = values.map(text => `'${text}'`);
                }
            } else if (val.columnType === "number") {
                let unit = val.unit ? " " + val.unit : "";
                values = values.map(num => `${num}${unit}`);
            }
            let mess = `${colName} ${val.condition.id} ${values.join(" và ")}`;
            tags.push(
                <Chip key={index} label={mess} color="primary" onDelete={() => onDeleteTagFilter(index)} />
            );
        })
        return tags;
    }

    const onDeleteTagFilter = (index) => {
        let newOrderFilter = [...otherFilter];
        newOrderFilter.splice(index, 1);
        dispatch(setRowFilter({
            id: storage.id,
            list: newOrderFilter
        }));
    };

    return (
        <div className='flex flex-col relative w-full overflow-auto' style={{ height: "calc(100vh - 112px)" }}>

            {otherFilter?.length > 0 &&
                <Stack direction="row" className='sticky top-0 left-0 z-99' spacing={1} px={2} py={1}
                    sx={{
                        backgroundColor: (theme) => lighten(theme.palette.background.default, 0.4),
                    }}>
                    {generateTagFilter()}
                </Stack>
            }
            <div className='flex-1'>
                <Table stickyHeader className="table_baocao" aria-labelledby="tableTitle">
                    <OtherTableHead
                        storage={storage}
                        order={order}
                        onRequestSort={handleRequestSort}
                        rowCount={data?.length}
                    />
                    {!loading &&
                        <OtherTableBody storage={storage} data={data} forceUpdate={forceUpdate} />
                    }
                </Table>
            </div>
            {loading ?
                <div className="flex items-center justify-center h-full">
                    <Loading />
                </div>
                :

                <>
                    <ShowEmptyValue arr={data} />
                    <div className='w-full flex flex-row items-center border-t-1 sticky left-0 bottom-0 bg-white z-20'>
                        <TablePagination
                            className="flex-1 shrink-0"
                            component="div"
                            count={page.totalRecords}
                            rowsPerPage={rowsPerPage}
                            labelRowsPerPage="Số hàng mỗi trang"
                            labelDisplayedRows={({ from, to, count }) => {
                                return `${from}–${to} trong tổng ${count !== -1 ? count : `hơn ${to}`}`;
                            }}
                            page={page.pageIndex}
                            backIconButtonProps={{
                                'aria-label': 'Trước',
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Sau',
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
                </>
            }
        </div>
    )
}

export default OtherTable;
