import SvgIcon from '@adg/core/SvgIcon';
import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, IconButton, Popover } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';

import { selectColFilter, setColFilter } from '../store/colFilterSlice';

export const SelectColumn = ({ id = "", rows = [] }) => {
    const [filterFields, setFilterFields] = useState(null);
    const [filterMenu, setFilterMenu] = useState(null);
    const dispatch = useDispatch();
    const filter = useSelector(selectColFilter(id));

    useEffect(() => {
        setFilterFields(filter ? { ...filter } : null);
    }, [filter, id]);

    const handleChangeColumn = (field = null, checked = false) => {
        if (field == null) {
            setFilterFields(!checked ? {} : null);
            return;
        }
        if (filterFields == null) {
            let newFilter = {
                [field]: true
            };
            setFilterFields(newFilter);
        } else {
            let newFilter = {
                ...filterFields,
                [field]: checked
            }
            setFilterFields(newFilter);
        }
    }

    const onFilterClick = (event) => {
        setFilterMenu(event.currentTarget);
    };

    const onFilterClose = () => {
        setFilterMenu(null);
    };

    const onSubmit = () => {
        dispatch(setColFilter({
            id,
            cols: filterFields !== null ? { ...filterFields } : null
        }));
        _saveFilterToLocal(filterFields !== null ? { ...filterFields } : null)
        onFilterClose();
    }

    const _saveFilterToLocal = (filter) => {
        let newAllFilter = localStorage.getItem("filterColumns");
        try {
            if (newAllFilter) {
                newAllFilter = JSON.parse(newAllFilter);
            } else {
                newAllFilter = {};
            }
        } catch (_) {
            newAllFilter = {};
        }
        newAllFilter[id] = filter;
        localStorage.setItem("filterColumns", JSON.stringify(newAllFilter));
    }

    return (
        <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}
        >
            <IconButton
                className='bg-gray-200 text-dark hover:text-white hover:bg-gray-800'
                variant="contained"
                color="inherit"
                onClick={onFilterClick}
            >
                <SvgIcon>heroicons-outline:view-boards</SvgIcon>
            </IconButton>
            <Popover
                open={Boolean(filterMenu)}
                anchorEl={filterMenu}
                onClose={onFilterClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                classes={{
                    paper: 'py-8',
                }}
            >
                <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                    <FormLabel component="legend">Chọn cột muốn hiển thị:</FormLabel>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={filterFields == null || Object.keys(filterFields).length == rows.filter((f) => f.requireFilter == false).length}
                                onChange={(_, checked) => handleChangeColumn(null, checked)}
                                name="All"
                            />
                        }
                        label="Tất cả"
                    />
                    <FormGroup>
                        {rows?.map(val => {
                            if (val.requireFilter || !val.display) return null;
                            return (
                                <FormControlLabel key={val.id}
                                    control={
                                        <Checkbox
                                            checked={filterFields != null ? filterFields[val.id] == true : true}
                                            onChange={(_, checked) => handleChangeColumn(val.id, checked)}
                                            name={val.label}
                                        />
                                    }
                                    label={val.label}
                                />
                            );
                        })}
                    </FormGroup>
                    <FormHelperText>
                        <Button
                            variant="contained"
                            color="secondary"
                            className='bg-gray-200 w-full text-gray-800 hover:text-white'
                            onClick={onSubmit}
                        >
                            Lọc
                        </Button>
                    </FormHelperText>
                </FormControl>
            </Popover>
        </motion.div>
    )
}