
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Button,
    IconButton,
    Paper,
    styled,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { colsOther } from './OtherTableHead';
import SvgIcon from '@adg/core/SvgIcon';
import StorageService from '@service/storageService';
import OrderService from '@service/orderService';
import { MESSAGE_STATUS, showMessageWithStatus } from 'app/store/adg/messageSlice';

import { selectRowFilter, setRowFilter } from '../../store/rowFilterSlice';
import { ShowEmptyValue } from '../../DashboardTable';
import { selectStorages } from '../../store/storageSlice';
import { FilterInCell } from '../../components/FilterInCell';
import { selectColFilter } from '../../store/colFilterSlice';

const TRANSACTION_TYPE = {
    PAID_EXPORT: "Giải chấp",
    ONLY_PAID: "Trả giải chấp",
    ONLY_EXPORT: "Nợ giải chấp",
    IMPORT_TO_STORAGE: "Hàng cảng"
}

const StyledTableHeader = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const OtherTableRow = ({ item, onOpenPayment, storage, isEmptyRow = false, className, style }) => {
    const dispatch = useDispatch();
    const storageSelected = useSelector(selectStorages);
    const filterCol = useSelector(selectColFilter(storage?.id));
    const filterRow = useSelector(selectRowFilter(storage?.id));

    const [isShowSub, hadShowSub] = useState(false);
    const [note, setNote] = useState(item.note);
    const [isLoading, hadLoading] = useState(false);
    const [historyList, setHistoryList] = useState([]);

    const defineFilter = filterCol ? filterCol : {};

    useEffect(() => {
        if (isShowSub) {
            StorageService.getTransactionsDetail(
                storage.id,
                item.orderId,
                dispatch
            ).then(({ data: res }) => {
                setHistoryList(res.data);
            }).finally(() => {
                hadLoading();
            });
        }
    }, [isShowSub]);

    const onAddRowFilter = (columnKey, type, val) => {
        if (type === "datetime") {
            val = moment(val).format("YYYY-MM-DD");
        }
        let data = {
            columnId: columnKey,
            columnType: type,
            condition: {
                id: type === "text" ? "bằng" : "=",
                numVal: 1,
                operator: "equal",
                vals: [
                    val
                ]
            },
        };
        let rootFilter = filterRow ?? [];
        let newFilter = [...rootFilter, data];
        dispatch(setRowFilter({
            id: storage?.id,
            list: newFilter,
        }));
    }

    const onUpdateNotePress = (e) => {
        if (e?.keyCode === 13) {
            onUpdateNote();
        }
    }

    const onUpdateNote = () => {
        let body = {
            orderStorages: [{
                orderId: item.orderId,
                storageId: storage.id,
                note
            }]
        };
        if (item.note != note) {
            OrderService.updateStorageNote(body, dispatch).then((res) => {
                dispatch(showMessageWithStatus("Đã cập nhật ghi chú", MESSAGE_STATUS.success));
            });
        }
    }

    const getStorages = (storageId) => {
        let storage = storageSelected?.list?.find(val => val.id === storageId);
        if (storage?.name) {
            return storage.name;
        }
        return "Gỉải chấp";
    }

    const cells = [
        {
            textStyle: isEmptyRow ? "font-bold" : "",
            content: isEmptyRow ? "" : (
                <IconButton variant="text" size='small' className='p-0'>
                    <div className={clsx("transition-all duration-200", isShowSub ? "rotate-180" : "rotate-0")}>
                        <ArrowDropDownIcon />
                    </div>
                </IconButton>
            )
        },
        {
            textStyle: "font-bold",
            content: isEmptyRow ? "" :
                <FilterInCell className='font-bold' name="số KU" onClick={() => onAddRowFilter("contractId", "text", item.contractId)}>
                    <Tooltip title={item.contractId} placement='top'>
                        <span>{item.contractId}</span>
                    </Tooltip>
                </FilterInCell>
        },
        {
            textStyle: "",//
            content: isEmptyRow ? "" :
                <FilterInCell className='overflow-hidden' name="mã sản phẩm" onClick={() => onAddRowFilter("productId", "text", item.productId)}>
                    <Tooltip title={"Thực hiện giải chấp với " + item.productId} placement='top' className=''>
                        <Button
                            className='rounded-lg py-0 px-4 bg-dark-theme h-32 min-h-0 w-full'
                            variant="contained" color="secondary"
                            onClick={(e) => { e.stopPropagation(); onOpenPayment(); }}
                        >
                            <p className='truncate'>{item.productId} </p>
                        </Button>
                    </Tooltip>
                </FilterInCell>
        },
        {
            textStyle: isEmptyRow ? "font-bold" : "",
            content: isEmptyRow ? "Tổng:" :
                <FilterInCell name="ngày tạo" onClick={() => onAddRowFilter("createdAt", "datetime", item.createdAt)}>
                    {moment(item.createdAt).format("DD/MM/YYYY")}
                </FilterInCell>
        },
        {
            textStyle: isEmptyRow ? "font-bold" : "",
            content: (item.importQuantity / 1000).toLocaleString("vi-VN")
        },
        {
            textStyle: isEmptyRow ? "font-bold" : "",
            content: (item.releaseQuantity / 1000).toLocaleString("vi-VN")
        },
        {
            textStyle: isEmptyRow ? "font-bold" : "",
            content: (item.inventoryQuantity / 1000).toLocaleString("vi-VN")
        },
        {
            textStyle: isEmptyRow ? "font-bold" : "",
            content: (item.actualReleaseQuantity / 1000).toLocaleString("vi-VN")
        },
        {
            textStyle: isEmptyRow ? "font-bold" : "",
            content: isEmptyRow ? (item.debitCredit / 1000).toLocaleString("vi-VN") :
                <Box className="flex items-center justify-center">
                    <Typography
                        fontWeight={(item.debitCredit == null || item.debitCredit == 0) ? null : "bold"}
                        color={(item.debitCredit == null || item.debitCredit == 0) ? null : item.debitCredit > 0 ? "green" : "red"}
                    >
                        {item.debitCredit > 0 && "+"}{(item.debitCredit / 1000).toLocaleString("vi-VN")}
                    </Typography >
                </Box>
        },
        {
            textStyle: isEmptyRow ? "font-bold" : "",
            content: (item.actualInventoryQuantity / 1000).toLocaleString("vi-VN")
        },
        {
            textStyle: isEmptyRow ? "font-bold" : "",
            content: isEmptyRow ? "" : item.updatedBy
        },
        {
            textStyle: "",
            content: isEmptyRow ? "" : (
                <TextField
                    id="note"
                    size="small"
                    value={note ?? ""}
                    onChange={({ target: { value } }) => setNote(value)}
                    onBlur={onUpdateNote}
                    onKeyDown={onUpdateNotePress}
                    fullWidth
                />
            )
        },
    ]

    return [
        <TableRow
            key={item.orderId ?? ""}
            className="min-h-8 cursor-pointer"
            hover
            onClick={() => { if (!isEmptyRow) hadShowSub(!isShowSub); }}
        >
            {colsOther.map((row, index) => (defineFilter[row.id] || filterCol == null || row.requireFilter) && row.display &&
                <TableCell key={index}
                    className={clsx("px-8 py-4", className, row.className, row.textStyle, isEmptyRow && "z-10")}
                    align={row.align}
                    component="td"
                    scope="row"
                    style={{ ...row.moreStyle, ...style }}
                >
                    <div style={row.moreStyle} className={clsx(`text-${row.align}`, cells[index].textStyle)}>
                        {cells[index].content}
                    </div>
                </TableCell>
            )}
        </TableRow>
        ,
        <TableRow
            key={(item.orderId ?? "") + "-sub"}>
            <TableCell colSpan={filterCol != null ? Object.keys(filterCol).length + 1 : 12} className="bg-gray-100 h-0 p-0" component="td" scope="row" align="left">
                <Box className={clsx('flex w-5/6 xl:w-full lg:w-full overflow-hidden transition-all ease-out duration-300', isShowSub ? "h-auto py-8" : "h-0")}>
                    <Box className='px-16' sx={{ minWidth: 900 }}>
                        <TableContainer className='rounded-sm max-h-224 overflow-auto' component={Paper}>
                            <Table stickyHeader aria-label="simple-table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableHeader className='p-8' align='center'>Ngày</StyledTableHeader>
                                        <StyledTableHeader className='p-8'>Kho đến</StyledTableHeader>
                                        <StyledTableHeader className='p-8' align='center'>Loại</StyledTableHeader>
                                        <StyledTableHeader className='p-8' align='center'>Số lượng</StyledTableHeader>
                                        <StyledTableHeader className='p-8' align='center'>Trạng thái</StyledTableHeader>
                                        <StyledTableHeader className='p-8' align='center'>Người cập nhật</StyledTableHeader>
                                        <StyledTableHeader className='p-8 w-160' align='center'>Ghi chú</StyledTableHeader>
                                    </TableRow>
                                </TableHead>
                                {!isLoading && (
                                    <TableBody>
                                        {historyList.map(val =>
                                            <TableRow key={val.id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell className='p-8' align='center'>
                                                    {moment(val.releaseDate).format("DD/MM/YYYY")}
                                                </TableCell>
                                                <TableCell className='p-8'>
                                                    {getStorages(val.targetStorageId)}
                                                </TableCell>
                                                <TableCell className='p-8 text-center'>
                                                    {TRANSACTION_TYPE[val.transactionType]}
                                                    {!Object.keys(TRANSACTION_TYPE).includes(val.transactionType) &&
                                                        <span className='font-bold text-red italic'>(Chưa rõ giao dịch)</span>
                                                    }
                                                </TableCell>
                                                <TableCell className='p-8 text-center'>
                                                    {["PAID_EXPORT", "IMPORT_TO_STORAGE"].includes(val.transactionType) &&
                                                        (val.releaseQuantity / 1000) + " Tấn"
                                                    }
                                                    {"ONLY_PAID" === val.transactionType &&
                                                        <Typography className='font-bold text-green'>+{val.releaseQuantity / 1000} Tấn</Typography>
                                                    }
                                                    {"ONLY_EXPORT" === val.transactionType &&
                                                        <Typography className='font-bold text-red'>-{val.releaseQuantity / 1000} Tấn</Typography>
                                                    }
                                                    {!Object.keys(TRANSACTION_TYPE).includes(val.transactionType) &&
                                                        <Typography className='font-bold text-red'>({val.releaseQuantity / 1000} Tấn)</Typography>
                                                    }
                                                </TableCell>
                                                <TableCell className='p-8 flex items-center justify-center' >
                                                    {val.status === "ACTIVE" ?
                                                        <SvgIcon className="text-48" size={24} color="success">heroicons-outline:badge-check</SvgIcon> :
                                                        <SvgIcon className="text-48" size={24} color="disabled">heroicons-outline:x-circle</SvgIcon>
                                                    }
                                                </TableCell>
                                                <TableCell className='p-8' align='center'>
                                                    {val.updatedBy}
                                                </TableCell>
                                                <TableCell className='p-8' align='left'>
                                                    {val.note}
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                )}
                            </Table>
                            <Box sx={{ flex: 1, backgroundColor: "#fff" }}>
                                <ShowEmptyValue arr={historyList} />
                            </Box>
                        </TableContainer>
                        <Typography className='text-grey-600 text-13 mt-8'>ID: {item.orderId}</Typography>
                    </Box>
                </Box>
            </TableCell >
        </TableRow >
    ];
}

export default OtherTableRow;
