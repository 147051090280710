import SvgIcon from '@adg/core/SvgIcon';
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    FormLabel,
    IconButton,
    InputLabel,
    MenuItem,
    Popover,
    Select,
    TextField,
    Typography
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import _ from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';

import { selectRowFilter, setRowFilter } from '../store/rowFilterSlice';
import { selectStorages } from '../store/storageSlice';

const defineColumn2Form = (cols = []) => {
    let newCols = [];
    cols.forEach(val => {
        if (val.isConditionCol) {
            newCols.push({
                name: val.label,
                unit: val.sublabel,
                id: val.id,
                type: val.valueType,
                customList: val.customList
            })
        }
    });
    return newCols;
}

const conditions = {
    text: [
        { id: "bằng", numVal: 1, operator: "equal" },
        { id: "không bằng", numVal: 1, operator: "not_equal" },
        { id: "chứa", numVal: 1, operator: "contain" },
        { id: "không chứa", numVal: 1, operator: "not_contain" },
    ],
    number: [
        { id: "=", numVal: 1, operator: "equal" },
        { id: ">", numVal: 1, operator: "greater_than" },
        { id: ">=", numVal: 1, operator: "greater_equal" },
        { id: "<", numVal: 1, operator: "less_than" },
        { id: "<=", numVal: 1, operator: "less_equal" },
        { id: "khác", numVal: 1, operator: "dif" },
        { id: "trong khoảng", numVal: 2, operator: "between" },
    ],
    datetime: [
        { id: "=", numVal: 1, operator: "equal" },
        { id: ">", numVal: 1, operator: "after" },
        { id: "<", numVal: 1, operator: "before" },
        { id: "trong khoảng", numVal: 2, operator: "between" },
    ],
}

export const FilterData = ({ id = "", rows = [] }) => {
    const dispatch = useDispatch();
    const currentFilterRow = useSelector(selectRowFilter(id));

    const [filterFields, setFilterFields] = useState([]);
    const [filterMenu, setFilterMenu] = useState(null);

    const allColumns = defineColumn2Form(rows);

    const onFilterClick = (event) => {
        setFilterMenu(event.currentTarget);
        setFilterFields(_.merge([], currentFilterRow));
    };

    const onFilterClose = () => {
        setFilterMenu(null);
    };

    const onSubmit = () => {
        filterFields.forEach(val => {
            let type = val.columnType;
            val.condition.vals = val.condition.vals.map(v => (
                type == "datetime" ? moment(v).format("YYYY-MM-DD") :
                    type == "number" ? v - 0 : v
            ));
        })
        dispatch(setRowFilter({
            id: id,
            list: filterFields,
        }));
        onFilterClose();
    }

    const onAddCondition = () => {
        filterFields.push({
            columnId: null,
            columnType: null,
            condition: null
        });
        setFilterFields([...filterFields]);
    }

    const onRemoveCondition = (index) => {
        filterFields.splice(index, 1);
        setFilterFields([...filterFields]);
    }

    const onChangeColumn = (col = null, index) => {
        filterFields[index].columnId = col.id;
        filterFields[index].columnType = col.type;
        filterFields[index].unit = col.unit;
        filterFields[index].condition = null;
        filterFields[index].customList = null;

        if (col.customList) {
            filterFields[index].customList = col.customList;
            filterFields[index].condition = {
                id: "là",
                numVal: 1,
                operator: "equal",
                vals: [""],
            };
        }
        setFilterFields([...filterFields]);
    }

    const onChangeCondition = (condition = null, index) => {
        let columnType = filterFields[index].columnType;
        filterFields[index].condition = {
            ...condition,
            vals: Array(condition.numVal).fill(columnType === "datetime" ? moment() : "")
        };
        setFilterFields([...filterFields]);
    }

    const onChangeValue = (val = null, index, indexVal) => {
        filterFields[index].condition.vals[indexVal] = val;
        setFilterFields([...filterFields]);
    }

    const checkParamsNotOk = () => {
        let notOk = false;
        if (filterFields.length === 0) {
            return false;
        }
        filterFields.forEach(val => {
            let data = val.condition?.vals;
            if (data == null ||
                data?.filter(i => i === "" || i == null).length > 0 ||
                val.condition?.numVal === 2 && data[0] - 0 > data[1] - 0 || data[0] < 0 || data[1] < 0) {
                notOk = true;
            }
        });
        return notOk;
    }

    return (
        <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}
        >
            <IconButton
                className='text-dark bg-gray-200 hover:text-white hover:bg-gray-800'
                onClick={onFilterClick}
            >
                <SvgIcon>{`heroicons-${currentFilterRow?.length > 0 ? "solid" : "outline"}:filter`}</SvgIcon>
            </IconButton>
            <Popover
                open={Boolean(filterMenu)}
                anchorEl={filterMenu}
                onClose={onFilterClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                classes={{
                    paper: 'py-8',
                }}
            >
                <FormControl sx={{ m: 3, minWidth: 400 }} component="fieldset" variant="standard">
                    <FormLabel component="legend">Chọn cột muốn áp dụng điều kiện:</FormLabel>
                    {filterFields?.map((f, index) => (
                        <Box key={index} flex={1} display="flex" flexDirection="row" alignItems="end">
                            <FormControl variant="standard" sx={{ m: 1, minWidth: 160 }}>
                                <InputLabel id="column-select">Cột</InputLabel>
                                <Select
                                    labelId="column-select"
                                    id="column-select"
                                    value={f.columnId ?? ""}
                                    label="Cột"
                                >
                                    {allColumns.map((val, i) => (
                                        <MenuItem key={i} value={val.id}
                                            onClick={() => { onChangeColumn(val, index) }}>
                                            {val.name} {val.unit}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {f.customList ?
                                <>
                                    <Typography mx={4} alignSelf="center" pt={1.5} fontSize={16}>
                                        {f.condition.id}
                                    </Typography>
                                    <FormControl variant="standard" sx={{ m: 1, minWidth: 160 }}>
                                        <InputLabel id="value-select">Giá trị</InputLabel>
                                        <Select
                                            labelId="value-select"
                                            id="value-select"
                                            value={f.condition.vals[0] ?? ""}
                                            label="Giá trị"

                                            onChange={({ target: { value } }) => onChangeValue(value, index, 0)}
                                        >
                                            {f.customList?.map((val, i) => (
                                                <MenuItem key={i} value={val.key}>
                                                    {val.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </>
                                :
                                <>
                                    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                        <InputLabel id="condition-select">Phép tính</InputLabel>
                                        <Select
                                            labelId="condition-select"
                                            id="condition-select"
                                            value={f.condition?.id ?? ""}
                                            label="Phép tính"
                                            disabled={f.columnId == null}
                                        >
                                            {f.columnType != null && conditions[f.columnType]?.map((val, i) => (
                                                <MenuItem key={i} value={val.id}
                                                    onClick={() => { onChangeCondition(val, index) }}>
                                                    {val.id}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    {f.columnType != null && f.condition?.id != null &&
                                        f.condition?.vals.map((v, i, arr) => (
                                            <FormControl key={i} variant="standard" sx={{ m: 1 }}>
                                                {f.columnType === "datetime" ?
                                                    <Box sx={{ width: 120 }}>
                                                        <DatePicker
                                                            label={f.condition.numVal === 1 ? "Ngày" : i == 0 ? "Từ ngày" : "Đến ngày"}
                                                            value={moment(v)}
                                                            maxDate={i == 0 ? moment(arr[1]) : moment()}
                                                            onChange={(value) => onChangeValue(value, index, i)}

                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    variant="standard"
                                                                />
                                                            )}
                                                        />
                                                    </Box>
                                                    :
                                                    < TextField sx={{ width: f.columnType === "text" ? 215 : 100 }}
                                                        label={f.condition.numVal === 1 ? "Giá trị" : i == 0 ? "Từ" : "Đến"}
                                                        type={f.columnType}
                                                        variant="standard"
                                                        value={v}
                                                        error={v == "" || f.condition.numVal === 2 && i == 0 && arr[0] - 0 > arr[1] - 0 || arr[0] < 0 || arr[1] < 0}
                                                        onChange={({ target: { value } }) => onChangeValue(value, index, i)}
                                                    />
                                                }

                                            </FormControl>
                                        ))
                                    }
                                </>
                            }
                            <IconButton
                                className='flex items-center w-40 h-40 space-x-8 px-16 mb-8 rounded-full text-dark bg-white hover:bg-gray-300'
                                onClick={() => onRemoveCondition(index)}
                            >
                                <SvgIcon>heroicons-outline:x</SvgIcon>
                            </IconButton>
                        </Box>
                    ))}
                    <FormHelperText>
                        <IconButton
                            className='flex items-center w-full space-x-8 px-16 mb-8 rounded-full border-1 shadow-0 text-dark bg-gray-200  hover:text-white hover:bg-gray-800'
                            onClick={onAddCondition}
                        >
                            <SvgIcon>heroicons-outline:plus</SvgIcon>
                        </IconButton>
                    </FormHelperText>
                    <FormHelperText>
                        <Button
                            variant="contained"
                            color="secondary"
                            className='bg-gray-200 w-full text-gray-800 hover:text-white'
                            onClick={onSubmit}
                            disabled={checkParamsNotOk()}
                        >
                            Lọc
                        </Button>
                    </FormHelperText>
                </FormControl>
            </Popover>
        </motion.div >
    )
}