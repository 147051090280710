import { createSlice } from '@reduxjs/toolkit';

const colFilterSlice = createSlice({
  name: 'homeDashboard/colFilter',
  initialState: {
  },
  reducers: {
    setColFilter: (state, action) => {
      let id = action.payload.id;
      state[id] = action.payload.cols;
    },
    setColFilterAll: (_, action) => action.payload
  },
});
export const setColFilter = colFilterSlice.actions.setColFilter;
export const setColFilterAll = colFilterSlice.actions.setColFilterAll;
export const selectColFilter = (id) => ({ homeDashboard }) => homeDashboard.colFilter[id];

export default colFilterSlice.reducer;
