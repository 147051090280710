import React, { useState } from 'react';
import { Box, Tab as MuiTab, Tabs as MuiTabs } from '@mui/material';

import { TabPanel } from './TabPanel';

const a11yProps = (index) => ({
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
});

const Tabs = ({ prefix, list = [], item, ...other }) => {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', flexDirection: "row", display: "flex", alignItems: "center" }}>
                {prefix}
                <MuiTabs value={value} onChange={handleChange} aria-label="tabs" scrollButtons allowScrollButtonsMobile>
                    {list.map((val, index) => (
                        <MuiTab key={index}
                            label={val.label}
                            textColor="secondary"
                            variant="scrollable"
                            {...a11yProps(index)}
                        />
                    ))}
                </MuiTabs>
            </Box>
            {list.map((val, index) => (
                <TabPanel key={index} value={value} index={index}>
                    {val.content(item, other)}
                </TabPanel>
            ))}
        </Box>
    );
}

export default Tabs;
