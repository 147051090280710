import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState } from 'react';
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, ListItemIcon, ListItemText, MenuItem, Popover, styled } from '@mui/material';
import moment from 'moment';

import SvgIcon from '@adg/core/SvgIcon';
import OrderService from '@service/orderService';
import history from '@history';

import { MESSAGE_STATUS, showMessageWithStatus } from 'app/store/adg/messageSlice';

import { FileUploadDialog } from '../dialog/FileUploadDialog';
import { selectBank } from '../store/bankSlice';
import { ConfirmDialog } from '../dialog/ConfirmDialog';
import { ImportManualDialog } from '../dialog/ImportManualDialog';
import { closeDialog, openDialog } from 'app/store/adg/dialogSlice';

const Root = styled("div")(({ theme }) => ({
    '& .bg-dark-theme': {
        backgroundColor: theme.palette.background.dark,
        color: theme.palette.text.light,
    },
    '& .bg-dark-theme:disabled': {
        backgroundColor: theme.palette.grey[400],
        color: theme.palette.text.light,
    }
}));

export const ImportFile = ({ forceUpdate }) => {

    const dispatch = useDispatch();
    const { selected: bankSelected } = useSelector(selectBank);

    const [uploadManual, setUploadManual] = useState(false);
    const [importMenu, setImportMenu] = useState(null);

    const inputRef = React.createRef();
    const [file, setFile] = useState(null);
    const [dataConfirm, setDataConfirm] = useState(null);

    const _defineGroupOfOrderCodes = (list = []) => {
        let newList = [];
        list.forEach(val => {
            let index = newList.findIndex((item) => item.code === val.contractId);
            val.orderQuantity /= 1000;
            val.unitPrice *= 1000;
            if (index === -1) {
                newList.push({
                    code: val.contractId,
                    children: [val],
                });
            } else {
                newList[index].children.push(val);
            }
        });
        return newList;
    }

    const onUploadFile = () => {
        let body = new FormData();
        file?.forEach((val) => {
            body.append('file', val);
        });
        OrderService.importOrder(body, dispatch).then(({ data: { data } }) => {
            if (data && data.purchaseOrders && data.purchaseOrders.length > 0) {
                setDataConfirm({
                    ...data,
                    purchaseOrders: _defineGroupOfOrderCodes(data.purchaseOrders)
                });
                setFile(null);
                inputRef.current.value = "";
            } else {
                dispatch(showMessageWithStatus("Tệp của bạn đúng không đúng tiêu chuẩn", MESSAGE_STATUS.warning));
            }
        }).catch((err => _showErrorMessageFromAPI(err, { maxWidth: "lg" })));

    }

    const onConfirmOrder = () => {
        let orders = dataConfirm.purchaseOrders.map(val => val.children).flat(1).map(val => ({
            ...val,
            orderQuantity: val.orderQuantity * 1000,
            unitPrice: val.unitPrice / 1000
        }));
        let body = {
            purchaseOrders: orders,
            portId: bankSelected.storageOfBank.id,
            bankId: bankSelected.bankId
        };
        OrderService.confirmOrder(body, dispatch).then(({ data }) => {
            forceUpdate();
            setDataConfirm(null);
            dispatch(showMessageWithStatus("Đơn hàng đã nhập thành công", MESSAGE_STATUS.success))
        }).catch((err => {
            console.log(err)
        }));
    }

    const onSelectedFiles = ({ target: { files } }) => {
        let newFiles = [];
        for (let i = 0; i < files.length; i++) {
            const element = files[i];
            newFiles.push(element);
        }
        if (file != null) {
            setFile(file.concat(newFiles));
        } else {
            setFile(newFiles);
        }
    };

    const onRemoveFile = (index) => {
        file.splice(index, 1);
        setFile([...file]);
    }

    const onImportMenuClick = (event) => {
        setImportMenu(event.currentTarget);
    };

    const onCloseMenu = () => {
        setImportMenu(null);
    };

    const onUploadDataManual = (data = []) => {
        let newData = data.map(val => ({
            ...val,
            lcDate: moment(val.lcDate).format("YYYY-MM-DD"),
            orderQuantity: val.orderQuantity * 1000,
            receivedQuantity: val.receivedQuantity * 1000,
            unitPrice: val.unitPrice / 1000,
        }));

        let body = {
            purchaseOrders: newData,
            portId: bankSelected.storageOfBank.id,
            bankId: bankSelected.bankId
        };
        OrderService.confirmOrder(body, dispatch).then(({ data }) => {
            setUploadManual(false);
            forceUpdate();
            dispatch(showMessageWithStatus("Đơn hàng đã nhập thành công", MESSAGE_STATUS.success))
        }).catch((err => _showErrorMessageFromAPI(err, { maxWidth: "md" })));
    }

    const _showErrorMessageFromAPI = (err = {}, option = {}) => {
        if (err.response?.status === 400) {
            dispatch(openDialog({
                ...option,
                children: (
                    <React.Fragment>
                        <DialogTitle>Lỗi nhập liệu</DialogTitle>
                        <DialogContent>
                            <DialogContentText className='whitespace-pre'>
                                {err.response?.data?.messages?.map(v => "- " + v).join("\n") ?? "Lỗi dữ liệu"}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => dispatch(closeDialog())} color="primary" autoFocus>
                                OK
                            </Button>
                        </DialogActions>
                    </React.Fragment>
                )
            }));
        } else {
            console.error(err);
        }
    }

    return (
        <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}
        >
            <input ref={inputRef} type='file' className='hidden' multiple
                onChange={onSelectedFiles}
            />
            <Root>
                <Button
                    className='bg-dark-theme' variant="contained" color="secondary"
                    startIcon={<SvgIcon>heroicons-outline:upload</SvgIcon>}
                    onClick={onImportMenuClick}
                >
                    Nhập
                </Button>
            </Root>

            <Popover
                open={Boolean(importMenu)}
                anchorEl={importMenu}
                onClose={onCloseMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                classes={{
                    paper: 'py-8',
                }}
            >
                <MenuItem onClick={() => {
                    inputRef.current?.click();
                    setFile([]);
                    onCloseMenu();
                }}>
                    <ListItemIcon className="min-w-40">
                        <SvgIcon>heroicons-outline:document-add</SvgIcon>
                    </ListItemIcon>
                    <ListItemText primary="Nhập từ tệp" />
                </MenuItem>
                <MenuItem onClick={() => {
                    setUploadManual(true);
                    onCloseMenu()
                }}>
                    <ListItemIcon className="min-w-40">
                        <SvgIcon>heroicons-outline:table</SvgIcon>
                    </ListItemIcon>
                    <ListItemText primary="Nhập từ mẫu" />
                </MenuItem>
                <MenuItem onClick={() => {
                    history.push("/app/dashboard/release")
                }}>
                    <ListItemIcon className="min-w-40">
                        <SvgIcon>heroicons-outline:document-add</SvgIcon>
                    </ListItemIcon>
                    <ListItemText primary="Giải chấp từ excel" />
                </MenuItem>
            </Popover>
            <FileUploadDialog
                files={file}
                setUploadManual={() => {
                    inputRef.current.value = "";
                    setFile(null);
                    setUploadManual(true);
                }}
                closeDialog={() => {
                    inputRef.current.value = "";
                    setFile(null);
                }}
                onRemove={onRemoveFile}
                onPressAdd={() => inputRef.current?.click()}
                onOk={onUploadFile}
            />
            <ImportManualDialog
                bankSelected={bankSelected}
                open={uploadManual}
                setUploadManual={() => {
                    setUploadManual(false);
                    setFile([]);
                }}
                closeDialog={() => {
                    setUploadManual(false);
                }}
                onRemove={onRemoveFile}
                onUploadDataManual={onUploadDataManual}
            />
            <ConfirmDialog bankSelected={bankSelected} item={dataConfirm} closeDialog={() => setDataConfirm(null)} onOk={onConfirmOrder} />
        </motion.div>
    )
}