import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

const getTimeString = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return (minutes > 0 ? `${minutes} phút` : "") + ((seconds > 0 || minutes === 0) ? ` ${seconds} giây` : "");
};

let tmpTime = 0;
let timeOutId = null;

export const TimeCounter = ({ timeMax, message, onEndTime = () => { } }) => {
    const [timeCounter, setTimeCounter] = useState(1);

    useEffect(() => {
        if (timeCounter == 0) {
            onEndTime();
        }
    }, [timeCounter]);

    useEffect(() => {
        tmpTime = timeMax;
        countDown();
    }, []);

    const countDown = () => {
        clearTimeout(timeOutId);
        tmpTime--;
        setTimeCounter(tmpTime);
        if (tmpTime > 0) {
            timeOutId = setTimeout(countDown, 1000);
        }
    }

    return <p className='italic text-center py-8'>
        {message} {getTimeString(timeCounter)}
    </p>
}