import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  state: null,
  options: {
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
    autoHideDuration: 2000,
    message: '',
    variant: null,
  },
};
const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    showMessage: (state, action) => {
      state.state = true;
      state.options.variant = "info";
      state.options.message = action.payload;
    },
    showMessage2: (state, action) => {
      state.state = true;
      state.options = {
        ...initialState.options,
        ...action.payload,
      }
    },
    hideMessage: (state, action) => {
      state.state = null;
    },
  },
});

export const MESSAGE_STATUS = {
  success: 'success',
  warning: 'warning',
  error: 'error',
  info: 'info',
};

export const showMessageWithStatus = (message, status = "info", option = {}) => async (dispatch) => {
  const newMessage = {
    ...option,
    message,
    variant: status
  }
  dispatch(messageSlice.actions.showMessage2(newMessage));
}

export const { hideMessage, showMessage } = messageSlice.actions;

export const selectMessageState = ({ adg }) => adg.message.state;

export const selectMessageOptions = ({ adg }) => adg.message.options;

export default messageSlice.reducer;
