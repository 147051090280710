import React, { } from 'react';
import { Dialog, DialogTitle, Button, DialogActions, styled, Typography, TextField, Select, InputAdornment, MenuItem, InputLabel, Autocomplete, Checkbox, FormControl, TextareaAutosize, RadioGroup, FormControlLabel, Radio, FormLabel, FormHelperText } from "@mui/material";
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import _ from 'lodash';

import { yupResolver } from '@hookform/resolvers/yup';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectBank } from '../store/bankSlice';

const Root = styled("div")(({ theme }) => {
    return ({
        '&': {
            minHeight: "30vh"
        },
        '& .bg-dark-theme': {
            backgroundColor: theme.palette.background.dark,
            color: theme.palette.text.light,
        },
        '& .bg-dark-theme:disabled': {
            backgroundColor: theme.palette.grey[400],
            color: theme.palette.text.light,
        }
    })
});
const TRANSACTION_TYPE = {
    PAID_EXPORT: "Giải chấp",
    ONLY_PAID: "Trả giải chấp",
    ONLY_EXPORT: "Nợ giải chấp",
}

const DialogContent = ({ item, onCancel, onOk, onChangeType }) => {
    if (!item) return null;
    const stock = ["ONLY_EXPORT", "PAID_EXPORT"].includes(item.transactionType) ? item.actualInventoryQuantity : item.inventoryQuantity;

    const giaChap = yup.object().shape({
        type: yup.string(),
        quantityNum: yup.number().typeError("Vui lòng nhập số lượng hàng hóa")
            .positive("Số lượng cần lớn hơn 0")
            .lessThan(
                yup.ref("type", {
                    map: (val) => ["ONLY_EXPORT", "PAID_EXPORT"].includes(val) ? item.actualInventoryQuantity : item.inventoryQuantity
                }),
                (val) => "Số lượng cần nhỏ hơn hoặc bằng " + (val.less / 1000).toLocaleString("vi-VN", { minimumFractionDigits: 0 }))
            .required('Vui lòng nhập giá trị'),
        note: yup.string()
    });
    const giaChapDefaultValues = {
        type: 'PAID_EXPORT',
        quantityNum: '',
        note: ''
    };
    const { control, formState, watch, handleSubmit } = useForm({
        mode: 'onChange',
        defaultValues: giaChapDefaultValues,
        resolver: yupResolver(giaChap),
    });
    const { selected: bankSelected } = useSelector(selectBank);

    const { isValid, dirtyFields, errors } = formState;

    const onSubmit = (data) => onOk({
        id: item.orderId,
        releaseQuantity: data.quantityNum * 1000,
        note: data.note,
        type: data.type,
    }, TRANSACTION_TYPE[data.type]);

    return (
        <form className="flex flex-col justify-center w-full mt-16" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-row mx-4 mb-16">
                <div className='flex-1 flex'>
                    <div className='flex-1'>
                        <Typography>
                            Tồn sổ sách:
                        </Typography>
                        <Typography variant="h6" flex={1}>{(item.inventoryQuantity / 1000).toLocaleString("vi-VN")} (Tấn)</Typography>
                    </div>
                    <div className='flex-1'>
                        <Typography>
                            Tồn thực tế:
                        </Typography>
                        <Typography variant="h6" flex={1}>{(item.actualInventoryQuantity / 1000).toLocaleString("vi-VN")} (Tấn)</Typography>
                    </div>
                </div>
                <div className='flex-1'>
                    <Controller
                        name="type"
                        control={control}
                        render={({ field }) => (
                            <FormControl variant="standard">
                                <FormLabel id="demo-error-radios">Loại giao dịch</FormLabel>
                                <RadioGroup
                                    row
                                    {...field}
                                >
                                    {Object.entries(TRANSACTION_TYPE).map(([key, val]) =>
                                        <FormControlLabel key={key} value={key} control={<Radio onClick={() => onChangeType(val)} />} label={val} />
                                    )}
                                </RadioGroup>
                            </FormControl>
                        )}
                    />
                </div>
            </div>
            <FormControl fullWidth>
                <div className='flex flex-row'>

                    <Controller
                        name="quantityNum"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                className="mb-24 mx-4 flex-1"
                                label="Số lượng"
                                type="number"
                                error={!!errors.quantityNum}
                                helperText={errors?.quantityNum?.message}
                                required
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">Tấn</InputAdornment>,
                                }}
                            />
                        )}
                    />
                    <Controller
                        name="note"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                className="mb-24 mx-4" style={{ flex: 2 }}
                                label="Ghi chú"
                            />
                        )}
                    />
                </div>
                {isValid &&
                    <div className="mb-24">
                        {watch().type === "PAID_EXPORT" ?
                            <Typography>
                                Số lượng còn lại sau giải chấp:{" "}
                                <span className='font-bold'>
                                    {((stock - watch().quantityNum * 1000) / 1000).toLocaleString("vi-VN")}
                                </span> (Tấn)
                            </Typography>
                            :
                            <Typography>
                                {TRANSACTION_TYPE[watch().type] + " "}
                                tại <span className='font-bold'>{bankSelected.bankName}</span>,
                                mã hàng <span className='font-bold'>{item.productId} - {item.contractId}</span>,
                                số lượng <span className='font-bold'>{((watch().quantityNum * 1000) / 1000).toLocaleString("vi-VN")}</span> (Tấn)
                            </Typography>
                        }
                    </div>
                }
            </FormControl>

            <DialogActions>
                <Button color="primary"
                    onClick={onCancel}
                >
                    Hủy
                </Button>
                <Button className='bg-dark-theme' color="primary" type="submit" autoFocus variant='contained'
                    disabled={_.isEmpty(dirtyFields) || !isValid}
                >
                    Xác nhận
                </Button>
            </DialogActions>
        </form>
    )
};


export const PaymentDialog2 = ({ item, closeDialog = () => { }, onOk = () => { } }) => {
    const titleRef = useRef();
    return (
        <Dialog onClose={closeDialog} open={item != null} maxWidth="md" fullWidth={true}
            classes={{ paper: 'rounded-8', }}>
            <Root>
                <DialogTitle className="bg-dark-theme">
                    <div className='flex flex-row justify-center'>
                        <p ref={titleRef} className='flex-1'>Giải chấp</p>
                    </div>
                </DialogTitle>
                <div className='px-24'>
                    <DialogContent item={item} onCancel={closeDialog} onOk={onOk}
                        onChangeType={(typeText = "") => {
                            titleRef.current.innerText = typeText;
                        }}
                    />
                </div>
            </Root>
        </Dialog >
    )
};
