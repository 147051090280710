import React, { } from 'react';
import { useSelector } from 'react-redux';

import { SelectColumn } from './components/SelectColumn';
import { SelectStorage } from './components/SelectStorage';
import { SelectBank } from './components/SelectBank';
import { ImportFile } from './components/ImportFile';
import { TabControl } from './components/TabControl';
import { FilterData } from './components/FilterData';
import { selectStorages } from './store/storageSlice';
import { ExportData } from './components/ExportData';
import { colsPort } from './tables/port/PortTableHead';
import { colsHistory } from './tables/history/HistoryTableHead';
import { colsOther } from './tables/other/OtherTableHead';
import { selectBank } from './store/bankSlice';

const DashboardHeader = ({ forceUpdate }) => {
  const tab = useSelector(selectStorages);
  const { selected: bankSelected } = useSelector(selectBank);

  const tabColums = [
    {
      id: "port-" + bankSelected?.bankId,
      rows: colsPort
    },
    {
      id: "history-" + bankSelected?.bankId,
      rows: colsHistory
    },
  ]

  tab.selectedList?.forEach(t => {
    tabColums.push({
      id: t.id,
      rows: colsOther
    });
  });

  return (
    <div className="relative flex flex-col sm:flex-row w-full items-center pt-8 pb-4 space-x-8">
      <div className="relative flex flex-1 pt-1 flex-col sm:flex-row sm:space-y-0 items-center justify-start overflow-hidden space-x-8">
        <SelectBank />
        <TabControl bankId={bankSelected?.bankId} className="overflow-hidden" />
        <SelectStorage bankId={bankSelected?.bankId} />
      </div>
      <div className="flex flex-row items-center justify-end space-x-8">
        <ExportData id={tabColums[tab.index].id} rows={tabColums[tab.index].rows} />
        <FilterData id={tabColums[tab.index].id} rows={tabColums[tab.index].rows} />
        <SelectColumn id={tabColums[tab.index].id} rows={tabColums[tab.index].rows} />
        <ImportFile forceUpdate={forceUpdate} />
      </div>
    </div>
  );
}

export default DashboardHeader;
