import ChangePasswordPage from '.';

const ChangePasswordConfig = {
    settings: {
        layout: {
            config: {
                title: "THAY ĐỔI MẬT KHẨU",
                navbar: {
                    display: true,
                },
                toolbar: {
                    display: true,
                },
                footer: {
                    display: true,
                },
                leftSidePanel: {
                    display: true,
                },
            },
        },
    },
    roles: ["ROLE_SYSTEM_ADMIN", "ROLE_EMPLOYEE", "ROLE_GUEST"],
    routes: [
        {
            path: 'app/change-password',
            element: <ChangePasswordPage />,
        },
    ],
};

export default ChangePasswordConfig;
