import Loading from '@adg/core/Loading';
import PropTypes from 'prop-types';
import { Suspense as ReactSuspense } from 'react';

const Suspense = (props) => {
  return <ReactSuspense fallback={<Loading {...props.loadingProps} />}>{props.children}</ReactSuspense>;
}

Suspense.propTypes = {
  loadingProps: PropTypes.object,
};

Suspense.defaultProps = {
  loadingProps: {
    delay: 0,
  },
};

export default Suspense;
