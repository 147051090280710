import { ThemeProvider } from '@mui/material/styles';
import { memo, useEffect } from 'react';

function AdgTheme(props) {
  const { theme } = props;
  const { mode } = theme.palette;

  useEffect(() => {
    document.body.classList.add('light');
  }, [mode]);

  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
}

export default memo(AdgTheme);
