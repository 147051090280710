import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, Button, DialogActions, styled, Box } from "@mui/material";
import _ from 'lodash';

import FileItem from '../components/FileItem';

const Root = styled("div")(({ theme }) => ({
    '&': {
        minHeight: "30vh"
    },
    '& .bg-dark-theme': {
        backgroundColor: theme.palette.background.dark,
        color: theme.palette.text.light,
    },
    '& .bg-dark-theme:disabled': {
        backgroundColor: theme.palette.grey[400],
        color: theme.palette.text.light,
    }
}));

let tmpValidData = [true];

export const FileUploadDialog = ({
    setUploadManual, files,
    closeDialog = () => { },
    onPressAdd = () => { }, onRemove = () => { }, onOk = () => { },
}) => {
    const [isValidForm, hadValidForm] = useState(true);

    useEffect(() => {
        if (files?.length === 0) {
            hadValidForm(false);
        } else {
            hadValidForm(true);
        }
    }, [files]);

    return (
        <Dialog onClose={closeDialog} open={files != null} maxWidth="md" fullWidth={true}
            classes={{ paper: 'rounded-8', }}>
            <Root>
                <DialogTitle className="bg-dark-theme">
                    <div className='flex flex-row justify-center'>
                        <p className='flex-1'>Nhập dữ liệu</p>
                        <Box>
                            <Button className='px-40' variant='outlined' color='info' size="small"
                                onClick={() => setUploadManual(true)}
                            >
                                Nhập bằng mẫu
                            </Button>

                        </Box>
                    </div>
                </DialogTitle>
                <div>
                    <div className="flex flex-wrap mt-8">
                        {files && files.map((val, index) =>
                            <FileItem key={index} index={index} item={val} onRemove={onRemove} />
                        )}
                        <FileItem isAddNew={true} onAdd={onPressAdd} />

                    </div>
                    <DialogActions>
                        <Button color="primary"
                            onClick={closeDialog}
                        >
                            Hủy
                        </Button>
                        <Button className='bg-dark-theme' color="primary" variant='contained' autoFocus disabled={!isValidForm}
                            onClick={() => onOk()}
                        >
                            Nhập
                        </Button>
                    </DialogActions>
                </div>
            </Root>
        </Dialog >
    );
};
