import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import SvgIcon from '@adg/core/SvgIcon';
import { selectUser } from 'app/store/userSlice';

const UserMenu = () => {
  const user = useSelector(selectUser);

  const [userMenu, setUserMenu] = useState(null);

  const userMenuClick = (event) => {
    setUserMenu(event.currentTarget);
  };

  const userMenuClose = () => {
    setUserMenu(null);
  };

  return (
    <>
      <Button
        className="min-h-40 min-w-40 px-0 mr-24 md:pl-16 py-0 md:py-6 bg-transparent"
        onClick={userMenuClick}
        color="inherit"
      >
        <div className="hidden md:flex flex-col mx-4 items-end">
          <Typography component="span" className="font-semibold flex">
            {user.info.fullName}
          </Typography>
        </div>
        <Avatar className="md:mx-4">{user.info.fullName.charAt(0)}</Avatar>
      </Button>

      <Popover
        open={Boolean(userMenu)}
        anchorEl={userMenu}
        onClose={userMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: 'py-8',
        }}
      >
        <MenuItem
          component={NavLink}
          to="/app/change-password"
          onClick={() => {
            userMenuClose();
          }}
        >
          <ListItemIcon className="min-w-80">
            <SvgIcon>heroicons-outline:key</SvgIcon>
          </ListItemIcon>
          <ListItemText primary="Thay đổi mật khẩu" />
        </MenuItem>
        <MenuItem
          component={NavLink}
          to="/logout"
          onClick={() => {
            userMenuClose();
          }}
        >
          <ListItemIcon className="min-w-80">
            <SvgIcon>heroicons-outline:logout</SvgIcon>
          </ListItemIcon>
          <ListItemText primary="Đăng xuất" />
        </MenuItem>
      </Popover>
    </>
  );
}

export default UserMenu;
