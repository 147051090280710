import React, { createRef, useEffect, useState } from 'react';
import { Dialog, DialogTitle, Button, DialogActions, styled, Typography, Box, InputAdornment, TextField, Select, MenuItem, FormHelperText } from "@mui/material";
import _ from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { useSelector } from 'react-redux';
import { selectRole } from '../store/roleSlice';


const Root = styled("div")(({ theme }) => ({
    '&': {
        minHeight: "30vh"
    },
    '& .bg-dark-theme': {
        backgroundColor: theme.palette.background.dark,
        color: theme.palette.text.light,
    },
    '& .bg-dark-theme:disabled': {
        backgroundColor: theme.palette.grey[400],
        color: theme.palette.text.light,
    }
}));


const defaultData = {
    username: "",
    password: "",
    rePassword: "",
    fullName: "",
    roleName: "",
}

export const CreateUserDialog = ({ open = false, closeDialog = () => { }, onOk = () => { } }) => {

    const { list: roleList } = useSelector(selectRole);

    useEffect(() => {
        if (open) {
            reset(defaultData);
        }
    }, [open]);

    const confirmValidate = yup.object().shape({
        username: yup.string().required("Vui lòng nhập thông tin").matches(/([0-9A-Za-z\.]{4,})/, "Tài khoản không hợp lệ"),
        password: yup.string().required("Vui lòng nhập thông tin").matches(/([0-9A-Za-z!@#$%&]{8,})/, "Mật khẩu cần ít nhất 8 ký tự"),
        rePassword: yup.string().required("Vui lòng nhập thông tin"),
        fullName: yup.string().required("Vui lòng nhập thông tin"),
        roleName: yup.string().required("Vui lòng chọn thông tin"),
    });

    const { control, formState, setValue, handleSubmit, setError, getValues, reset, clearErrors } = useForm({
        mode: 'onChange',
        defaultValues: defaultData,
        resolver: yupResolver(confirmValidate),
    });

    const { errors, dirtyFields, isValid } = formState;


    const onSubmit = (data) => {
        onOk(data, (usernameMessage) => {
            setError("username", {
                message: usernameMessage,
                type: "value"
            })
        })
    }

    return (
        <Dialog onClose={closeDialog} open={open} maxWidth="md" fullWidth={true}
            classes={{ paper: 'rounded-8', }}>
            <Root>
                <DialogTitle className="bg-dark-theme">
                    <div className='flex flex-row justify-center'>
                        <p className='flex-1'>Tạo người dùng mới</p>
                    </div>
                </DialogTitle>

                <div className="flex flex-col justify-center w-full p-8">
                    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                        <div className='flex flex-row my-8 '>
                            <div className='flex flex-1 flex-row items-center min-h-40'>
                                <Typography className='flex-1' align='right' fontWeight="bold">
                                    <span className='pr-24'>
                                        Tên tài khoản:
                                    </span>
                                </Typography>
                                <Controller
                                    name="username"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            className='flex-1' variant="standard" size="small"
                                            error={!!errors?.username}
                                            helperText={errors?.username?.message}
                                        />
                                    )}
                                />
                            </div>
                            <div className='flex flex-1 flex-row items-center min-h-40'>
                                <Typography className='flex-1' align='right' fontWeight="bold">
                                    <span className='pr-24'>
                                        Họ và tên:
                                    </span>
                                </Typography>
                                <Controller
                                    name="fullName"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            className='flex-1' variant="standard" size="small"
                                            error={!!errors?.fullName}
                                            helperText={errors?.fullName?.message}
                                        />
                                    )}
                                />
                            </div>

                        </div>
                        <div className='flex flex-row my-8 '>
                            <div className='flex flex-1 flex-row items-center min-h-40'>
                                <Typography className='flex-1' align='right' fontWeight="bold">
                                    <span className='pr-24'>
                                        Mật khẩu mới:
                                    </span>
                                </Typography>
                                <Controller
                                    name="password"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                if (getValues("rePassword") !== "") {
                                                    if (e.target.value !== getValues("rePassword")) {
                                                        setError("rePassword", {
                                                            message: "Mật khẩu không trùng khớp",
                                                            type: "value"
                                                        });
                                                    } else {
                                                        clearErrors("rePassword");
                                                    }
                                                }
                                            }}
                                            className='flex-1' variant="standard" size="small"
                                            type="password"
                                            error={!!errors?.password}
                                            helperText={errors?.password?.message}
                                        />
                                    )}
                                />
                            </div>
                            <div className='flex flex-1 flex-row items-center min-h-40'>
                                <Typography className='flex-1' align='right' fontWeight="bold">
                                    <span className='pr-24'>
                                        Nhập lại mật khẩu:
                                    </span>
                                </Typography>

                                <Controller
                                    name="rePassword"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            onChange={(e) => {
                                                setValue("rePassword", e.target.value);
                                                if (e.target.value !== getValues("password")) {
                                                    setError("rePassword", {
                                                        message: "Mật khẩu không trùng khớp",
                                                        type: "value"
                                                    });
                                                } else {
                                                    clearErrors("rePassword");
                                                }
                                            }}
                                            className='flex-1' variant="standard" size="small"
                                            type="password"
                                            error={!!errors?.rePassword}
                                            helperText={errors?.rePassword?.message}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className='flex flex-row my-8'>
                            <div className='flex flex-1 flex-row items-center min-h-40'>
                                <Typography className='flex-1' align='right' fontWeight="bold">
                                    <span className='pr-24'>
                                        Role:
                                    </span>
                                </Typography>
                                <Controller
                                    name="roleName"
                                    control={control}
                                    render={({ field }) => (
                                        <Box className='flex-1'>
                                            <Select {...field} fullWidth variant='standard'
                                                error={!!errors.roleName}
                                            >
                                                {roleList.map((val, i) => (
                                                    <MenuItem key={i} value={val.name}>
                                                        {val.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {!!errors.roleName && <FormHelperText error={true}>{errors.roleName?.message}</FormHelperText>}
                                        </Box>
                                    )}
                                />
                            </div>
                            <div className='flex flex-1 flex-row items-center min-h-40'>
                            </div>
                        </div >

                        <DialogActions>
                            <Button color="primary"
                                onClick={closeDialog}
                            >
                                Hủy
                            </Button>
                            <Button className='bg-dark-theme' color="primary" type="submit" autoFocus variant='contained'
                                disabled={_.isEmpty(dirtyFields) || !isValid}
                            >
                                Tạo mới
                            </Button>
                        </DialogActions>
                    </Box >
                </div>
            </Root>
        </Dialog >
    );
};
