import React, { useContext, memo, useCallback, useRef } from 'react';
import _ from '@lodash';
import AppContext from 'app/AppContext';
import {
  selectCurrentLayoutConfig,
  selectCurrentSettings,
  selectDefaultSettings,
  setSettings,
} from 'app/store/adg/settingsSlice';
import { useDeepCompareEffect } from '@adg/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { matchRoutes, useLocation, useRoutes } from 'react-router-dom';
import GlobalStyles from '@mui/material/GlobalStyles';
import { alpha } from '@mui/material/styles';
import Dialog from '../Dialog';
import Suspense from '../Suspense';
import Message from '../Message';
import NavbarWrapperLayout from './components/NavbarWrapperLayout';
import ToolbarLayout from './components/ToolbarLayout';
import FooterLayout from './components/FooterLayout';

const inputGlobalStyles = (
  <GlobalStyles
    styles={(theme) => {

      let obj = {
        html: {
          backgroundColor: `${theme.palette.background.default}!important`,
          color: `${theme.palette.text.primary}!important`,
        },
        body: {
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.primary,
        },
        'table.simple tbody tr td': {
          borderColor: theme.palette.divider,
        },
        'table.simple thead tr th': {
          borderColor: theme.palette.divider,
        },
        'a:not([role=button]):not(.MuiButtonBase-root)': {
          color: theme.palette.secondary.main,
          textDecoration: 'underline',
          '&:hover': {},
        },
        'a.link, a:not([role=button])[target=_blank]': {
          background: alpha(theme.palette.secondary.main, 0.2),
          color: 'inherit',
          borderBottom: `1px solid ${theme.palette.divider}`,
          textDecoration: 'none',
          '&:hover': {
            background: alpha(theme.palette.secondary.main, 0.3),
            textDecoration: 'none',
          },
        },
        '[class^="border"]': {
          borderColor: theme.palette.divider,
        },
        '[class*="border"]': {
          borderColor: theme.palette.divider,
        },
        '[class*="divide-"] > :not([hidden]) ~ :not([hidden])': {
          borderColor: theme.palette.divider,
        },
        hr: {
          borderColor: theme.palette.divider,
        },

        '::-webkit-scrollbar-thumb': {
          boxShadow: `inset 0 0 0 20px rgba(0, 0, 0, 0.24)`,
        },
        '::-webkit-scrollbar-thumb:active': {
          boxShadow: `inset 0 0 0 20px rgba(0, 0, 0, 0.37)`,
        },
      }
      return obj;
    }
    }
  />
);

const LayoutRoot = memo(() => {

  const appContext = useContext(AppContext);
  const config = useSelector(selectCurrentLayoutConfig);
  const { routes } = appContext;
  return (
    <div id="adg-layout" className="w-full flex">
      <div className="flex flex-auto min-w-0">
        {config?.navbar?.display && <NavbarWrapperLayout />}

        <main id="adg-main" className="flex flex-col flex-auto min-h-full min-w-0 relative z-10">
          {config?.toolbar?.display && <ToolbarLayout className='sticky top-0' />}

          <div className="flex flex-col flex-auto min-h-0 relative z-10">
            <Dialog />

            <Suspense>{useRoutes(routes)}</Suspense>

          </div>

          {/* {config?.footer?.display && <FooterLayout />} */}
        </main>

      </div>
      <Message />
    </div>
  )
});

const Layout = (props) => {
  const appContext = useContext(AppContext);
  const dispatch = useDispatch();
  const settings = useSelector(selectCurrentSettings);
  const defaultSettings = useSelector(selectDefaultSettings);

  const { routes } = appContext;

  const location = useLocation();
  const { pathname } = location;

  const matchedRoutes = matchRoutes(routes, pathname);
  const matched = matchedRoutes ? matchedRoutes[0] : false;

  const newSettings = useRef(null);

  const shouldAwaitRender = useCallback(() => {
    let _newSettings;

    if (matched && matched.route.settings) {

      let routeSettings = {};
      matchedRoutes.forEach((val) => {
        routeSettings = _.merge(routeSettings, val.route.settings);
      });
      _newSettings = { ...defaultSettings, ...routeSettings };
    } else if (!_.isEqual(newSettings.current, defaultSettings)) {
      _newSettings = { ...defaultSettings };
    } else {
      _newSettings = newSettings.current;
    }
    if (!_.isEqual(newSettings.current, _newSettings)) {
      newSettings.current = _newSettings;
    }
  }, [defaultSettings, matched]);

  shouldAwaitRender();

  useDeepCompareEffect(() => {
    if (!_.isEqual(newSettings.current, settings)) {
      dispatch(setSettings(newSettings.current));
    }
  }, [dispatch, newSettings.current, settings]);

  return (<>
    {inputGlobalStyles}
    <LayoutRoot />
  </>)
}

export default memo(Layout);
