import Utils from '@adg/utils/Utils';
import { showMessage } from 'app/store/adg/messageSlice';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import jwtServiceConfig from './jwtServiceConfig';

class JwtService extends Utils.EventEmitter {
  init() {
    this.setInterceptors();
    this.handleAuthentication();
  }

  setInterceptors = () => {
    axios.interceptors.response.use((response) => response, (err) => {
      return new Promise((resolve, reject) => {
        if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
          // if you ever get an unauthorized response, logout the user
          this.emit('onAutoLogout', 'Invalid access_token');
          this.setSession(null);
        }
        throw err;
      });
    });
  };

  handleAuthentication = () => {
    const access_token = this.getAccessToken();
    if (!access_token) {
      this.emit('onNoAccessToken');
      return;
    }

    if (this.isAuthTokenValid(access_token)) {
      this.setSession(access_token, null, true);
      this.emit('onAutoLogin', true);
    } else {
      this.setSession(null);
      this.emit('onAutoLogout', 'access_token expired');
    }
  };

  loginWithUsernameAndPassword = (username, password, isRemenber) =>
    axios.post(jwtServiceConfig.login, {
      username,
      password,
    }).then((response) => {
      if (response.data) {
        let { token, ...user } = response.data;
        this.setSession(token, user, isRemenber);
        this.emit('onLogin', user);
      } else {
        throw ("Mật khẩu không đúng");
      }
    });

  loginWithToken = () => {
    return new Promise((resolve, reject) => {
      let profile = localStorage.getItem('profile');
      if (profile) {
        profile = JSON.parse(profile);
        resolve(profile);
      } else {
        resolve({
          username: "unknown",
          fullName: 'unknown',
          roles: 'unknown',
        });
      }
      //   axios.get(jwtServiceConfig.accessToken, {
      //     data: { access_token: this.getAccessToken() }
      //   }).then((response) => {
      //     if (response.data.user) {
      //       this.setSession(response.data.access_token);
      //       resolve(response.data.user);
      //     } else {
      //       this.logout();
      //       reject(new Error('Failed to login with token.'));
      //     }
      //   }).catch((error) => {
      //     this.logout();
      //     reject(new Error('Failed to login with token.'));
      //   });
    });
  };

  setSession = (access_token, user, isRemenber = false) => {
    if (access_token) {
      if (isRemenber) {
        localStorage.setItem('jwt_access_token', access_token);
        if (user) {
          localStorage.setItem('profile', JSON.stringify(user));// remove
        }
      }
      axios.defaults.headers.common.Authorization = `${access_token}`;
    } else {
      localStorage.removeItem('jwt_access_token');
      localStorage.removeItem('profile');// remove
      delete axios.defaults.headers.common.Authorization;
    }
  };

  logout = () => {
    this.setSession(null);
    this.emit('onLogout', 'Logged out');
  };

  isAuthTokenValid = (access_token) => {
    if (!access_token) {
      return false;
    }
    const decoded = jwtDecode(access_token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn('access token expired');
      return false;
    }

    return true;
  };

  getAccessToken = () => {
    return window.localStorage.getItem('jwt_access_token');
  };
}

const instance = new JwtService();

export default instance;
