import { Input, Paper } from "@mui/material"
import React, { useState } from "react";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";

import SvgIcon from "@adg/core/SvgIcon";

import { selectSearch, setSeach } from "../store/searchSlice";

export const SeachUser = () => {
    const dispatch = useDispatch();
    const search = useSelector(selectSearch);

    const onChange = (text) => {
        dispatch(setSeach(text));
    }

    return (
        <Paper
            component={motion.div}
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
            className="flex items-center w-full sm:max-w-360 space-x-8 px-16 rounded-full border-1 shadow-0"
        >
            <SvgIcon color="disabled">heroicons-solid:search</SvgIcon>

            <Input
                placeholder="Tìm kiếm tên, username..."
                className="flex flex-1"
                disableUnderline
                fullWidth
                value={search}
                inputProps={{
                    'aria-label': 'Search',
                }}
                onChange={({ target: { value } }) => onChange(value)}
            />
        </Paper>
    )
}