import { yupResolver } from '@hookform/resolvers/yup';
import { Box, InputAdornment, MenuItem, Select, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

const statusList = [
    { name: "Đã hoàn thành", value: "COMPLETED" },
    { name: "Đang thực hiện", value: "PROCESSING" },
]

export const InfoItem = ({ data, editAll = false, onAddValidData = () => { } }) => {
    const [item] = useState(data);
    let tiGia = localStorage.getItem("TI_GIA");

    const confirmValidate = yup.object().shape({
        contractId: yup.string().required("Vui lòng nhập mã chính xác"),
        productId: yup.string().required("Vui lòng nhập mã chính xác"),
        note: yup.string(),
        description: yup.string(),
        lcDate: yup.string(),
        providerCode: yup.string().required("Vui lòng nhập mã nhà cung cấp"),
        orderQuantity: yup.number().typeError("Vui lòng nhập số lượng")
            .positive("Số lượng cần lớn hơn 0")
            .required('Vui lòng nhập giá trị'),
        receivedQuantity: yup.number().typeError("Vui lòng nhập số lượng")
            .positive("Số tiền cần lớn hơn 0")
            .required('Vui lòng nhập giá trị'),
        unitPrice: yup.number().typeError("Vui lòng nhập đơn giá")
            .positive("Số tiền cần lớn hơn 0")
            .required('Vui lòng nhập giá trị'),
        status: yup.string(),
        lcDate: yup.date().typeError("Thời gian không phù hợp").required("Vui lòng nhập ngày LC"),
    });

    const { control, formState, handleSubmit, setError, watch, setValue, getValues } = useForm({
        mode: 'onChange',
        defaultValues: item,
        resolver: yupResolver(confirmValidate),
    });

    const { isValid, errors } = formState;

    const onUpdateRootData = () => {
        if (!_.isEqual({ ...data, ...getValues() }, data)) {
            onAddValidData(getValues(), isValid);
        }
    }

    return (
        <Box>
            {editAll &&
                <div className='flex flex-row my-8 border-b-1 border-gray-200'>
                    <div className='flex flex-1 flex-row items-center min-h-40'>
                        <Typography className='w-160' align='right' fontWeight="bold">
                            <span className='pr-12'>
                                Số KU:
                            </span>
                        </Typography>
                        <Controller
                            name="contractId"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    onBlur={onUpdateRootData}
                                    className='flex-1' variant="standard" size="small"
                                    error={!!errors.contractId}
                                    helperText={errors?.contractId?.message}
                                />
                            )}
                        />
                    </div>
                    <div className='flex flex-1 flex-row items-center min-h-40'>
                        <Typography className='w-160' align='right' fontWeight="bold">
                            <span className='pr-12'>
                                Mã hàng hàng:
                            </span>
                        </Typography>

                        <Controller
                            name="productId"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    onBlur={onUpdateRootData}
                                    className='flex-1' variant="standard" size="small"
                                    error={!!errors.productId}
                                    helperText={errors?.productId?.message}
                                />
                            )}
                        />
                    </div>

                </div>
            }

            <div className='flex flex-row my-8 border-b-1 border-gray-200'>
                <div className='flex flex-1 flex-row items-center min-h-40'>
                    <Typography className='w-160' align='right' fontWeight="bold">
                        <span className='pr-12'>
                            Mã nhà cung cấp:
                        </span>
                    </Typography>


                    <Controller
                        name="providerCode"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                onBlur={onUpdateRootData}
                                className='flex-1' variant="standard" size="small"
                                error={!!errors.providerCode}
                                helperText={errors?.providerCode?.message}
                            />
                        )}
                    />
                </div>
                <div className='flex flex-1 flex-row items-center min-h-40'>
                    <Typography className='w-160' align='right' fontWeight="bold">
                        <span className='pr-12'>
                            Đơn giá:
                        </span>
                    </Typography>

                    <Controller
                        name="unitPrice"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                onBlur={onUpdateRootData}
                                className='flex-1' variant="standard" size="small"
                                type="number"
                                error={!!errors.unitPrice}
                                helperText={errors?.unitPrice?.message}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">$/Tấn</InputAdornment>,
                                }}
                            />
                        )}
                    />
                </div>

            </div>
            <div className='flex flex-row my-8 border-b-1 border-gray-200'>
                <div className='flex flex-1 flex-row items-center min-h-40'>
                    <Typography className='w-160' align='right' fontWeight="bold">
                        <span className='pr-12'>
                            Số lượng đặt hàng:
                        </span>
                    </Typography>

                    <Controller
                        name="orderQuantity"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                onBlur={onUpdateRootData}
                                // onChange={(e) => {
                                //     setValue("receivedQuantity", e.target.value);
                                //     field.onChange(e);
                                // }}
                                className='flex-1' variant="standard" size="small"
                                type="number"
                                error={!!errors.orderQuantity}
                                helperText={errors?.orderQuantity?.message}
                                required
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">Tấn</InputAdornment>,
                                }}
                            />
                        )}
                    />
                </div>
                <div className='flex flex-1 flex-row items-center min-h-40'>
                    <Typography className='w-160' align='right' fontWeight="bold">
                        <span className='pr-12'>
                            Số lượng đã nhận:
                        </span>
                    </Typography>

                    <Controller
                        name="orderQuantity"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                onBlur={onUpdateRootData}
                                // onChange={(e) => {
                                //     setValue("orderQuantity", e.target.value);
                                //     field.onChange(e);
                                // }}
                                className='flex-1' variant="standard" size="small"
                                error={!!errors.orderQuantity}
                                helperText={errors?.orderQuantity?.message}
                                type="number"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">Tấn</InputAdornment>,
                                }} />
                        )}
                    />
                </div>
            </div>
            <div className='flex flex-row my-8 border-b-1 border-gray-200'>
                <div className='flex flex-1 flex-row items-center min-h-40'>
                    <Typography className='w-160' align='right' fontWeight="bold">
                        <span className='pr-12'>
                            Tình trạng:
                        </span>
                    </Typography>
                    {editAll ?
                        <Controller
                            name="status"
                            control={control}
                            render={({ field }) => (
                                <Box className='flex-1'>
                                    <Select {...field}
                                        onBlur={onUpdateRootData}
                                        fullWidth variant='standard'>
                                        {statusList.map((val, i) => (
                                            <MenuItem key={i} value={val.value}>
                                                {val.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Box>
                            )}
                        />
                        :
                        <Typography className='flex-1' align='left' >
                            {item.status}
                        </Typography>
                    }
                </div>
                <div className='flex flex-1 flex-row items-center min-h-40'>
                    <Typography className='w-160' align='right' fontWeight="bold">
                        <span className='pr-12'>
                            Ngày mở LC:
                        </span>
                    </Typography>
                    {editAll ?
                        <Controller
                            name="lcDate"
                            control={control}
                            render={({ field }) => (
                                <DatePicker
                                    className='flex-1'
                                    {...field}
                                    value={moment(field.value)}
                                    onChange={(value) => field.onChange({ target: { value } })}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            onBlur={onUpdateRootData}
                                            className='flex-1'
                                            variant="standard"
                                            error={!!errors.lcDate}
                                            helperText={errors?.lcDate?.message}
                                        />
                                    )}
                                />
                            )}
                        />
                        :
                        <Typography className='flex-1' align='left' >
                            {item.lcDate && moment(item.lcDate).format("DD/MM/YYYY")}
                        </Typography>
                    }
                </div>
            </div >

            <div className='flex flex-row my-8 border-b-1 border-gray-200 '>
                <div className='flex flex-1 flex-row items-center min-h-40'>
                    <Typography className='flex-1' align='right' fontWeight="bold">
                        <span className='pr-12'>
                            Ghi chú
                        </span>
                    </Typography>
                    {editAll ?
                        <Controller
                            name="note"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    onBlur={onUpdateRootData}
                                    className='flex-1' variant="standard" size="small" style={{ flex: 3 }}
                                    error={!!errors.note}
                                    helperText={errors?.note?.message}
                                    required
                                />
                            )}
                        /> :
                        <Controller
                            name="description"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    onBlur={onUpdateRootData}
                                    className='flex-1' variant="standard" size="small" style={{ flex: 3 }}
                                    error={!!errors.description}
                                    helperText={errors?.description?.message}
                                    required
                                />
                            )}
                        />
                    }
                </div>
            </div>
        </Box >
    )
}
