import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import SplashScreen from '@adg/core/SplashScreen';
import { showMessage } from 'app/store/adg/messageSlice';
import { logoutUser, setUser } from 'app/store/userSlice';
import jwtService from './jwtService';
import UserService from '@service/useService';

const AuthContext = React.createContext();

function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(undefined);
  const [waitAuthCheck, setWaitAuthCheck] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    jwtService.on('onAutoLogin', () => {
      jwtService.loginWithToken().then((user) => {
        success(user);
      }).catch((error) => {
        pass(error.message);
      });
    });

    jwtService.on('onLogin', (user) => {
      success(user, 'Đã đăng nhập');
    });

    jwtService.on('onLogout', () => {
      pass('Đã đăng xuất');

      dispatch(logoutUser());
    });

    jwtService.on('onAutoLogout', (message) => {
      pass(message);

      dispatch(logoutUser());
    });

    jwtService.on('onNoAccessToken', () => {
      pass();
    });

    jwtService.init();

  }, [dispatch]);

  const success = (user, message) => {
    if (message) {
      dispatch(showMessage(message));
    }
    dispatch(setUser(user));
    setWaitAuthCheck(false);
    setIsAuthenticated(true);
  }

  const pass = (message) => {
    if (message) {
      dispatch(showMessage(message));
    }
    setWaitAuthCheck(false);
    setIsAuthenticated(false);
  }

  return waitAuthCheck ? (
    <SplashScreen />
  ) : (
    <AuthContext.Provider value={{ isAuthenticated }}>{children}</AuthContext.Provider>
  );
}

function useAuth() {
  const context = React.useContext(AuthContext);
  return context;
}

export { AuthProvider, useAuth };
