import { combineReducers } from '@reduxjs/toolkit';
import dialog from './dialogSlice';
import message from './messageSlice';
import navbar from './navbarSlice';
import settings from './settingsSlice';
import navigation from './navigationSlice';

const adgReducers = combineReducers({
  navigation,
  navbar,
  message,
  dialog,
  settings
});

export default adgReducers;
