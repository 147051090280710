
import React, { useState } from 'react';
import moment from 'moment';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    MenuItem,
    Select,
    styled,
    Switch,
    TableCell,
    TableRow,
    Tooltip,
} from '@mui/material';
import RotateLeft from '@mui/icons-material/RotateLeft';

import SvgIcon from '@adg/core/SvgIcon';
import { closeDialog, openDialog } from 'app/store/adg/dialogSlice';

import { rows } from './TblHead';
import { selectRole } from '../store/roleSlice';
import UserService from '@service/useService';
import { selectUser } from 'app/store/userSlice';
import { MESSAGE_STATUS, showMessage, showMessageWithStatus } from 'app/store/adg/messageSlice';

const Root = styled("div")(({ theme }) => ({
    '& .bg-dark-theme': {
        backgroundColor: theme.palette.background.dark,
        color: theme.palette.text.light,
    },
    '& .bg-dark-theme:disabled': {
        backgroundColor: theme.palette.grey[400],
        color: theme.palette.text.light,
    },

    '& .MuiOutlinedInput-input': {
        backgroundColor: "#ffffff",
    }
}));

const TblRow = ({ item, forceUpdate, className = "", style = {} }) => {
    const dispatch = useDispatch();
    const { list: listRole } = useSelector(selectRole);
    const { info: currentUser } = useSelector(selectUser);

    const [role, setRole] = useState(item.roleName);
    const [isActive, setActive] = useState(item.active);

    const onChangeRole = (index) => {
        let roleName = listRole[index].name;
        if (roleName !== role) {
            UserService.changeRole(dispatch, item.userId, roleName).then(() => {
                setRole(roleName);
                dispatch(showMessageWithStatus("Đã thay đổi role cho " + item.userName, MESSAGE_STATUS.success));
            })
        }
    }

    const onActiveUser = (checked) => {
        let body = {
            username: item.userName,
            active: checked,
        }
        UserService.changeStatus(dispatch, body).then(() => {
            setActive(checked);
            dispatch(closeDialog());
            dispatch(showMessageWithStatus("Đã thay đổi trạng thái cho " + item.userName, MESSAGE_STATUS.success));
        })
    }


    const onResetUserPassword = () => {
        UserService.resetUserPassword(dispatch, item.userId).then((res) => {
            dispatch(showMessageWithStatus("Đã khôi phục mật khẩu cho " + item.userName, MESSAGE_STATUS.success));
            dispatch(closeDialog());
        })
    }

    const onConfirmActive = ({ target: { checked } }) => {
        dispatch(openDialog({
            children: (
                <React.Fragment>
                    <DialogTitle id="alert-dialog-title">Xác nhận</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Bạn muốn {checked ? "kích hoạt" : "hủy kích hoạt"} người dùng '{item.userName}' này?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => dispatch(closeDialog())} color="primary">
                            Không
                        </Button>
                        <Button onClick={() => onActiveUser(checked)} color="primary" autoFocus>
                            Có
                        </Button>
                    </DialogActions>
                </React.Fragment>
            )
        }))
    }

    const onConfirmRestorePassword = ({ target: { checked } }) => {
        dispatch(openDialog({
            children: (
                <React.Fragment>
                    <DialogTitle >Xác nhận</DialogTitle>
                    <DialogContent>
                        <DialogContentText >
                            Bạn muốn khôi phục mật khẩu người dùng '{item.userName}' này?
                        </DialogContentText>
                        <DialogContentText >
                            Mật khẩu sau khôi phục sẽ là 'adg2022'
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => dispatch(closeDialog())} color="primary">
                            Không
                        </Button>
                        <Button onClick={onResetUserPassword} color="primary" autoFocus>
                            Có
                        </Button>
                    </DialogActions>
                </React.Fragment>
            )
        }))
    }

    const cells = [
        {
            textStyle: "",
            content: item.userName
        },
        {
            textStyle: "",
            content: item.fullName
        },
        {
            textStyle: "",
            content: currentUser.username == item.userName ? role :
                <Select value={role} size='small' className='w-216' >
                    {
                        listRole.map((b, index) => (
                            <MenuItem value={b.name} key={b.name} onClick={() => onChangeRole(index)}>
                                {b.name}
                            </MenuItem>
                        ))
                    }
                </Select>
        },
        {
            textStyle: "",
            content: moment(item.userCreatedAt).format("DD/MM/YYYY")
        },
        {
            textStyle: "",
            content: currentUser.username == item.userName ? "" :
                <Box className="flex">
                    <Switch onChange={onConfirmActive} checked={isActive} />
                    <Tooltip title="Khôi phục mật khẩu">
                        <IconButton className='mx-4' color='primary' onClick={onConfirmRestorePassword} >
                            <RotateLeft className='text-gray-600 hover:text-cyan-500' />
                        </IconButton>
                    </Tooltip>
                </Box>
        },
    ];

    return (
        <TableRow
            className={"min-h-40 cursor-pointer"}
            hover
        >
            {rows.map((row, index) => row.display &&
                <TableCell key={index}
                    className={clsx("py-8 h-56 " + className, (row.className != null) && "z-10", row.className, cells[index].textStyle)}
                    align={row.align} component="td" scope="row" style={{ ...(row.moreStyle ?? {}), ...style }}
                >
                    <Root style={row.moreStyle}>
                        {cells[index].content}
                    </Root>
                </TableCell>
            )}
        </TableRow >
    );
}

export default TblRow;
