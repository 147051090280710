import axios from "axios";

const GET_USER_URL = "/user";
const GET_USER_PROFILE_URL = "/user";
const CREATE_USER_PROFILE_URL = "/user";
const CHANGE_PASS_URL = "/user/change-password";
const CHANGE_USER_STATUS_URL = "/user/change-status";
const CHANGE_USER_ROLE_URL = "/user/${1}/assign-role";
const RESET_USER_PASSWORD_URL = "/user/${1}/reset-password";
const GET_ROLES_URL = "/role";

const getUsers = async (dispatch = () => { }) => {
    return axios.get(GET_USER_URL).catch((errRes) => {
        axios.mapError(dispatch, errRes.response);
        throw errRes;
    });
}

const getUserProfile = async (dispatch = () => { }) => {
    return axios.get(GET_USER_PROFILE_URL).catch((errRes) => {
        axios.mapError(dispatch, errRes.response);
        throw errRes;
    });
}

const changePass = async (dispatch = () => { }, { oldPassword, newPassword, rePassword }) => {
    return axios.put(CHANGE_PASS_URL, { oldPassword, newPassword, rePassword }).catch((errRes) => {
        axios.mapError(dispatch, errRes.response);
        throw errRes;
    });
}

const changeRole = async (dispatch = () => { }, userId, roleName) => {
    return axios.put(CHANGE_USER_ROLE_URL.replace("${1}", userId), null,
        {
            params: { roleName }
        }
    ).catch((errRes) => {
        axios.mapError(dispatch, errRes.response);
        throw errRes;
    });
}

const changeStatus = async (dispatch = () => { }, data) => {
    return axios.put(CHANGE_USER_STATUS_URL, data).catch((errRes) => {
        axios.mapError(dispatch, errRes.response);
        throw errRes;
    });
}

const getRoles = async (dispatch = () => { }) => {
    return axios.get(GET_ROLES_URL).catch((errRes) => {
        axios.mapError(dispatch, errRes.response);
        throw errRes;
    });
}

const createUser = async (dispatch = () => { }, data) => {
    return axios.post(CREATE_USER_PROFILE_URL, data).catch((errRes) => {
        axios.mapError(dispatch, errRes.response);
        throw errRes;
    });
}

const resetUserPassword = async (dispatch = () => { }, userId) => {
    return axios.put(RESET_USER_PASSWORD_URL.replace("${1}", userId)).catch((errRes) => {
        axios.mapError(dispatch, errRes.response);
        throw errRes;
    });
}

const UserService = {
    getUsers,
    getUserProfile,
    changePass,
    changeRole,
    getRoles,
    createUser,
    resetUserPassword,
    changeStatus,
}

export default UserService;