import { combineReducers } from '@reduxjs/toolkit';
import adg from './adg';
import user from './userSlice';

const createReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    adg,
    user,
    ...asyncReducers,
  });

  // /*
	// Reset the redux store when user logged out
	//  */
  // if (action.type === 'user/userLoggedOut') {
  //   // state = undefined;
  // }

  return combinedReducer(state, action);
};

export default createReducer;
