import { createSlice } from '@reduxjs/toolkit';

const initialState = ""

const searchSlice = createSlice({
    name: 'userManagement/search',
    initialState,
    reducers: {
        setSeach: (_, action) => action.payload
    },
});

export const setSeach = searchSlice.actions.setSeach;
export const selectSearch = ({ userManagement }) => userManagement.search;

export default searchSlice.reducer;
