import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import BankService from '@service/bankService';
import StorageService from '@service/storageService';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectBank, setBank } from '../store/bankSlice';
import { setStorages } from '../store/storageSlice';

export const SelectBank = () => {
    const dispatch = useDispatch();
    const bank = useSelector(selectBank);

    const [bankList, setBankList] = useState([]);

    useEffect(() => {
        setBankList(bank.list);
    }, [bank]);

    const onBankSelected = (bankSelected, index) => {
        dispatch(setBank({
            ...bank,
            selected: bankSelected,
        }));
        localStorage.setItem("CURRENT_BANK", index + "");
        let bankInLocal = localStorage.getItem("BANK_STORAGE");
        if (bankInLocal) {
            bankInLocal = JSON.parse(bankInLocal);
        } else {
            bankInLocal = {};
        }
        let selectedList = bankSelected.allStorages.filter(val => {
            return bankInLocal[bankSelected.bankId]?.find(item => item.id === val.id) != null;
        });
        let listStorages = bankSelected.allStorages.map(val => {
            let hadDisplay = bankInLocal[bankSelected.bankId]?.find(item => item.id === val.id) != null;
            return {
                ...val,
                display: hadDisplay
            }
        });
        dispatch(setStorages({
            bankId: bankSelected.bankId,
            list: listStorages,
            selectedList
        }));
    }

    return (
        <FormControl className="w-144" variant="outlined" size='small'>
            <InputLabel id="bank-select-label" >Ngân hàng</InputLabel>
            {bankList.length > 0 &&
                <Select
                    labelId="bank-select-label"
                    id="bank-select"
                    label="Ngân hàng"
                    value={bank.selected?.bankId || ''}
                >
                    {bankList.map((b, index) => (
                        <MenuItem value={b.bankId} key={b.bankId} onClick={() => { onBankSelected(b, index) }}>
                            {b.bankName}
                        </MenuItem>
                    ))}
                </Select>
            }
        </FormControl>
    )
}