import { styled } from "@mui/material";
import { Tab, Tabs } from '@mui/material';

const StyledNav = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.background.dark,
    color: theme.palette.text.primary,
    display: 'flex',
    flexDirection: 'column'
}));

const StyledTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
))(() => ({
    padding: 4,
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
        maxWidth: 40,
        width: '100%',
    },
}));

const StyledTab = styled(({ editing, ...props }) => <Tab disableRipple {...props} />)(
    ({ theme, editing }) => ({
        textTransform: 'none',
        fontWeight: theme.typography.fontWeightRegular,
        backgroundColor: theme.palette.background.dark,
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: editing ? "#b4cbf9" : "transparent",
        fontSize: theme.typography.pxToRem(15),
        margin: 2,
        borderRadius: 4,
        color: 'rgba(255, 255, 255, 0.6)',
        '&.Mui-selected': {
            color: '#fff',
            backgroundColor: '#2a303c',
        },
        '&:hover': {
            backgroundColor: '#464f61',
        },
    }),
);

export {
    StyledNav,
    StyledTabs,
    StyledTab
}
