
import React, { } from 'react';
import moment from 'moment';
import clsx from 'clsx';
import {
    TableCell,
    TableRow,
} from '@mui/material';

import { colsReleased } from './ReleasedTableHead';

const TRANSACTION_TYPE = {
    PAID_EXPORT: "Giải chấp",
    ONLY_PAID: "Trả giải chấp",
    ONLY_EXPORT: "Nợ giải chấp",
    IMPORT_TO_STORAGE: "Hàng cảng"
}

const ReleasedTableRow = ({ item, isEmptyRow = false, className, style }) => {
    const error = item.error;

    const cells = [
        {
            textStyle: "font-bold",
            content: isEmptyRow ? "Tổng:" : item.contractId
        },
        {
            textStyle: isEmptyRow ? "font-bold" : "",
            content: isEmptyRow ? "" : item.productId
        },
        {
            textStyle: (isEmptyRow ? "font-bold" : "") + " text-center",
            content: item.transactionType ? TRANSACTION_TYPE[item.transactionType] : ""
        },
        {
            textStyle: (isEmptyRow ? "font-bold" : "") + " text-center",
            content: isEmptyRow ? "" : moment(item.createdAt).format("DD/MM/YYYY")
        },
        {
            textStyle: (isEmptyRow ? "font-bold" : "") + " text-center",
            content:
                <p>
                    {((item.releaseQuantity ?? 0) / 1000).toLocaleString("vi-VN")}/
                    <span className='font-bold'>{(item.exportQuantity / 1000).toLocaleString("vi-VN")}</span>
                </p>
        },
        {
            textStyle: (isEmptyRow ? "font-bold" : "") + " text-center",
            content:
                <p>
                    {item.releaseQuantity && item.transactionType === "PAID_EXPORT" ?
                        ((item.inventoryQuantity - item.releaseQuantity) / 1000).toLocaleString("vi-VN")
                        : item.transactionType === "ONLY_EXPORT" ? (item.inventoryQuantity/ 1000).toLocaleString("vi-VN") : 0}
                </p>// so sach
        },
        {
            textStyle: (isEmptyRow ? "font-bold" : "") + " text-center",
            content:
                <p>
                    {item.actualInventoryQuantity ?
                        ((item.actualInventoryQuantity - item.releaseQuantity) / 1000).toLocaleString("vi-VN")
                        : 0}
                </p>
        },
    ]


    return [
        <TableRow
            key="k1"
            className={"cursor-pointer"}
            hover
        >
            {colsReleased.map((row, index) => row.display &&
                <TableCell key={index}
                    className={clsx("px-8 py-8", error && "text-red", className, row.className, row.textStyle, isEmptyRow && "z-10")}
                    align={row.align} component="td" scope="row" style={{ ...row.moreStyle, ...style }}
                >
                    <div style={row.moreStyle} className={clsx(`text-${row.align}`, cells[index].textStyle)}>
                        {cells[index].content}
                    </div>
                </TableCell>
            )}
        </TableRow>,
        <TableRow key="k2">
            {item.errMessage &&
                <TableCell
                    className={"px-8 py-8 text-red"}
                    colSpan={colsReleased.filter((row) => row.display).length}
                >
                    {item.errMessage}
                </TableCell>
            }
        </TableRow>,
    ];
}

export default ReleasedTableRow;
