import axios from 'axios';

const GET_STORAGES_URL = "/international-payment/tracking/storage";
const GET_STORAGE_TRANSACTION_URL = "/international-payment/tracking/storage/${1}/transaction";
const RELEASE_STORAGE_TRANSACTION_URL = "/international-payment/tracking/storage/${1}/transaction/release";
const MULTIPLE_RELEASE_STORAGE_TRANSACTION_URL = "/international-payment/tracking/storage/transaction/multiple-release";
const GET_STORAGE_INFO_URL = "/international-payment/tracking/storage/${1}/order";
const GET_STORAGE_TRANSACTIONS_DETAIL_URL = "/international-payment/tracking/storage/${1}/order/${2}/transaction";
const EXPORT_DATA_URL = "/international-payment/tracking/storage/${1}/order/export";

const getStorages = async (dispatch = () => { }) => {
    return axios.get(GET_STORAGES_URL).catch((errRes) => {
        axios.mapError(dispatch, errRes.response);
        throw errRes;
    });
}

const getStoragetransaction = async (storageId, filter, dispatch = () => { }) => {
    return axios.post(GET_STORAGE_TRANSACTION_URL.replace("${1}", storageId), filter).catch((errRes) => {
        axios.mapError(dispatch, errRes.response);
        throw errRes;
    });
}
const getStorageInfo = async (storageId, filter, dispatch = () => { }) => {
    return axios.post(GET_STORAGE_INFO_URL.replace("${1}", storageId), filter).catch((errRes) => {
        axios.mapError(dispatch, errRes.response);
        throw errRes;
    });
}

const releaseStorageTransaction = async (storageId, data, dispatch = () => { }) => {
    return axios.post(RELEASE_STORAGE_TRANSACTION_URL.replace("${1}", storageId), data).catch((errRes) => {
        axios.mapError(dispatch, errRes.response);
        throw errRes;
    });
}

const multipleReleaseStorageTransaction = async (data, dispatch = () => { }) => {
    return axios.post(MULTIPLE_RELEASE_STORAGE_TRANSACTION_URL, data).catch((errRes) => {
        axios.mapError(dispatch, errRes.response);
        throw errRes;
    });
}

const getTransactionsDetail = async (storageId, orderId, dispatch = () => { }) => {
    return axios.get(GET_STORAGE_TRANSACTIONS_DETAIL_URL.replace("${1}", storageId).replace("${2}", orderId)).catch((errRes) => {
        axios.mapError(dispatch, errRes.response);
        throw errRes;
    });
}

const exportData = async (storageId, data, dispatch = () => { }) => {
    return axios.post(EXPORT_DATA_URL.replace("${1}", storageId), data, {
        responseType: "blob",
    }).catch((errRes) => {
        axios.mapError(dispatch, errRes.response);
        throw errRes;
    });
}

const StorageService = {
    getStorages,
    getStoragetransaction,
    getStorageInfo,
    releaseStorageTransaction,
    multipleReleaseStorageTransaction,
    getTransactionsDetail,
    exportData
}

export default StorageService;