import { SnackbarProvider } from 'notistack';
import { useSelector } from 'react-redux';
import axios from 'axios';

import Authorization from '@adg/core/Authorization';
import BrowserRouter from '@adg/core/BrowserRouter';
import AdgTheme from '@adg/core/AdgTheme';
import Layout from '@adg/core/Layout';

import { selectUser } from './store/userSlice';
import withAppProviders from './withAppProviders';
import { AuthProvider } from './auth/AuthContext';
import settingsConfig from './configs/settingsConfig';
import { selectMainTheme } from './store/adg/settingsSlice';
import { api } from '@service/base';

const App = () => {
    const user = useSelector(selectUser);
    const mainTheme = useSelector(selectMainTheme);
    api.init();
    
    return (
        <AdgTheme theme={mainTheme} >
            <AuthProvider>
                <BrowserRouter>
                    <Authorization
                        userRoles={user.roles}
                        loginRedirectUrl={settingsConfig.loginRedirectUrl}
                    >
                        <SnackbarProvider
                            maxSnack={5}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            classes={{
                                containerRoot: 'top-0 right-0 mt-52 md:mt-68 mr-8 lg:mr-80 z-99',
                            }}
                        >
                            <Layout />
                        </SnackbarProvider>
                    </Authorization>
                </BrowserRouter>
            </AuthProvider>
        </AdgTheme>
    );
};

export default withAppProviders(App)();
