import MuiDialog from '@mui/material/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import {
  closeDialog,
  selectDialogOptions,
  selectDialogState,
} from 'app/store/adg/dialogSlice';

const Dialog = (props) => {
  const dispatch = useDispatch();
  const state = useSelector(selectDialogState);
  const options = useSelector(selectDialogOptions);

  return (
    <MuiDialog
      open={state}
      onClose={(ev) => dispatch(closeDialog())}
      aria-labelledby="adg-dialog-title"
      classes={{
        paper: 'rounded-8',
      }}
      {...options}
      fullWidth={true}
    //maxWidth='xs'|'xl'|"sm"|"md"|"lg"
    />
  );
}

export default Dialog;
