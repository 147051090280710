import React, { } from 'react';
import { Dialog, DialogTitle, Button, DialogActions, styled, Typography, TextField, Select, InputAdornment, MenuItem, InputLabel, Autocomplete, Checkbox, FormControl, TextareaAutosize } from "@mui/material";
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import _ from 'lodash';



import Tabs from "@adg/core/Tabs";
import StorageService from "@service/storageService";
import { yupResolver } from '@hookform/resolvers/yup';


import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectBank } from '../store/bankSlice';


const Root = styled("div")(({ theme }) => {
    return ({
        '&': {
            minHeight: "30vh"
        },
        '& .bg-dark-theme': {
            backgroundColor: theme.palette.background.dark,
            color: theme.palette.text.light,
        },
        '& .bg-dark-theme:disabled': {
            backgroundColor: theme.palette.grey[400],
            color: theme.palette.text.light,
        }
    })
});

const list = [
    {
        label: "Giải chấp",
        content: (item, other) => {
            if (!item) return null;
            const stock = item.totalInventoryQuantity;

            const giaChap = yup.object().shape({
                priceNum: yup.string(),
                quantityNum: yup.number().typeError("Vui lòng nhập số lượng hàng hóa")
                    .positive("Số tiền cần lớn hơn 0")
                    .lessThan(stock / 1000 + 0.0001, "Số lượng cần nhỏ hơn hoặc bằng " + (stock / 1000).toLocaleString("vi-VN", { minimumFractionDigits: 0 }))
                    .required('Vui lòng nhập giá trị'),
                note: yup.string()
            });
            const giaChapDefaultValues = {
                priceNum: '',
                quantityNum: '',
                note: ''
            };
            const { control, formState, watch, setValue } = useForm({
                mode: 'onChange',
                defaultValues: giaChapDefaultValues,
                resolver: yupResolver(giaChap),
            });

            const { isValid, dirtyFields, errors } = formState;


            return (
                <div className="flex flex-col justify-center w-full mt-16">
                    <div className='min-h-256'>
                        <div className="flex flex-row mx-4 mb-16">
                            <div className='flex-1'>
                                <Typography>
                                    Còn tồn:
                                </Typography>
                                <Typography variant="h6" flex={1}>{(stock / 1000).toLocaleString("vi-VN")} (Tấn)</Typography>
                            </div>
                            <div className='flex-1'>
                                <Typography>
                                    Đơn giá:
                                </Typography>
                                <Typography variant="h6" flex={1}>{(item.unitPrice - 0).toLocaleString("vi-VN")}$</Typography>
                            </div>
                            <div className='flex-1'>
                                <Typography>
                                    Số tiền:
                                </Typography>
                                <Typography variant="h6" flex={1}>
                                    {(Math.round((
                                        watch().priceNum
                                    ) * 100) / 100).toLocaleString("vi-VN")}$
                                </Typography>
                            </div>
                        </div>
                        <FormControl fullWidth>
                            <div className='flex flex-row'>
                                <Controller
                                    name="quantityNum"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            onChange={(e) => {
                                                let { target: { value } } = e;
                                                setValue("priceNum", `${value * item.unitPrice * 1000}`);
                                                field.onChange(e)
                                            }}
                                            className="mb-24 mx-4 flex-1"
                                            label="Số lượng"
                                            type="number"
                                            error={!!errors.quantityNum}
                                            helperText={errors?.quantityNum?.message}
                                            required
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">Tấn</InputAdornment>,
                                            }}
                                        />
                                    )}
                                />
                                <Controller
                                    name="note"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            className="mb-24 mx-4" style={{ flex: 2 }}
                                            label="Ghi chú"
                                        />
                                    )}
                                />
                            </div>
                            {isValid &&
                                <div className="mb-24">
                                    <Typography>
                                        Số lượng còn lại sau giải chấp:{" "}
                                        <Typography variant='overline' fontWeight="bold">
                                            {((stock - watch().quantityNum * 1000) / 1000).toLocaleString("vi-VN")}
                                        </Typography> (Tấn)
                                    </Typography>
                                    <Typography>
                                        Giá trị còn lại sau giải chấp:{" "}
                                        <Typography variant='overline' fontWeight="bold">
                                            {(Math.floor((stock * item.unitPrice - watch().priceNum) * 100) / 100).toLocaleString("vi-VN")}$
                                            {" "}
                                        </Typography>
                                        (
                                        <Typography variant='overline' fontWeight="bold">
                                            {(Math.floor(
                                                (stock * item.unitPrice - watch().priceNum) * 1000 /
                                                (stock * item.unitPrice)
                                            ) / 10).toLocaleString("vi-VN")}%
                                        </Typography>
                                        {" "}
                                        giá trị tổng ban đầu)
                                    </Typography>
                                </div>
                            }
                        </FormControl>


                    </div>
                    <DialogActions>
                        <Button color="primary"
                            onClick={other.closeDialog}
                        >
                            Hủy
                        </Button>
                        <Button className='bg-dark-theme' color="primary" type="submit" autoFocus variant='contained'
                            disabled={_.isEmpty(dirtyFields) || !isValid}
                            onClick={() => other.onOk(
                                item.id,
                                {
                                    releaseQuantity: watch().quantityNum * 1000,
                                    targetStorageId: null,
                                    note: watch().note
                                },
                                "giải chấp"
                            )}
                        >
                            Trả hàng
                        </Button>
                    </DialogActions>
                </div>
            )
        }
    },
    {
        label: "Chuyển hàng về kho",
        content: (item, other) => {
            if (!item) return null;
            const stock = item.totalInventoryQuantity;

            const { selected: bankSelected } = useSelector(selectBank);

            const refund = yup.object().shape({
                priceNum: yup.string(),
                storage: yup.string().required(),
                quantityNum: yup.number().typeError("Vui lòng nhập số lượng hàng hóa")
                    .positive("Số lượng cần lớn hơn 0")
                    .lessThan(stock / 1000 + 0.0001, "Số lượng cần nhỏ hơn hoặc bằng " + (stock / 1000).toLocaleString("vi-VN", { minimumFractionDigits: 0 }))
                    .required('Vui lòng nhập giá trị'),
                note: yup.string()
            });

            const refundDefaultValues = {
                storage: '',
                quantityNum: '',
                note: '',
            };
            const { control, formState, watch, setValue } = useForm({
                mode: 'onChange',
                defaultValues: refundDefaultValues,
                resolver: yupResolver(refund),
            });

            const { isValid, dirtyFields, errors } = formState;

            return (
                <div className="flex flex-col justify-center w-full mt-16">
                    <div className='min-h-256'>
                        <div className="flex flex-row mx-4 mb-16">
                            <div className='flex-1'>
                                <Typography>
                                    Còn tồn:
                                </Typography>
                                <Typography variant="h6" flex={1}>{(stock / 1000).toLocaleString("vi-VN")} (Tấn)</Typography>
                            </div>
                            <div className='flex-1'>
                                <Typography>
                                    Đơn giá:
                                </Typography>
                                <Typography variant="h6" flex={1}>{(item.unitPrice - 0).toLocaleString("vi-VN")}$</Typography>
                            </div>
                            <div className='flex-1'>
                                <Typography>
                                    Số tiền:
                                </Typography>
                                <Typography variant="h6" flex={1}>
                                    {(Math.round((
                                        watch().quantityNum * 1000 * item.unitPrice
                                    ) * 100) / 100).toLocaleString("vi-VN")}$
                                </Typography>
                            </div>
                        </div>
                        <FormControl fullWidth>

                            <div className="flex flex-row">
                                <Controller
                                    name="storage"
                                    control={control}
                                    render={({ field }) => (
                                        <div className="flex-1 mb-24 mx-4">
                                            <InputLabel id="select-label" required>Kho</InputLabel>
                                            <Select
                                                {...field}
                                                className="flex flex-1"
                                                labelId="select-label"
                                                label="Kho"
                                                required
                                            >
                                                {bankSelected.allStorages?.map((s) => !s.port &&
                                                    <MenuItem value={s.id} key={s.id}>Kho {s.name}</MenuItem>
                                                )}
                                            </Select>
                                        </div>
                                    )}
                                />
                                <Controller
                                    name="quantityNum"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            className="mb-24 mx-4 flex-1"
                                            label="Số lượng"
                                            type="number"
                                            error={!!errors.quantityNum}
                                            helperText={errors?.quantityNum?.message}
                                            required
                                            onChange={(e) => {
                                                let { target: { value } } = e;
                                                setValue("priceNum", `${value * item.unitPrice * 1000}`);
                                                field.onChange(e)
                                            }}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">Tấn</InputAdornment>,
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <Controller
                                name="note"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        className="mb-24 mx-4"
                                        label="Ghi chú"
                                    />
                                )}
                            />
                        </FormControl>
                        {!!item && isValid &&

                            <div className="mb-24">
                                <Typography>
                                    Số lượng còn lại sau giải chấp:{" "}
                                    <Typography variant='overline' fontWeight="bold">
                                        {((stock - watch().quantityNum * 1000) / 1000).toLocaleString("vi-VN")}
                                    </Typography> (Tấn)
                                </Typography>
                                <Typography>
                                    Giá trị còn lại sau giải chấp:{" "}
                                    <Typography variant='overline' fontWeight="bold">
                                        {(Math.floor((
                                            stock * item.unitPrice - watch().priceNum
                                        ) * 100) / 100).toLocaleString("vi-VN")}${" "}
                                    </Typography>
                                    (
                                    <Typography variant='overline' fontWeight="bold">
                                        {(Math.floor((
                                            (stock * item.unitPrice - watch().priceNum) * 100 /
                                            (stock * item.unitPrice)
                                        ) * 10) / 10).toLocaleString("vi-VN")}%
                                    </Typography>
                                    {" "}giá trị tổng ban đầu)
                                </Typography>
                            </div>
                        }
                    </div>
                    <DialogActions>
                        <Button color="primary"
                            onClick={other.closeDialog}
                        >
                            Hủy
                        </Button>
                        <Button className='bg-dark-theme' color="primary" type="submit" autoFocus variant='contained'
                            disabled={_.isEmpty(dirtyFields) || !isValid}
                            onClick={() => other.onOk(
                                item.id,
                                {
                                    releaseQuantity: watch().quantityNum * 1000,
                                    targetStorageId: watch().storage,
                                    note: watch().note
                                },
                                "trả hàng"
                            )}
                        >
                            Trả hàng
                        </Button>
                    </DialogActions>
                </div>
            )
        }
    }
];

export const PaymentDialog = ({ item, closeDialog = () => { }, onOk = () => { } }) => (
    <Dialog onClose={closeDialog} open={item != null} maxWidth="md" fullWidth={true}
        classes={{ paper: 'rounded-8', }}>
        <Root>
            <DialogTitle className="bg-dark-theme">
                <div className='flex flex-row justify-center'>
                    <p className='flex-1'>Trả hàng</p>
                    {item &&
                        <p>Số KU: {item.contractId} - {item.productId}</p>
                    }
                </div>
            </DialogTitle>
            <div className='px-24'>
                {item && <Tabs list={list} item={item} closeDialog={closeDialog} onOk={onOk} />}
            </div>
        </Root>
    </Dialog >
);
