import React from 'react';
import { TableCell, TableHead, TableRow, lighten, Tooltip, TableSortLabel } from '@mui/material';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { selectStorages } from '../../store/storageSlice';
import { selectColFilter } from '../../store/colFilterSlice';
import { selectRowFilter } from '../../store/rowFilterSlice';

export const colsOther = [
	{
		id: 'orderId',
		display: true,
		align: 'center',
		disablePadding: true,
		label: '',
		sort: false,
		className: "",
		requireFilter: true,
		moreStyle: { width: 1 },
		isConditionCol: false,
		valueType: 'text'
	},
	{
		id: 'contractId',
		display: true,
		align: 'left',
		disablePadding: false,
		label: 'Số KU',
		sort: true,
		className: "",
		requireFilter: false,
		moreStyle: { width: 160 },
		isConditionCol: true,
		valueType: 'text'
	},
	{
		id: 'productId',
		display: true,
		align: 'center',
		disablePadding: false,
		label: 'Mã hàng',
		sort: true,
		className: "",
		requireFilter: false,
		moreStyle: { width: 200 },
		isConditionCol: true,
		valueType: 'text'
	},
	{
		id: 'createdAt',
		display: true,
		align: 'center',
		disablePadding: false,
		label: 'Ngày tạo',
		sort: true,
		className: "",
		requireFilter: false,
		moreStyle: { width: 100 },
		isConditionCol: true,
		valueType: 'datetime'
	},
	{
		id: 'importQuantity',
		display: true,
		align: 'center',
		disablePadding: false,
		label: 'SL nhập',
		sublabel: '(Tấn)',
		sort: true,
		requireFilter: false,
		moreStyle: { width: 90 },
		isConditionCol: true,
		valueType: 'number',
		sum: true,
	},
	{
		id: 'releaseQuantity',
		display: true,
		align: 'center',
		disablePadding: false,
		label: 'SL giải chấp',
		sublabel: '(Tấn)',
		sort: true,
		requireFilter: false,
		moreStyle: { width: 110 },
		isConditionCol: true,
		valueType: 'number',
		sum: true,
	},
	{
		id: 'inventoryQuantity',
		display: true,
		align: 'center',
		disablePadding: false,
		label: 'SL tồn',
		sublabel: '(Tấn)',
		sort: true,
		requireFilter: false,
		moreStyle: { width: 70 },
		isConditionCol: true,
		valueType: 'number',
		sum: true,
	},
	{
		id: 'actualReleaseQuantity',
		display: true,
		align: 'center',
		disablePadding: false,
		label: 'Xuất thực tế',
		sublabel: '(Tấn)',
		sort: true,
		requireFilter: false,
		moreStyle: { width: 120 },
		isConditionCol: true,
		valueType: 'number',
		sum: true,
	},
	{
		id: 'debitCredit',
		display: true,
		align: 'center',
		disablePadding: false,
		label: 'Debit/Credit',
		sublabel: '(Tấn)',
		sort: true,
		requireFilter: false,
		moreStyle: { width: 98 },
		isConditionCol: true,
		valueType: 'number',
		sum: true,
	},
	{
		id: 'actualInventoryQuantity',
		display: true,
		align: 'center',
		disablePadding: false,
		label: 'SL tồn thực tế',
		sublabel: '(Tấn)',
		sort: true,
		requireFilter: false,
		moreStyle: { width: 130 },
		isConditionCol: true,
		valueType: 'number',
		sum: true,
	},
	{
		id: 'updatedBy',
		display: true,
		align: 'center',
		disablePadding: false,
		label: 'Người cập nhật',
		sublabel: '',
		sort: true,
		requireFilter: false,
		moreStyle: { width: 140 },
		isConditionCol: true,
		valueType: 'text'
	},
	{
		id: 'note',
		display: true,
		align: 'left',
		disablePadding: false,
		label: 'Ghi chú',
		sort: true,
		className: "",
		requireFilter: false,
		moreStyle: { width: 160 },
		isConditionCol: true,
		valueType: 'text'
	},
];

const OtherTableHead = ({ storage, order, onRequestSort }) => {
	const filterCol = useSelector(selectColFilter(storage?.id));
	const filterRow = useSelector(selectRowFilter(storage?.id));

	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow className={clsx("sticky h-40 z-20", (filterRow ?? []).length > 0 ? "top-48" : "top-0")}>
				{colsOther.map((row, index) => {
					if (!row.display) return null;
					if (filterCol != null && Object.keys(filterCol ?? {}).length > 0) {
						if (filterCol[row.id] !== true && row.requireFilter == false) return null;
					}
					return (
						<TableCell
							sx={{
								backgroundColor: (theme) => lighten(theme.palette.background.default, 0.4),
							}}
							className={clsx("py-4 px-0 m-0 font-semibold", row.className != null ? "z-50" : "", row.className)}
							key={index}
							style={row.moreStyle}
							sortDirection={order.id === row.id ? order.direction : false}
							scope='row'
						>
							<div style={{ ...row.moreStyle, textAlign: row.align }}> {row.sort ?
								<Tooltip
									title="Sắp xếp"
									placement={row.align === 'right' ? 'bottom-end' : 'bottom-start'}
									enterDelay={300}
								>
									<TableSortLabel
										active={order.id === row.id}
										direction={order.direction}
										onClick={createSortHandler(row.id)}
										hideSortIcon={true}
									>
										{row.label.split("").map((c, i) => c === "\n" ? <br key={i} /> : c)}
										{!!row.sublabel && <><br />{row.sublabel}</>}
									</TableSortLabel>
								</Tooltip>
								:
								row.label.split("").map((c, i) => c === "\n" ? <br key={i} /> : c)
							}
							</div>
						</TableCell>
					)
				})}
			</TableRow>
		</TableHead>
	);
}

export default OtherTableHead;
