import Divider from '@mui/material/Divider';
import PropTypes from 'prop-types';
import { memo } from 'react';
import _ from '@lodash';
import GlobalStyles from '@mui/material/GlobalStyles';
import NavLayout from './components/NavLayout';
import NavCollapse from './components/containers/NavCollapseContainer';
import NavGroup from './components/containers/NavGroupContainer';
import NavItem from './components/containers/NavItemContainer';
import NavLink from './components/containers/NavLinkContainer';
import { registerComponent } from './NavItem';

const inputGlobalStyles = (
  <GlobalStyles
    styles={(theme) => ({
      '.popper-navigation-list': {
        '& .adg-list-item': {
          padding: '8px 12px 8px 12px',
          height: 40,
          minHeight: 40,
          '& .adg-list-item-text': {
            padding: '0 0 0 8px',
          },
        },
        '&.dense': {
          '& .adg-list-item': {
            minHeight: 32,
            height: 32,
            '& .adg-list-item-text': {
              padding: '0 0 0 8px',
            },
          },
        },
      },
    })}
  />
);

/*
Register Adg Navigation Components
 */
registerComponent('group', NavGroup);
registerComponent('collapse', NavCollapse);
registerComponent('item', NavItem);
registerComponent('link', NavLink);
registerComponent('divider', () => <Divider className="my-16" />);

function Navigation(props) {
  const options = _.pick(props, [
    'navigation',
    'active',
    'className',
    'onItemClick',
    'firstLevel',
    'selectedId',
  ]);
  if (props.navigation.length > 0) {
    return (
      <>
        {inputGlobalStyles}
        <NavLayout {...options} />
      </>
    );
  }
  return null;
}

Navigation.propTypes = {
  navigation: PropTypes.array.isRequired,
};

export default memo(Navigation);
