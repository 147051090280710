import { combineReducers } from '@reduxjs/toolkit';

import colFilter from './colFilterSlice';
import rowFilter from './rowFilterSlice';
import bank from './bankSlice';
import storage from './storageSlice';

const reducer = combineReducers({
    colFilter,
    rowFilter,
    bank,
    storage,
});

export default reducer;
