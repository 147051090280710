import React, { } from 'react';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import { useDispatch } from 'react-redux';
import { styled } from '@mui/material';
import SvgIcon from '@adg/core/SvgIcon';

const TypeBadge = styled(Box)(({ theme, ...props }) => ({
    backgroundColor: "#15afcd"
}));

const ItemIcon = ({ type }) => {

    const getType = () => {
        switch (type) {
            case "application/x-zip-compressed":
                return "ZIP";
            case "application/zip":
                return "ZIP";
            default:
                return "UNKNOW";
        }
    }

    return (
        <div className="relative">
            <SvgIcon className="" size={64} color="disabled">
                heroicons-outline:document
            </SvgIcon>
            <TypeBadge
                className="absolute left-0 bottom-0 px-6 rounded text-12 font-semibold leading-20 text-white"
            >
                {getType()}
            </TypeBadge>
        </div>
    );
}


const FileItem = ({ isAddNew = false, item, onRemove, onAdd }) => {
    const dispatch = useDispatch();

    return (
        <Box
            sx={{ backgroundColor: 'background.paper' }}
            className={"flex flex-col relative w-full sm:w-160 h-160 m-8 p-16 shadow rounded-16 cursor-pointer " +
                (!isAddNew ? (
                    "hover:before:absolute hover:before:w-full hover:before:h-full hover:before:bg-gray-300 hover:before:top-0 hover:before:left-0 hover:before:rounded-16 hover:before:z-10 " +
                    "hover:before:flex hover:before:items-center hover:before:justify-center hover:before:bg-opacity-50 " +
                    "hover:before:text-black hover:before:text-60 hover:before:font-bold hover:before:align-middle hover:before:content-['✕'] "
                ) : "")
            }
            onClick={() => isAddNew ? onAdd() : onRemove()}
        >
            {isAddNew ? <>
                <div className="flex flex-auto w-full items-center justify-center mt-4">
                    <div className="relative">
                        <SvgIcon className="" size={64} color="disabled">
                            heroicons-outline:plus-sm
                        </SvgIcon>
                    </div>
                </div>
                <div className="flex shrink flex-col justify-center text-center">
                    <Typography className="text-12 font-medium">Chọn File</Typography>
                </div>
            </>
                : (<>
                    <div className="flex flex-auto w-full items-center justify-center mt-4">
                        <ItemIcon className="" type={item.type} />
                    </div>
                    <div className="flex shrink flex-col justify-center text-center">
                        <Typography className="truncate text-12 font-medium">{item.name}</Typography>
                        {item.contents && (
                            <Typography className="truncate text-12 font-medium" color="text.secondary">
                                {item.contents}
                            </Typography>
                        )}
                    </div>
                </>)}
        </Box >
    );
}

export default FileItem;
