import React, { createRef, useEffect, useState } from 'react';
import { Dialog, DialogTitle, Button, DialogActions, styled, Typography, Box } from "@mui/material";
import _ from 'lodash';

import { StyledNav, StyledTab, StyledTabs } from '../components/styles/customTab';
import { FormImport } from '../components/FormImport';

const Root = styled("div")(({ theme }) => ({
    '&': {
        // minHeight: "30vh"
    },
    '& .bg-dark-theme': {
        backgroundColor: theme.palette.background.dark,
        color: theme.palette.text.light,
    },
    '& .bg-dark-theme:disabled': {
        backgroundColor: theme.palette.grey[400],
        color: theme.palette.text.light,
    }
}));

export const ImportManualDialog = ({
    open, bankSelected,
    closeDialog = () => { }, setUploadManual = () => { },
    onUploadDataManual = () => { }
}) => {

    const [indexDataArr, setIndexDataArr] = useState(0);
    const [dataArr, setDataArr] = useState([{
        contractId: "",
        note: "",
        productId: "",
        providerCode: "",
        orderQuantity: "",
        receivedQuantity: "0",
        unitPrice: "",
        lcDate: new Date(),
        status: "PROCESSING",
        error: null
    }]);
    const [formRefs, setFormRefs] = useState([]);
    const [isEditing, hasEditing] = useState(true);

    useEffect(() => {
        setFormRefs((formRefs) =>
            Array(dataArr.length).fill().map((_, i) => formRefs[i] || createRef()),
        );
    }, [dataArr.length]);

    useEffect(() => {
        if (open) {
            hasEditing(true);
            setDataArr([{
                contractId: "",
                note: "",
                lcDate: new Date(),
                productId: "",
                providerCode: "",
                orderQuantity: "",
                receivedQuantity: "",
                unitPrice: "",
                status: "PROCESSING",
            }]);
            setIndexDataArr(0);
        }
    }, [open]);

    const propsTab = (index) => {
        return {
            id: `new-vertical-tab-${index}`,
            'aria-controls': `new-vertical-tabpanel-${index}`,
        };
    }

    const TabPanel = (props) => {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`new-vertical-tabpanel-${index}`}
                aria-labelledby={`new-vertical-tab-${index}`}
                {...other}
            >
                <Box sx={{ px: 3, display: value === index ? "block" : "none" }}>
                    {children}
                </Box>
            </div>
        );
    }

    const handleChange = (_, newValue) => {
        if (!isEditing) {
            setIndexDataArr(newValue);
        }
    };

    const addMoreData = () => {
        dataArr.push({
            contractId: "",
            note: "",
            productId: "",
            providerCode: "",
            orderQuantity: "",
            receivedQuantity: "0",
            unitPrice: "",
            lcDate: new Date(),
            status: "PROCESSING",
            error: null
        })
        hasEditing(true);
        setIndexDataArr(dataArr.length - 1);
        setDataArr([...newData]);
    }

    const onRemoveData = () => {
        if (indexDataArr == dataArr.length - 1) {
            setIndexDataArr(indexDataArr - 1);
        }

        dataArr.splice(indexDataArr, 1);
        setDataArr([...dataArr]);
        hasEditing(false);
    }

    const onUpdateInfo = (data) => {
        hasEditing(false);
        dataArr[indexDataArr] = data;
        setDataArr([...dataArr]);
    }

    return (
        <Dialog onClose={closeDialog} open={open} maxWidth="md" fullWidth={true}
            classes={{ paper: 'rounded-8', }}>
            <Root>
                <DialogTitle className="bg-dark-theme">
                    <div className='flex flex-row justify-center'>
                        <p className='flex-1'>Nhập dữ liệu</p>
                        <Box>
                            <Button className='px-40' variant='outlined' color='info' size="small"
                                onClick={() => setUploadManual()}
                            >
                                Nhập dữ liệu từ file
                            </Button>

                        </Box>
                    </div>
                </DialogTitle>

                <div className="flex flex-col justify-center w-full">
                    <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }} className="w-full"
                    >
                        <StyledNav>
                            <StyledTabs
                                orientation="vertical"
                                variant="scrollable"
                                indicatorColor="secondary"
                                value={indexDataArr}
                                onChange={handleChange}
                                sx={{ borderRight: 1, borderColor: 'divider', flex: 1 }}
                            >
                                {dataArr.map((val, index) => (
                                    <StyledTab {...propsTab(index)} sx={{ whiteSpace: "nowrap" }} key={index} editing={isEditing && indexDataArr == index}
                                        label={isEditing && indexDataArr == index ?
                                            <Box sx={{ width: 150 }} textAlign="center">
                                                <span className='text-white font-bold'>
                                                    Đang chỉnh sửa...
                                                </span>
                                            </Box>
                                            :
                                            <Box sx={{ width: 150 }} textAlign="center">
                                                <span className='text-gray-500'>
                                                    {val.contractId == "" ? "Chưa có số KU" : val.contractId}
                                                </span>
                                                <br />
                                                <span className='text-white'>
                                                    {val.productId == "" ? "Chưa có mã s/p" : val.productId}
                                                </span>
                                            </Box>
                                        }
                                    />
                                ))}
                            </StyledTabs>
                            <div className='p-16'>
                                <Typography color="#FFF">
                                    Ngân hàng
                                </Typography>
                                <Typography fontWeight="bold" color="#FFF">
                                    {bankSelected?.bankName}
                                </Typography>
                            </div>
                        </StyledNav>
                        <div className='w-full'>
                            <Box minHeight={150}>
                                {dataArr.map((val, index) => {
                                    const fieldName = `data[${index}]`;
                                    return (
                                        <TabPanel key={fieldName} value={indexDataArr} index={index}>
                                            <fieldset name={fieldName}>
                                                <FormImport ref={formRefs[index]}
                                                    data={val}
                                                    onUpdateData={onUpdateInfo}
                                                    isEditing={isEditing}
                                                />
                                            </fieldset>
                                        </TabPanel>
                                    )
                                })}
                            </Box>
                            <div className='flex items-center'>

                                <div className='pl-8 flex-1 flex items-center'>
                                    <Button variant='outlined' color="error"
                                        onClick={onRemoveData}
                                    >
                                        Xóa
                                    </Button>

                                    {isEditing ?
                                        <Button variant='outlined' color="info"
                                            sx={{ ml: 1 }}
                                            onClick={() => { formRefs[indexDataArr].current.click() }}
                                        >
                                            Hoàn tất
                                        </Button>
                                        :
                                        <Button variant='outlined' color="info"
                                            sx={{ ml: 1 }}
                                            onClick={() => hasEditing(true)}
                                        >
                                            Chỉnh sửa
                                        </Button>
                                    }

                                </div>
                                <DialogActions>
                                    <Button color="primary"
                                        onClick={closeDialog}
                                    >
                                        Hủy
                                    </Button>
                                    <Button className='bg-dark-theme' variant='contained' color="primary" disabled={isEditing}
                                        onClick={addMoreData}
                                    >
                                        + Thêm dữ liệu
                                    </Button>
                                    <Button className='bg-dark-theme' color="primary" variant='contained' autoFocus disabled={isEditing}
                                        onClick={() => onUploadDataManual(dataArr)}
                                    >
                                        Nhập
                                    </Button>
                                </DialogActions>
                            </div>
                        </div>
                    </Box>
                </div>
            </Root>
        </Dialog >
    );
};
