import { Box, InputAdornment, MenuItem, Select, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import _ from 'lodash';
import moment from 'moment';
import React, { forwardRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const statusList = [
    { name: "Đã hoàn thành", value: "COMPLETED" },
    { name: "Đang thực hiện", value: "PROCESSING" },
]

export const FormImport = forwardRef(({ data, onUpdateData, isEditing }, ref) => {

    const confirmValidate = yup.object().shape({
        contractId: yup.string(),
        productId: yup.string().required("Vui lòng nhập mã chính xác"),
        note: yup.string(),
        description: yup.string(),
        lcDate: yup.string(),
        providerCode: yup.string().required("Vui lòng nhập mã nhà cung cấp"),
        orderQuantity: yup.number().typeError("Vui lòng nhập số lượng")
            .positive("Số lượng cần lớn hơn 0")
            .required('Vui lòng nhập giá trị'),
        receivedQuantity: yup.number().typeError("Vui lòng nhập số lượng")
            .positive("Số tiền cần lớn hơn 0")
            .required('Vui lòng nhập giá trị'),
        unitPrice: yup.number().typeError("Vui lòng nhập đơn giá")
            .positive("Số tiền cần lớn hơn 0")
            .required('Vui lòng nhập giá trị'),
        status: yup.string(),
        lcDate: yup.date().typeError("Thời gian không phù hợp").required("Vui lòng nhập ngày LC"),
    });

    const { control, formState, setValue, handleSubmit } = useForm({
        mode: 'onChange',
        defaultValues: data,
        resolver: yupResolver(confirmValidate),
    });

    const { errors } = formState;

    return (
        <Box component="form" onSubmit={handleSubmit(onUpdateData)}>
            <button ref={ref} type='sumit' className='hidden' />
            <div className='flex flex-row my-8 border-b-1 border-gray-200'>
                <div className='flex flex-1 flex-row items-center min-h-40'>
                    <Typography className='flex-1' align='right' fontWeight="bold">
                        <span className='pr-24'>
                            Số KU:
                        </span>
                    </Typography>
                    <Controller
                        name="contractId"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                id="contractId"
                                className='flex-1' variant="standard" size="small"
                                error={!!errors?.contractId}
                                helperText={errors?.contractId?.message}
                                disabled={!isEditing}
                            />
                        )}
                    />
                </div>
                <div className='flex flex-1 flex-row items-center min-h-40'>
                    <Typography className='flex-1' align='right' fontWeight="bold">
                        <span className='pr-24'>
                            Mã hàng:
                        </span>
                    </Typography>
                    <Controller
                        name="productId"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                id="productId"
                                className='flex-1' variant="standard" size="small"
                                error={!!errors?.productId}
                                helperText={errors?.productId?.message}
                                disabled={!isEditing}
                            />
                        )}
                    />
                </div>

            </div>
            <div className='flex flex-row my-8 border-b-1 border-gray-200'>
                <div className='flex flex-1 flex-row items-center min-h-40'>
                    <Typography className='flex-1' align='right' fontWeight="bold">
                        <span className='pr-24'>
                            Mã nhà cung cấp:
                        </span>
                    </Typography>


                    <Controller
                        name="providerCode"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                id="providerCode"
                                className='flex-1' variant="standard" size="small"
                                error={!!errors?.providerCode}
                                helperText={errors?.providerCode?.message}
                                disabled={!isEditing}
                            />
                        )}
                    />
                </div>
                <div className='flex flex-1 flex-row items-center min-h-40'>
                    <Typography className='flex-1' align='right' fontWeight="bold">
                        <span className='pr-24'>
                            Đơn giá:
                        </span>
                    </Typography>

                    <Controller
                        name="unitPrice"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                className='flex-1' variant="standard" size="small"
                                type="number"
                                error={!!errors?.unitPrice}
                                helperText={errors?.unitPrice?.message}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">$/Tấn</InputAdornment>,
                                }}
                                disabled={!isEditing}
                            />
                        )}
                    />
                </div>

            </div>
            <div className='flex flex-row my-8 border-b-1 border-gray-200'>
                <div className='flex flex-1 flex-row items-center min-h-40'>
                    <Typography className='flex-1' align='right' fontWeight="bold">
                        <span className='pr-24'>
                            Số lượng đặt hàng:
                        </span>
                    </Typography>

                    <Controller
                        name="orderQuantity"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                onChange={(e) => {
                                    setValue("receivedQuantity", e.target.value);
                                    field.onChange(e);
                                }}
                                className='flex-1' variant="standard" size="small"
                                type="number"
                                error={!!errors?.orderQuantity}
                                helperText={errors?.orderQuantity?.message}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">Tấn</InputAdornment>,
                                }}
                                disabled={!isEditing}
                            />
                        )}
                    />
                </div>
                <div className='flex flex-1 flex-row items-center min-h-40'>
                    <Typography className='flex-1' align='right' fontWeight="bold">
                        <span className='pr-24'>
                            Số lượng đã nhận:
                        </span>
                    </Typography>

                    <Controller
                        name="receivedQuantity"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                onChange={(e) => {
                                    setValue("orderQuantity", e.target.value);
                                    field.onChange(e);
                                }}
                                className='flex-1' variant="standard" size="small"
                                type="number"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">Tấn</InputAdornment>,
                                }}
                                error={!!errors?.receivedQuantity}
                                helperText={errors?.receivedQuantity?.message}
                                disabled={!isEditing}
                            />
                        )}
                    />
                </div>
            </div>
            <div className='flex flex-row my-8 border-b-1 border-gray-200'>
                <div className='flex flex-1 flex-row items-center min-h-40'>
                    <Typography className='flex-1' align='right' fontWeight="bold">
                        <span className='pr-24'>
                            Tình trạng:
                        </span>
                    </Typography>
                    <Controller
                        name="status"
                        control={control}
                        render={({ field }) => (
                            <Box className='flex-1'>
                                <Select {...field}
                                    disabled={!isEditing}
                                    fullWidth variant='standard'>
                                    {statusList.map((val, i) => (
                                        <MenuItem key={i} value={val.value}>
                                            {val.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Box>
                        )}
                    />
                </div>
                <div className='flex flex-1 flex-row items-center min-h-40'>
                    <Typography className='flex-1' align='right' fontWeight="bold">
                        <span className='pr-24'>
                            Ngày mở LC:
                        </span>
                    </Typography>
                    <Controller
                        name="lcDate"
                        control={control}
                        render={({ field }) => (
                            <DatePicker
                                className='flex-1'
                                {...field}
                                value={moment(field.value)}
                                onChange={(value) => field.onChange({ target: { value } })}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className='flex-1'
                                        variant="standard"
                                        id="lcDate"
                                        error={!!errors?.lcDate}
                                        helperText={errors?.lcDate?.message}
                                        disabled={!isEditing}
                                    />
                                )}
                            />
                        )}
                    />
                </div>
            </div >

            <div className='flex flex-row my-8 border-b-1 border-gray-200 '>
                <div className='flex flex-1 flex-row items-center min-h-40'>
                    <Typography className='flex-1' align='right' fontWeight="bold">
                        <span className='pr-24'>
                            Ghi chú
                        </span>
                    </Typography>
                    <Controller
                        name="note"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                className='flex-1' variant="standard" size="small" style={{ flex: 3 }}
                                disabled={!isEditing}
                            />
                        )}
                    />
                </div>
            </div>
        </Box >
    )
})
