const LayoutConfig = {
    defaults: {
      navbar: {
        display: false,
      },
      toolbar: {
        display: false,
      },
      footer: {
        display: false,
      },
      leftSidePanel: {
        display: false,
      },
    }
  };
  
  export default LayoutConfig;
  