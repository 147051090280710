import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import React, { } from 'react';

import SvgIcon from '@adg/core/SvgIcon';

export const FilterInCell = ({ className = "", name = "", onClick = () => { }, children }) => {
    return (
        <Box className="flex items-center w-full" >
            <Tooltip title={`Lọc ${name} này`} placement="top">
                <IconButton
                    className='p-4 text-dark hover:text-white hover:bg-gray-800'
                    onClick={(e) => { e.stopPropagation(); onClick(); }}
                >
                    <SvgIcon size={18}>
                        heroicons-outline:plus-circle
                    </SvgIcon>
                </IconButton>
            </Tooltip>
            <div className={"flex-1 " + className}>
                {children}
            </div>
        </Box >
    )
}
