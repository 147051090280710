import { createSlice } from '@reduxjs/toolkit';

let tmpBank = null;

const storageSlice = createSlice({
    name: 'homeDashboard/storage',
    initialState: {
        index: 0,
        value: null,
        selectedList: [],
        list: []
    },
    reducers: {
        setStorages: (state, action) => {
            tmpBank = action.payload.bankId;
            return {
                index: 0,
                value: {
                    id: "port-" + action.payload.bankId
                },
                list: action.payload.list,
                selectedList: action.payload.selectedList ?? []
            }
        },
        setStorageIndex: (state, action) => {
            state.index = action.payload;
            if (action.payload < 2) {
                state.value = { id: (action.payload == 0 ? "port-" : "history-") + tmpBank }
            } else {
                state.value = state.selectedList[action.payload - 2];
            }
        },
        setCurrentStorage: (state, action) => ({
            list: action.payload.list,
            index: action.payload.index,
            value: action.payload.value,
            selectedList: action.payload.selectedList
        }),
    },
});

export const setStorageIndex = storageSlice.actions.setStorageIndex;
export const setStorages = storageSlice.actions.setStorages;
export const setCurrentStorage = storageSlice.actions.setCurrentStorage;

export const selectStorages = ({ homeDashboard }) => homeDashboard.storage;

export default storageSlice.reducer;
