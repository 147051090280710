
import React, { useState } from 'react';
import { TableBody } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import OrderService from '@service/orderService';
import Utils from '@adg/utils';

import TblRow from './TblRow';
import { selectSearch } from '../store/searchSlice';
import { selectRole } from '../store/roleSlice';

const TblBody = ({ data = [], forceUpdate }) => {
    const search = useSelector(selectSearch);
    const { selected: roleSelected } = useSelector(selectRole);

    if (data.length === 0) return null;

    return (
        <TableBody>
            {data.map((val, index) => (
                search === "" ||
                Utils.handleize(val.userName ?? "").toString().includes(Utils.handleize(search).toString()) ||
                Utils.handleize(val.fullName ?? "").toString().includes(Utils.handleize(search).toString())
            ) && (
                    roleSelected == null || roleSelected.name == val.roleName
                ) &&
                <TblRow key={index}
                    item={val}
                    forceUpdate={forceUpdate} />
            )}
        </TableBody>
    );
}

export default TblBody;
