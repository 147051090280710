import UserManangementPage from '.';

const UserManangementConfig = {
  settings: {
    layout: {
      config: {
        title: "QUẢN LÝ USER",
        navbar: {
          display: true,
        },
        toolbar: {
          display: true,
        },
        footer: {
          display: true,
        },
        leftSidePanel: {
          display: true,
        },
      },
    },
  },
  roles: ["ROLE_SYSTEM_ADMIN"],
  routes: [
    {
      path: 'app/user',
      element: <UserManangementPage />,
    },
  ],
};

export default UserManangementConfig;
