import React from 'react';
import { TableCell, TableHead, TableRow, lighten } from '@mui/material';
import clsx from 'clsx';

export const colsReleased = [
	{
		id: 'contractId',
		align: 'center',
		label: 'Số KU',
		display: true,
		disablePadding: true,
		className: "",
		moreStyle: { width: 160 },
	},
	{
		id: 'productId',
		align: 'center',
		label: 'Mã hàng',
		display: true,
		disablePadding: true,
		className: "",
		moreStyle: { width: 160 },
	},
	{
		id: 'productId',
		align: 'center',
		label: 'Loại giao dịch',
		display: true,
		disablePadding: true,
		className: "",
		moreStyle: { width: 120 },
	},
	{
		id: 'createdAt',
		align: 'center',
		label: 'Ngày',
		display: true,
		disablePadding: true,
		className: "",
		moreStyle: { width: 95 },
	},
	{
		id: 'releaseQuantity',
		align: 'center',
		label: 'Xuất kho',
		sublabel: "(Tấn)",
		display: true,
		disablePadding: true,
		className: "",
		moreStyle: { width: 130 },
	},
	{
		id: 'inventoryQuantity',
		align: 'center',
		label: "Tồn sau xuất",
		sublabel: "(Sổ sách - Tấn)",
		display: true,
		disablePadding: true,
		className: "",
		moreStyle: { width: 130 },
	},
	{
		id: 'inventoryQuantity',
		align: 'center',
		label: "Tồn sau xuất",
		sublabel: "(Thực tế - Tấn)",
		display: true,
		disablePadding: true,
		className: "",
		moreStyle: { width: 130 },
	},
];

const ReleasedTableHead = () => {

	return (
		<TableHead>
			<TableRow className={clsx("h-40")}>
				{colsReleased.map((row, index) => {
					if (!row.display) return null;
					return (
						<TableCell
							key={index}
							sx={{
								backgroundColor: (theme) => lighten(theme.palette.background.default, 0.4),
							}}
							className={clsx("py-0 px-8 m-0 font-semibold", row.className)}
							style={row.moreStyle}
							align={row.align}
							scope='row'
						>
							<div style={{ ...row.moreStyle, textAlign: row.align }}>
								{row.label.split("").map((c, i) => c === "\n" ? <br key={i} /> : c)}
								{!!row.sublabel && <><br />{row.sublabel}</>}
							</div>
						</TableCell>
					)
				})}
			</TableRow>
		</TableHead>
	);
}

export default ReleasedTableHead;
