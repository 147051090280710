
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Paper,
    styled,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import OrderService from '@service/orderService';

import SvgIcon from '@adg/core/SvgIcon';
import { closeDialog, openDialog } from 'app/store/adg/dialogSlice';
import { MESSAGE_STATUS, showMessageWithStatus } from 'app/store/adg/messageSlice';

import { selectStorages } from '../../store/storageSlice';
import { selectColFilter } from '../../store/colFilterSlice';
import { colsPort } from './PortTableHead';
import { ShowEmptyValue } from '../../DashboardTable';
import { selectRowFilter, setRowFilter } from '../../store/rowFilterSlice';
import { selectBank } from '../../store/bankSlice';
import { FilterInCell } from '../../components/FilterInCell';

const Root = styled("div")(({ theme }) => ({
    '& .MuiOutlinedInput-input': {
        backgroundColor: "#ffffff",
    }
}));

const StyledTableHeader = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const PortTableRow = ({ item, onOpenPayment, forceUpdate, isEmptyRow = false, className = "", style = {} }) => {
    const dispatch = useDispatch();
    const storageSelected = useSelector(selectStorages);
    const { selected: bankSelected } = useSelector(selectBank);
    const filterCol = useSelector(selectColFilter("port-" + bankSelected?.bankId));
    const filterRow = useSelector(selectRowFilter("port-" + bankSelected?.bankId));


    const [isShowSub, hadShowSub] = useState(false);
    const [confitmChange, setConfirmChange] = useState(null);
    const [data, setData] = useState({ ...item });
    const [historyList, setHistoryList] = useState([]);
    const [isLoading, hadLoading] = useState(false);

    let tiGia = localStorage.getItem("TI_GIA");

    // feed data for table in collapse
    useEffect(() => {
        if (isShowSub) {
            OrderService.getTransactionOfOrder(
                data.id,
                dispatch
            ).then(({ data: res }) => {
                setHistoryList(res.data);
            }).finally(() => {
                hadLoading();
            });
        }
    }, [isShowSub]);

    const onAddRowFilter = (columnKey, type, val) => {
        if (type === "datetime") {
            val = moment(val).format("YYYY-MM-DD");
        }
        let data = {
            columnId: columnKey,
            columnType: type,
            condition: {
                id: type === "text" ? "bằng" : "=",
                numVal: 1,
                operator: "equal",
                vals: [
                    val
                ]
            }
        };
        let rootFilter = filterRow ?? [];
        let newFilter = [...rootFilter, data];
        dispatch(setRowFilter({
            id: "port-" + bankSelected.bankId,
            list: newFilter,
        }));
    }


    const defineFilter = filterCol ? filterCol : {};

    const onUpdateNotePress = (e) => {
        if (e?.keyCode === 13) {
            onUpdateNote();
        }
    }

    const onUpdateNote = () => {
        let body = {
            orders: [{
                orderId: data.id,
                note: data.note
            }]
        };
        if (data.note != item.note) {
            hadLoading(true);
            OrderService.updateNote(body, dispatch).then((res) => {
                item.note = data.note;
                dispatch(showMessageWithStatus("Đã cập nhật ghi chú cho " + data.productId, MESSAGE_STATUS.success));

            }).finally(() => {
                hadLoading(false);
            });
        }
    }

    const onAcceptChange = () => {
        dispatch(closeDialog());
        hadLoading(false);
        OrderService.cancelOrder(data.id, dispatch).then((res) => {
            dispatch(showMessageWithStatus("Đã xóa đơn hàng thành " + data.productId, MESSAGE_STATUS.success));
            forceUpdate();
        }).finally(() => {
            hadLoading(false);
        });
    }

    const onAcceptChangeInfo = () => {
        let body = {
            orderId: data.id,
            productId: data.productId,
            contractId: data.contractId
        };
        OrderService.updateInfo(body, dispatch).then((res) => {
            dispatch(showMessageWithStatus("Đã cập nhật", MESSAGE_STATUS.success));
            item.productId = data.productId;
            item.contractId = data.contractId;
            setConfirmChange(null);
        }).catch(() => {
            dispatch(hideMessage());
            dispatch(showMessageWithStatus("Cập nhật thất bại", MESSAGE_STATUS.error));
            onRetoreInfo();
        });
    }

    const onConfirmChange = () => {
        dispatch(openDialog({
            children: (
                <>
                    <DialogTitle>Xác nhận</DialogTitle>
                    <DialogContent>
                        <Typography> Bạn muốn xóa đơn hàng này.</Typography>
                        <Typography>Vui lòng cân nhắc kĩ, hành động này không thể hoàn tác?</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => dispatch(closeDialog())} color="primary">
                            Hủy
                        </Button>
                        <Button onClick={onAcceptChange} color="primary" autoFocus >
                            Xóa
                        </Button>
                    </DialogActions>
                </>
            )
        }));
    }

    const onRetoreInfo = () => {
        data[confitmChange.fieldName] = item[confitmChange.fieldName];
        setConfirmChange(null);
        setData({ ...data });
    }

    const ConfirmChangeInfoDialog = ({ fieldName = "", name = "", canUpdate = true }) => {
        return (
            <Dialog open={confitmChange != null} maxWidth="xl">
                <DialogTitle>{canUpdate ? "Xác nhận" : "Thông báo"}</DialogTitle>
                {canUpdate ?
                    <>
                        <DialogContent className='w-512'>
                            <Typography>Bạn có thực sự muốn thay đổi {name} này?</Typography>
                            <Typography>Từ "<span className='text-red line-through'>{item[fieldName]}</span>" → "<span className='text-green'>{data[fieldName]}</span>" </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onRetoreInfo} color="primary">
                                Hủy
                            </Button>
                            <Button onClick={onAcceptChangeInfo} color="primary" autoFocus >
                                Cập nhật
                            </Button>
                        </DialogActions>
                    </>
                    :
                    <>
                        <DialogContent className='w-512'>
                            <Typography>Bạn không thể cập nhật được {name} với dữ liệu trống!</Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onRetoreInfo} color="primary">
                                OK
                            </Button>
                        </DialogActions>
                    </>
                }
            </Dialog>
        );
    }

    const getValueOfCustomList = (key, customList = []) => {
        let newKey = key;
        let icon = null;
        if (customList.length == 0) return key;
        customList.forEach(val => {
            if (val.key === key) {
                newKey = val.name;
                icon = val.iconClassName;
            }
        })
        if (icon) {
            return (
                <div className='flex items-center'>
                    <i className={clsx('w-8 h-8 rounded mr-8', icon)} />
                    <span>{newKey}</span>
                </div>
            )
        }
        return newKey;
    }

    const onChangeInfo = (key = "", value) => {
        data[key] = value;
        setData({ ...data });
    }

    const cells = [
        {
            content: isEmptyRow ? "" :
                <IconButton variant="text" size='small' className='p-0'>
                    <div className={clsx("transition-all duration-200", isShowSub ? "rotate-180" : "rotate-0")}>
                        <ArrowDropDownIcon />
                    </div>
                </IconButton>
        },
        {
            textStyle: "font-bold",
            content: isEmptyRow ? "Tổng:" :
                <FilterInCell className="font-bold" name="số KU" onClick={() => onAddRowFilter("contractId", "text", item.contractId)}>
                    <Tooltip title={data.contractId} placement='top'>
                        <span>{data.contractId}</span>
                    </Tooltip>
                </FilterInCell>
        },
        {
            textStyle: "font-bold",
            content: isEmptyRow ? "" :
                <FilterInCell className='flex' name="mã sản phẩm" onClick={() => onAddRowFilter("productId", "text", item.productId)}>
                    <Tooltip title={"Thực hiện giao dịch với " + data.productId} placement='top'>
                        <Button className='rounded-lg my-8 p-0 bg-dark-theme h-32 min-h-0 flex-1' variant="contained" color="secondary"
                            onClick={(e) => { e.stopPropagation(); onOpenPayment(); }}>
                            <p className='w-full truncate'>{data.productId}</p>
                        </Button>
                    </Tooltip>
                </FilterInCell>,
        },
        {
            textStyle: isEmptyRow ? "font-bold" : "",
            content: (data.receivedQuantity / 1000).toLocaleString("vi-VN")
        },
        {
            textStyle: isEmptyRow ? "font-bold" : "",
            content: (data.totalReleaseQuantity / 1000).toLocaleString("vi-VN")
        },
        {
            textStyle: isEmptyRow ? "font-bold" : "",
            content: (data.totalInventoryQuantity / 1000).toLocaleString("vi-VN")
        },
        {
            textStyle: isEmptyRow ? "font-bold" : "",
            content: isEmptyRow ? "" : data.status
        },
        {
            textStyle: isEmptyRow ? "font-bold" : "",
            content: isEmptyRow ? "" :
                <FilterInCell name="ngày lấy chứng từ" onClick={() => onAddRowFilter("lcDate", "datetime", item.lcDate)}>
                    {moment(data.lcDate).format("DD/MM/YYYY")}
                </FilterInCell>
        },
        {
            textStyle: isEmptyRow ? "font-bold" : "",
            content: isEmptyRow ? "" : moment(data.expiredDate).format("DD/MM/YYYY")
        },
        {
            textStyle: isEmptyRow ? "font-bold" : "",
            content: isEmptyRow ? "" : (data.unitPrice * 1000).toLocaleString("vi-VN") + " $"
        },
        {
            textStyle: isEmptyRow ? "font-bold" : "",
            content: (data.totalPrice).toLocaleString("vi-VN", { minimumFractionDigits: 0 }) + " đ"
        },
        {
            textStyle: isEmptyRow ? "font-bold" : "",
            content: (data.totalDebt).toLocaleString("vi-VN", { minimumFractionDigits: 0 }) + " đ"
        },
        {
            textStyle: isEmptyRow ? "font-bold" : "",
            content: data.updatedBy
        },
        {
            textStyle: isEmptyRow ? "font-bold" : "text-left",
            content: data.note?.toLowerCase()
        },
    ]

    if (storageSelected.list.length == 0) return null;

    return [
        <TableRow
            key={data.id}
            className={"min-h-8 cursor-pointer"}
            hover
            onClick={() => { if (!isEmptyRow) hadShowSub(!isShowSub); }}
        >
            {colsPort.map((row, index) => (defineFilter[row.id] || filterCol == null || row.requireFilter) && row.display &&
                <TableCell key={index}
                    className={clsx("p-0 bg-white " + className, row.className, cells[index].textStyle, isEmptyRow && "z-10")}
                    align={row.align} component="td" scope="row" style={{ ...row.moreStyle, ...style }}
                >
                    <Root style={row.moreStyle}>
                        {(typeof (cells[index].content) === "string" && !!row.customList) ?
                            getValueOfCustomList(cells[index].content, row.customList)
                            :
                            cells[index].content
                        }
                    </Root>
                </TableCell>
            )}
        </TableRow>,
        <TableRow
            key={data.id + "-sub"}>
            <TableCell colSpan={filterCol != null ? Object.keys(filterCol).length + 4 : 14} className="bg-gray-100 h-0 p-0" component="td" scope="row" align="left">
                <Root className={clsx('flex w-5/6 xl:w-full lg:w-full overflow-hidden transition-all ease-out duration-300', isShowSub ? "h-auto py-8" : "h-0")}>
                    <ConfirmChangeInfoDialog {...confitmChange} />
                    <Box className='px-16' sx={{ width: 500 }}>
                        <div className='flex items-center'>
                            <Typography textAlign="start" mr={1}>Trạng thái: </Typography>
                            {data.status == "IN_PROGRESS" && <Typography fontWeight="bold" flex={1}>Đang thực hiện</Typography>}
                            {data.status == "COMPLETED" && <Typography fontWeight="bold" flex={1}>Đã hoàn thành</Typography>}
                            {data.status == "CANCELED" && <Typography fontWeight="bold" flex={1}>Đã hủy</Typography>}
                            {data.status != "CANCELED" && !isLoading && historyList?.length === 1 &&
                                <Button variant='outlined' color="primary" onClick={onConfirmChange} >
                                    Hủy
                                </Button>
                            }
                        </div>
                        <TextField
                            className='my-8'
                            label="Số KU"
                            fullWidth
                            value={data.contractId}
                            error={data.contractId == ""}
                            helperText={data.contractId == "" && "Dữ liệu không được để trống"}
                            onChange={({ target: { value } }) => onChangeInfo("contractId", value)}
                            onBlur={() => {
                                if (data.contractId != item.contractId)
                                    setConfirmChange({ fieldName: "contractId", name: "số KU", canUpdate: data.contractId != "" });
                            }}
                            disabled={data.status === "CANCELED"}
                        />
                        <TextField
                            className='my-8'
                            label="Mã hàng"
                            fullWidth
                            value={data.productId}
                            error={data.productId == ""}
                            helperText={data.productId == "" && "Dữ liệu không được để trống"}
                            onChange={({ target: { value } }) => onChangeInfo("productId", value)}
                            onBlur={() => {
                                if (data.productId != item.productId)
                                    setConfirmChange({ fieldName: "productId", name: "mã hàng", canUpdate: data.productId != "" });
                            }}
                            disabled={data.status === "CANCELED"}
                        />
                        <TextField
                            className='my-8'
                            label="Ghi chú"
                            value={data.note ?? ""}
                            helperText={"Dữ liệu sẽ tự động câp nhật nếu bạn rời khỏi khung nhập liệu!"}
                            onChange={({ target: { value } }) => onChangeInfo("note", value)}
                            onKeyDown={onUpdateNotePress}
                            fullWidth
                            onBlur={onUpdateNote}
                        />
                        <Typography className='text-grey-600 text-13 ml-14'>ID: {item.id}</Typography>
                    </Box>
                    <Box className='px-16' sx={{ width: 600 }}>
                        <TableContainer className='rounded-sm max-h-192 overflow-auto' component={Paper} sx={{ minHeight: "calc(100% - 49px)" }}>
                            <Table stickyHeader aria-label="simple-table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableHeader className='p-8' align='center'>Ngày</StyledTableHeader>
                                        <StyledTableHeader className='p-8'>Kho đến</StyledTableHeader>
                                        <StyledTableHeader className='p-8'>Số lượng</StyledTableHeader>
                                        <StyledTableHeader className='p-8' align='center'>Trạng thái</StyledTableHeader>
                                        <StyledTableHeader className='p-8' align='center'>Người cập nhật</StyledTableHeader>
                                    </TableRow>
                                </TableHead>
                                {!isLoading && (
                                    <TableBody>
                                        {historyList.map(val =>
                                            <TableRow key={val.id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell className='p-8' align='center'>
                                                    {moment(val.releaseDate).format("DD/MM/YYYY")}
                                                </TableCell>
                                                <TableCell className='p-8'>
                                                    {val.targetStorageName}
                                                </TableCell>
                                                <TableCell className='p-8'>
                                                    {val.releaseQuantity / 1000} Tấn
                                                </TableCell>
                                                <TableCell className='p-8 flex items-center justify-center' >
                                                    {val.status === "ACTIVE" ?
                                                        <SvgIcon className="text-48" size={24} color="success">heroicons-outline:badge-check</SvgIcon> :
                                                        <SvgIcon className="text-48" size={24} color="disabled">heroicons-outline:x-circle</SvgIcon>
                                                    }
                                                </TableCell>
                                                <TableCell className='p-8' align='center'>
                                                    {val.updatedBy}
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                )}
                            </Table>
                            <Box sx={{ flex: 1, backgroundColor: "#fff" }}>
                                <ShowEmptyValue arr={historyList} />
                            </Box>
                        </TableContainer>
                    </Box>
                </Root>
            </TableCell >
        </TableRow >
    ];
}

export default PortTableRow;
