import { Table } from '@mui/material';
import React from 'react';
import ReleasedTableBody from './ReleasedTableBody';
import ReleasedTableHead from './ReleasedTableHead';

const ReleasedTable = ({ data }) => {

    return (
        <div className='flex-1'>
            <Table stickyHeader>
                <ReleasedTableHead />
                <ReleasedTableBody data={data} />
            </Table>
        </div>
    )
}

export default ReleasedTable;