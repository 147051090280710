import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import history from '@history';
import _ from '@lodash';

export const setUser = createAsyncThunk('user/setUser', async (user, { dispatch, getState }) => {
  let { roles, ...info } = user;
  return {
    roles: roles,
    info: info
  };
});

export const logoutUser = () => async (dispatch, getState) => {
  const { user } = getState();

  if (!user.roles) {
    // is guest
    return null;
  }

  history.push({
    pathname: '/',
  });

  return dispatch(userLoggedOut());
};

const initialState = {
  roles: null,
  info: null
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    userLoggedOut: (state, action) => initialState,
  },
  extraReducers: {
    [setUser.fulfilled]: (state, action) => action.payload,
  },
});

export const { userLoggedOut } = userSlice.actions;

export const selectUser = ({ user }) => user;

export default userSlice.reducer;
