import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    selected: null,
    list: [],
}

const bankSlice = createSlice({
    name: 'homeDashboard/bank',
    initialState,
    reducers: {
        setBank: (state, action) => action.payload
    },
});
export const setBank = bankSlice.actions.setBank;
export const selectBank = ({ homeDashboard }) => homeDashboard.bank;

export default bankSlice.reducer;
