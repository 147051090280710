import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useDispatch } from 'react-redux';
import {
  Button,
  styled,
} from '@mui/material';

import SvgIcon from '@adg/core/SvgIcon';

import { CreateUserDialog } from './dialogs/CreateUserDialog';
import { SelectRole } from './components/SelectRole';
import { SeachUser } from './components/SearchUser';
import UserService from '@service/useService';
import { hideMessage, MESSAGE_STATUS, showMessage, showMessageWithStatus } from 'app/store/adg/messageSlice';

const Root = styled("div")(({ theme }) => ({
  '& .bg-dark-theme': {
    backgroundColor: theme.palette.background.dark,
    color: theme.palette.text.light,
  },
  '& .bg-dark-theme:disabled': {
    backgroundColor: theme.palette.grey[400],
    color: theme.palette.text.light,
  }
}));

const UserManagementHeader = ({ forceUpdate = () => { } }) => {
  const dispatch = useDispatch();
  const [createDialog, setCreateDialog] = useState(false);

  const onCreate = (data, errFunc) => {
    UserService.createUser(dispatch, data).then((res) => {
      setCreateDialog(false);
      dispatch(showMessageWithStatus("Đã thêm thành công '" + data.username + "'", MESSAGE_STATUS.success));
      forceUpdate();
    }).catch(() => {
      dispatch(hideMessage());
      errFunc("Tài khoản đã tồn tại");
    });
  }


  return (
    <Root className="flex flex-col sm:flex-row space-y-16 sm:space-y-0 flex-1 w-full items-center justify-between py-32 px-24 md:px-32">
      <div className="flex flex-col sm:w-auto sm:flex-row space-y-16 sm:space-y-0 items-center  justify-start space-x-8">
        <SelectRole />
      </div>
      <div className="flex flex-col w-full sm:w-auto sm:flex-row space-y-16 sm:space-y-0 flex-1 items-center justify-end space-x-8">
        <SeachUser />
        <motion.div
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}
        >
          <CreateUserDialog open={createDialog} onOk={onCreate} closeDialog={() => setCreateDialog(false)} />
          <Button
            className="bg-dark-theme"
            variant="contained"
            color="secondary"
            startIcon={<SvgIcon>heroicons-outline:plus</SvgIcon>}
            onClick={() => setCreateDialog(true)}
          >
            Thêm mới
          </Button>
        </motion.div>
      </div>
    </Root>
  );
}
export default UserManagementHeader;
