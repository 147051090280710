import axios from "axios";

const ORDER_TRACKING_URL = "/international-payment/tracking/order";
const IMPORT_TRACKING_URL = "/international-payment/tracking/order/read-file";
const IMPORT_RELEASE_URL = "/international-payment/tracking/order/import-inventory-file";
const GET_TRANSACTION_OF_ORDER = "/international-payment/tracking/order/${1}/transaction";
const RELEASE_ORDER = "/international-payment/tracking/order/${1}/release";
const UPDATE_NOTE_ORDER = "/international-payment/tracking/order/update-note";
const UPDATE_INFO_ORDER = "/international-payment/tracking/order/update-info";
const UPDATE_STORAGE_NOTE_ORDER = "/international-payment/tracking/order/storage/update-note";
const UPDATE_STORAGE_DEBIT_ORDER = "/international-payment/tracking/order/storage/add-debt";
const FILTER_ORDER = "/international-payment/tracking/order/filter";
const CANCEL_ORDER = "/international-payment/tracking/order/${1}";
const EXPORT_DATA_URL = "/international-payment/tracking/order/filter/export";

const getOrders = async (data, dispatch = () => { }) => {
    return axios.get(ORDER_TRACKING_URL, {
        params: data
    }).catch((errRes) => {
        axios.mapError(dispatch, errRes.response);
        throw errRes;
    });
}

const importOrder = async (data, dispatch = () => { }) => {
    return axios.post(IMPORT_TRACKING_URL, data).catch((errRes) => {
        if (errRes.response?.status === 400) {
            throw errRes;
        } else {
            axios.mapError(dispatch, errRes.response);
        }
    });
}
const importReleaseOrder = async (data, dispatch = () => { }) => {
    return axios.post(IMPORT_RELEASE_URL, data).catch((errRes) => {
        if (errRes.response?.status === 400) {
            throw errRes;
        } else {
            axios.mapError(dispatch, errRes.response);
        }
    });
}

const confirmOrder = async (data, dispatch = () => { }) => {
    return axios.post(ORDER_TRACKING_URL, data).catch((errRes) => {
        if (errRes.response?.status === 400) {
            throw errRes;
        } else {
            axios.mapError(dispatch, errRes.response);
        }
    });
}

const getTransactionOfOrder = async (orderId, dispatch = () => { }) => {
    return axios.get(GET_TRANSACTION_OF_ORDER.replace("${1}", orderId)).catch((errRes) => {
        axios.mapError(dispatch, errRes.response);
        throw errRes;
    });
}

const updateNote = async (data, dispatch = () => { }) => {
    return axios.put(UPDATE_NOTE_ORDER, data).catch((errRes) => {
        axios.mapError(dispatch, errRes.response);
        throw errRes;
    });
}

const updateStorageNote = async (data, dispatch = () => { }) => {
    return axios.put(UPDATE_STORAGE_NOTE_ORDER, data).catch((errRes) => {
        axios.mapError(dispatch, errRes.response);
        throw errRes;
    });
}

const updateStorageDebitCredit = async (data, dispatch = () => { }) => {
    return axios.put(UPDATE_STORAGE_DEBIT_ORDER, data).catch((errRes) => {
        axios.mapError(dispatch, errRes.response);
        throw errRes;
    });
}

const updateInfo = async (data, dispatch = () => { }) => {
    return axios.put(UPDATE_INFO_ORDER, data).catch((errRes) => {
        axios.mapError(dispatch, errRes.response);
        throw errRes;
    });
}

const releaseOrder = async (orderId, data, dispatch = () => { }) => {
    return axios.post(RELEASE_ORDER.replace("${1}", orderId), data).catch((errRes) => {
        axios.mapError(dispatch, errRes.response);
        throw errRes;
    });
}

const filterOrder = async (data, dispatch = () => { }) => {
    return axios.put(FILTER_ORDER, data).catch((errRes) => {
        axios.mapError(dispatch, errRes.response);
        throw errRes;
    });
}
const cancelOrder = async (orderId, dispatch = () => { }) => {
    return axios.delete(CANCEL_ORDER.replace("${1}", orderId)).catch((errRes) => {
        axios.mapError(dispatch, errRes.response);
        throw errRes;
    });
}
const exportData = async (data, dispatch = () => { }) => {
    return axios.post(EXPORT_DATA_URL, data, {
        responseType: "blob",
    }).catch((errRes) => {
        axios.mapError(dispatch, errRes.response);
        throw errRes;
    });
}

const OrderService = {
    getOrders,
    importOrder,
    importReleaseOrder,
    confirmOrder,
    getTransactionOfOrder,
    updateNote,
    releaseOrder,
    filterOrder,
    cancelOrder,
    updateStorageNote,
    updateStorageDebitCredit,
    updateInfo,
    exportData,
}

export default OrderService;