import SvgIcon from '@adg/core/SvgIcon';
import { IconButton, Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import clsx from 'clsx';
import React, { useState } from 'react';
import { HD } from './HD';
import { PNK } from './PNK';
import { TKHQ } from './TKHQ';

export const TabGroup = ({ className, originData = [], onModifyData = () => { } }) => {

    const [tabIndex, setTabIndex] = useState(0);

    const removeInvoice = (index) => {
        originData.splice(index, 1);
        onModifyData([...originData]);
    }

    const get3LastNumber = (number = "0000") => {
        return number.substring(number.length - 3, number.length);
    }

    const tabProps = (index) => {
        return {
            id: `store-tab-${index}`,
            'aria-controls': `store-tabpanel-${index}`,
        };
    }

    const TabPanelControl = ({ value, index, children }) => {

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`store-tabpanel-${index}`}
                aria-labelledby={`store-tab-${index}`}
            >
                {value === index && (
                    children
                )}
            </div>
        );
    }


    return (
        <Box className="px-10">
            <Box className={className} sx={{
                borderBottom: 1, borderColor: 'divider',
                "& .MuiTabs-indicator": {
                    height: "100%",
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                }
            }}>
                <Tabs value={tabIndex} onChange={(_, index) => setTabIndex(index)} variant="scrollable" scrollButtons="auto">
                    {originData.map((val, index) => (
                        <Tab key={index}
                            label={
                                <Box className="flex items-center z-[1]">
                                    <Box className={clsx("whitespace-nowrap", tabIndex == index && "text-white")}>
                                        {/* {val.type === "HD" ? "HD-" : "P-"}{get3LastNumber(val.soHoaDon)} */}
                                        {val.type === "HD" && (<>
                                            HD - {get3LastNumber(val.soHoaDon)}
                                        </>)}
                                        {val.type === "PNK" && (<>
                                            PNK - {get3LastNumber(val.soHoaDon)}
                                        </>)}
                                        {val.type === "TKHQ" && (<>
                                            Tờ khai Hải quan
                                        </>)}
                                    </Box>
                                    <IconButton component="div" className={clsx('h-24 w-24 ml-4', tabIndex == index && "text-white")}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            removeInvoice(index);
                                        }} >
                                        <SvgIcon size={16} color={"inherit"}>heroicons-outline:x</SvgIcon>
                                    </IconButton>
                                </Box>
                            }
                            {...tabProps(index)}
                        />
                    ))}
                </Tabs>
            </Box>
            {originData.map((val, index) => (
                <TabPanelControl key={index} value={tabIndex} index={index}>
                    {val.type === "HD" && <HD info={val} onChangeData={(data) => {
                        originData[index] = data;
                        onModifyData([...originData]);
                    }} />}
                    {val.type === "TKHQ" && <TKHQ info={val} onChangeData={(data) => {
                        originData[index] = data;
                        onModifyData([...originData]);
                    }} />}
                    {val.type === "PNK" && <PNK info={val} />}
                </TabPanelControl>
            ))}
        </Box>
    )
}

