
import React, { useState } from 'react';
import { TableBody } from '@mui/material';
import { useDispatch } from 'react-redux';

import OrderService from '@service/orderService';

import PortTableRow from './PortTableRow';
import { PaymentDialog } from '../../dialog/PaymentDialog';
import { MESSAGE_STATUS, showMessageWithStatus } from 'app/store/adg/messageSlice';

const PortTableBody = ({ data = [], forceUpdate }) => {
    const dispatch = useDispatch();
    const [itemSelected, setItemSelected] = useState(null);

    const onOk = (id, item, typeName) => {
        let body = {
            releaseQuantity: item.releaseQuantity - 0,
            targetStorageId: item.targetStorageId,
            note: item.note
        }
        OrderService.releaseOrder(id, body, dispatch).then(() => {
            dispatch(showMessageWithStatus(`Đã ${typeName}`, MESSAGE_STATUS.success));
            setItemSelected(null);
            forceUpdate();
        })
    }
    if (data.length === 0) return null;

    return (
        <TableBody>
            <PaymentDialog item={itemSelected} onOk={onOk} closeDialog={() => setItemSelected(null)} />
            {data.map((val, index) => {
                return (
                    <PortTableRow key={index}
                        item={val}
                        forceUpdate={forceUpdate}
                        onOpenPayment={() => setItemSelected(val)} />
                );
            })}
            {data.length > 0 &&
                <PortTableRow
                    item={{
                        id: "total",
                        receivedQuantity: data.map(item => item.receivedQuantity).reduce((prev, b) => prev + b),
                        totalReleaseQuantity: data.map(item => item.totalReleaseQuantity).reduce((prev, b) => prev + b),
                        totalInventoryQuantity: data.map(item => item.totalInventoryQuantity).reduce((prev, b) => prev + b),
                        totalPrice: data.map(item => item.totalPrice).reduce((prev, b) => prev + b),
                        totalDebt: data.map(item => item.totalDebt).reduce((prev, b) => prev + b),
                    }}
                    isEmptyRow={true}
                    className="sticky left-0 py-10 border-t-2 bg-white rowTotal"
                    style={{
                        bottom: 52
                    }}
                />
            }
        </TableBody>
    );
}

export default PortTableBody;
