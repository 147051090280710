import axios from "axios";

import history from "@history";
import { MESSAGE_STATUS, showMessageWithStatus } from "app/store/adg/messageSlice";

const init = () => {
    axios.defaults.baseURL = "https://api.adg-internal.com";
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    // axios.defaults.headers.common['Authorization'] = 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJsdWFuLnBobSIsImV4cCI6MTY2MTc4NzcyNn0.HI3tOEl3WoXahnPR8GrE-q5GR8NiSa0x8YSdlqVJ8_aBf713F78qEj5h7BoLNeA8J3j9bVYOzKp_0IDnhHxdXA';

    axios.mapError = (dispatch, errRes) => {
        if (errRes.status == 404) {
            dispatch(showMessageWithStatus("Lỗi không tìm thấy (404)", MESSAGE_STATUS.error));
        } else if (errRes.status == 400) {
            dispatch(showMessageWithStatus("Lỗi thông tin không chính xác (400)", MESSAGE_STATUS.error));
        } else if (errRes.status >= 500) {
            history.push("/error/500");
        } else {
            dispatch(showMessageWithStatus("Lỗi " + errRes.status, MESSAGE_STATUS.error));
        }
    }
}

export const api = {
    init
};

