
import React, { useState } from 'react';
import { TableBody } from '@mui/material';
import { useDispatch } from 'react-redux';

import OrderTransactionService from '@service/orderTransactionService';

import { ChangeQuantityDialog } from '../../dialog/ChangeQuantityDialog';
import HistoryTableRow from './HistoryTableRow';
import { MESSAGE_STATUS, showMessageWithStatus } from 'app/store/adg/messageSlice';

const HistoryTableBody = ({ data = [], forceUpdate }) => {
    const dispatch = useDispatch();
    const [itemSelected, setItemSelected] = useState(null);

    if (data.length === 0) {
        return null;
    }

    const onOk = (transactionId, value) => {
        let body = {
            transactionId,
            actualReleaseQuantity: value.actutalRelease * 1000,
        };
        OrderTransactionService.partialExport(body, dispatch).then(() => {
            dispatch(showMessageWithStatus(`Đã thay đổi`, MESSAGE_STATUS.success));
            setItemSelected(null);
            forceUpdate();
        }).catch((err) => {
            if (err.response?.status === 400) {
                dispatch(showMessageWithStatus(err.response.data.data, MESSAGE_STATUS.error, { autoHideDuration: 4000 }));
            }
        })
    }

    return (
        <TableBody>
            <ChangeQuantityDialog item={itemSelected} onClose={() => setItemSelected(null)} onOk={onOk} />
            {data.map((val, index) => {
                return (
                    <HistoryTableRow key={val.transactionId}
                        item={val}
                        forceUpdate={forceUpdate}
                        onOpenChangeDialog={() => setItemSelected(val)}
                    />
                );
            })}
            {data.length > 0 &&
                <HistoryTableRow
                    item={{
                        transactionId: "total",
                        actualTransactionReleaseQuantity: data.map(item => item.actualTransactionReleaseQuantity).reduce((prev, b) => prev + b),
                    }}
                    isEmptyRow={true}
                    className="sticky left-0 py-10 border-t-2 bg-white rowTotal"
                    style={{
                        bottom: 52
                    }}
                />
            }
        </TableBody>
    );

}

export default HistoryTableBody;
