import _ from '@lodash';
import {
	styled,
	Typography
} from '@mui/material';
import { motion } from 'framer-motion';

import withRouter from '@adg/core/withRouter';

import { TabPanelControl } from './components/TabControl';
import PortTable from './tables/port';
import OtherTable from './tables/other';
import HistoryTable from './tables/history';
import { useSelector } from 'react-redux';
import { selectStorages } from './store/storageSlice';

const Root = styled("div")(({ theme, position }) => ({
	'&  .table_baocao': {
	},
	'& .MuiTableRow-hover:hover td': {
		backgroundColor: "#eef2ff"
	},
	'& .rowTotal td': {
		backgroundColor: "#eef2ff"
	},
	'& .bg-dark-theme': {
		backgroundColor: theme.palette.background.dark,
		color: theme.palette.text.light,
	},
	'& .bg-dark-theme:disabled': {
		backgroundColor: theme.palette.grey[400],
		color: theme.palette.text.light,
	}
}));

const DashboardTable = ({ updated, forceUpdate = () => { } }) => {
	const tab = useSelector(selectStorages);

	return (
		<>
			<TabPanelControl key={0} value={tab.index} index={0}>
				<Root className="items-center h-full">
					<PortTable updated={updated} forceUpdate={forceUpdate} />
				</Root>
			</TabPanelControl>
			<TabPanelControl key={1} value={tab.index} index={1}>
				<Root className="items-center h-full">
					<HistoryTable updated={updated} forceUpdate={forceUpdate} />
				</Root>
			</TabPanelControl>
			{tab.selectedList?.map((t, index) => {
				return (
					<TabPanelControl key={index + 2} value={tab.index} index={index + 2}>
						<Root className="items-center h-full">
							<OtherTable storage={t} updated={updated} forceUpdate={forceUpdate} />
						</Root>
					</TabPanelControl>
				)
			})}
		</ >
	);
}

export const ShowEmptyValue = ({ arr = [] }) => {
	if (arr.length === 0) {
		return (
			<motion.div
				initial={{ opacity: 0 }}
				animate={{ opacity: 1, transition: { delay: 0.1 } }}
				className="flex flex-1 justify-center h-full"
			>
				<Typography color="text.secondary" variant="h5">
					Chưa có thông tin!
				</Typography>
			</motion.div>
		);
	}
	return null;
}

export default withRouter(DashboardTable);
