
import React, { } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    styled,
    TableBody,
    TextField,
    Typography,
} from '@mui/material';

import OtherTableRow from './OtherTableRow';
import { PaymentDialog2 } from '../../dialog/PaymentDialog2';
import { useState } from 'react';
import StorageService from '@service/storageService';
import { useDispatch } from 'react-redux';
import OrderService from '@service/orderService';
import { MESSAGE_STATUS, showMessageWithStatus } from 'app/store/adg/messageSlice';

const OtherTableBody = ({ data = [], storage, forceUpdate }) => {
    const dispatch = useDispatch();

    const [itemSelected, setItemSelected] = useState(null);
    // const [itemDebit, setItemDebit] = useState(null);

    const onOk = (item, typeName = "") => {
        let body = {
            orderId: item.id,
            sourceStorageId: storage.id,
            transactionType: item.type,
            releaseQuantity: item.releaseQuantity,
            note: item.note
        }
        StorageService.releaseStorageTransaction(storage.id, body, dispatch).then(() => {
            dispatch(showMessageWithStatus(`Đã ${typeName.toLowerCase()} thành công!`, MESSAGE_STATUS.success));
            setItemSelected(null);
            forceUpdate();
        });
    }
    // const setOpenDebit = (row) => {
    //     let newData = { ...row }
    //     newData.debitCredit = (row.debitCredit ?? 0) / 1000;
    //     setItemDebit(newData);
    // };
    // const onCloseDialog = () => {
    //     setItemDebit(null);
    // };

    // const onUpdateDebitCredit = (item) => {
    //     let body = {
    //         orderId: item.orderId,
    //         storageId: storage.id,
    //         debtQuantity: item.debitCredit * 1000
    //     }
    //     OrderService.updateStorageDebitCredit(body, dispatch).then(() => {
    //         onCloseDialog();
    //         dispatch(showMessageWithStatus("Đã cập nhật", MESSAGE_STATUS.success));
    //         forceUpdate();
    //     })
    // }

    if (data.length === 0) {
        return null;
    }
    return (
        <TableBody>
            <PaymentDialog2 item={itemSelected} onOk={onOk} closeDialog={() => setItemSelected(null)} />
            {data.map((val, index) => (
                <OtherTableRow
                    key={index}
                    storage={storage}
                    item={val}
                    // onOpenDebitEdit={() => setOpenDebit(val)}
                    onOpenPayment={() => setItemSelected(val)} />
            ))}
            {data.length > 0 &&
                <OtherTableRow
                    item={{
                        importQuantity: data.map(item => item.importQuantity).reduce((prev, b) => prev + b),
                        releaseQuantity: data.map(item => item.releaseQuantity).reduce((prev, b) => prev + b),
                        inventoryQuantity: data.map(item => item.inventoryQuantity).reduce((prev, b) => prev + b),
                        actualReleaseQuantity: data.map(item => item.actualReleaseQuantity).reduce((prev, b) => prev + b),
                        debitCredit: data.map(item => item.debitCredit).reduce((prev, b) => prev + b),
                        actualInventoryQuantity: data.map(item => item.actualInventoryQuantity).reduce((prev, b) => prev + b),
                    }}
                    storage={storage}
                    isEmptyRow={true}
                    className="sticky left-0 py-10 border-t-2 bg-white rowTotal"
                    style={{
                        bottom: 52
                    }}
                />
            }
        </TableBody>
    );

}

export default OtherTableBody;
