import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    selected: null,
    list: [],
}

const roleSlice = createSlice({
    name: 'userManagement/role',
    initialState,
    reducers: {
        setRoles: (state, action) => {
            state.list = action.payload;
        },
        setRoleSelected: (state, action) => {
            state.selected = action.payload
        }
    },
});

export const setRoles = roleSlice.actions.setRoles;
export const setRoleSelected = roleSlice.actions.setRoleSelected;
export const selectRole = ({ userManagement }) => userManagement.role;

export default roleSlice.reducer;
