import { Box, Chip, lighten, Stack, Table, TablePagination } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import Loading from '@adg/core/Loading';
import OrderService from '@service/orderService';

import { ShowEmptyValue } from '../../DashboardTable';
import PortTableBody from './PortTableBody';
import PortTableHead, { colsPort } from './PortTableHead';
import { selectBank } from '../../store/bankSlice';
import { selectRowFilter, setRowFilter } from '../../store/rowFilterSlice';

export const currentPortTableInfo = {
    direction: null,
    id: null,
    pageIndex: null,
    rowsPerPage: null,
}

const PortTable = ({ updated, forceUpdate }) => {
    const dispatch = useDispatch();
    const { selected: bankSelected } = useSelector(selectBank);
    const portFilter = useSelector(selectRowFilter("port-" + bankSelected?.bankId));

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [page, setPage] = useState({
        pageIndex: 0,
        totalRecords: 0
    });
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [order, setOrder] = useState({
        direction: 'desc',
        id: "lcDate",
    });
    const tiGia = localStorage.getItem("TI_GIA") - 0;

    useEffect(() => {
        if (bankSelected) {
            _loadDataForByFilter(page.pageIndex, rowsPerPage);
        }
    }, [updated, bankSelected, portFilter, order]);

    currentPortTableInfo.id = order.id;
    currentPortTableInfo.direction = order.direction;
    currentPortTableInfo.pageIndex = page.pageIndex;
    currentPortTableInfo.rowsPerPage = rowsPerPage;

    const _loadDataForByFilter = (pageIndex, pageSize) => {
        setLoading(true);
        let portId = bankSelected.storageOfBank?.id;
        if (!portId) alert("ngân hàng hiện tại không có kho");
        let filterParams = [];
        portFilter?.forEach(val => {
            filterParams.push({
                field: val.columnId,
                dataType: val.columnType == "text" ? "string" : val.columnType,
                operator: val.condition.operator,
                value: val.unit == "(Tấn)" ? val.condition.vals.map(v => v * 1000) : val.condition.vals
            });
        })
        let body = {
            portId,
            sortField: order.id,
            sortDirection: order.direction.toUpperCase(),
            pageIndex,
            pageSize,
            filters: {
                and: filterParams
            },
        }
        OrderService.filterOrder(body, dispatch).then(({ data: { data: res } }) => {
            setPage({
                pageIndex,
                totalRecords: res.totalRecords
            });
            setRowsPerPage(pageSize);
            setData(res.orders);
        }).catch(() => {
            setPage({
                pageIndex: 0,
                totalRecords: 0,
            });
            setData([]);
        }).finally(() => {
            setLoading(false);
        });
    }

    const handleRequestSort = (event, property) => {
        const id = property;
        let direction = 'desc';

        if (order.id === property && order.direction === 'desc') {
            direction = 'asc';
        }

        setOrder({
            direction,
            id,
        });
    }

    const handleChangePage = (_, value) => {
        _loadDataForByFilter(value, rowsPerPage);
    }

    const handleChangeRowsPerPage = ({ target: { value } }) => {
        _loadDataForByFilter(0, value);
    }

    const generateTagFilter = () => {
        let tags = [];
        [...portFilter].forEach((val, index) => {
            let colName = colsPort.find((r) => r.id == val.columnId).label;
            let values = val.condition.vals;
            if (val.columnType === "datetime") {
                values = values.map(date => moment(date).format("DD/MM/YYYY"));
            } else if (val.columnType === "text") {
                if (val.customList) {
                    values = values.map((key) => {
                        let newText = val.customList.filter(item => item.key == key)[0].name;
                        return `${newText}`;
                    });
                } else {
                    values = values.map(text => `'${text}'`);
                }
            } else if (val.columnType === "number") {
                let unit = val.unit ? " " + val.unit : "";
                values = values.map(num => `${num}${unit}`);
            }
            let mess = `${colName} ${val.condition.id} ${values.join(" và ")}`;
            tags.push(
                <Chip key={index} label={mess} color="primary" onDelete={() => onDeleteTagFilter(index)} />
            );
        })
        return tags;
    }

    const onDeleteTagFilter = (index) => {
        let newOrderFilter = [...portFilter];
        newOrderFilter.splice(index, 1);
        dispatch(setRowFilter({
            id: "port-" + bankSelected.bankId,
            list: newOrderFilter
        }));
    };

    return (
        <div className='flex flex-col relative w-full overflow-auto' style={{ height: "calc(100vh - 112px)" }}>
            {portFilter?.length > 0 &&
                <Stack direction="row" className='sticky top-0 left-0 z-99' spacing={1} px={2} py={1}
                    sx={{
                        backgroundColor: (theme) => lighten(theme.palette.background.default, 0.4),
                    }}>
                    {generateTagFilter()}
                </Stack>
            }
            <div className='flex-1'>
                <Table stickyHeader className="table_baocao" aria-labelledby="tableTitle">
                    <PortTableHead
                        order={order}
                        onRequestSort={handleRequestSort}
                        rowCount={data.length}
                    />
                    {!loading &&
                        <PortTableBody data={data} forceUpdate={forceUpdate} />
                    }
                </Table>

            </div>
            {loading ?
                <div className="flex items-center justify-center h-full">
                    <Loading />
                </div>
                :
                <>
                    <ShowEmptyValue arr={data} />
                    <div className='w-full flex flex-row items-center border-t-1 sticky left-0 bottom-0 bg-white z-20'>
                        <p className='p-8'>
                            Tỷ giá: <span className='text-dark font-bold ml-16'> 1$ = {tiGia.toLocaleString("vi-VN")} VND</span>
                        </p>
                        <TablePagination
                            className="flex-1 shrink-0"
                            component="div"
                            count={page.totalRecords}
                            rowsPerPage={rowsPerPage}
                            labelRowsPerPage="Số hàng mỗi trang"
                            labelDisplayedRows={({ from, to, count }) => {
                                return `${from}–${to} trong tổng ${count !== -1 ? count : `hơn ${to}`}`;
                            }}
                            page={page.pageIndex}
                            backIconButtonProps={{
                                'aria-label': 'Trước',
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Sau',
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
                </>
            }

        </div>
    )
}

export default PortTable;
