import { createTheme, getContrastRatio } from '@mui/material/styles';
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import _ from '@lodash';
import {
  defaultSettings,
  defaultThemeOptions,
  extendThemeWithMixins,
  getParsedQuerySettings,
  mustHaveThemeOptions,
} from '@adg/default-settings';
import settingsConfig from 'app/configs/settingsConfig';
import LayoutConfig from '@adg/core/Layout/LayoutConfig';
import { setUser } from 'app/store/userSlice';
import { darkPaletteText, lightPaletteText } from 'app/configs/themesConfig';

const getInitialSettings = () => {
  const layout = {
    config: LayoutConfig.defaults,
  };
  return _.merge({}, defaultSettings, { layout }, settingsConfig, getParsedQuerySettings());
}

export const generateSettings = (_defaultSettings, _newSettings) => {
  const response = _.merge(
    {},
    _defaultSettings,
    _newSettings
  );

  return response;
}

const initialSettings = getInitialSettings();

const initialState = {
  initial: initialSettings,
  defaults: _.merge({}, initialSettings),
  current: _.merge({}, initialSettings),
};

export const setDefaultSettings = createAsyncThunk(
  'adg/settings/setDefaultSettings',
  async (val, { dispatch, getState }) => {
    const { adg } = getState();
    const { settings } = adg;
    const defaults = generateSettings(settings.defaults, val);

    return {
      ...settings,
      defaults: _.merge({}, defaults),
      current: _.merge({}, defaults),
    };
  }
);

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setSettings: (state, action) => {
      const current = generateSettings(state.defaults, action.payload);
      return {
        ...state,
        current,
      };
    },

    setInitialSettings: (state, action) => {
      return _.merge({}, initialState);
    },
    resetSettings: (state, action) => {
      return {
        ...state,
        defaults: _.merge({}, state.defaults),
        current: _.merge({}, state.defaults),
      };
    },
  },
  extraReducers: {
    [setDefaultSettings.fulfilled]: (state, action) => action.payload,
    [setUser.fulfilled]: (state, action) => {
      const defaults = generateSettings(state.defaults, action.payload?.data?.settings);
      return {
        ...state,
        defaults: _.merge({}, defaults),
        current: _.merge({}, defaults),
      };
    },
  },
});

const getDialogTheme = (state) => state.adg.settings.current.theme.dialog;
const getMainTheme = (state) => state.adg.settings.current.theme.main;
const getNavbarTheme = (state) => state.adg.settings.current.theme.navbar;
const getToolbarTheme = (state) => state.adg.settings.current.theme.toolbar;
const getFooterTheme = (state) => state.adg.settings.current.theme.footer;

function generateMuiTheme(theme) {
  const data = _.merge({}, defaultThemeOptions, theme, mustHaveThemeOptions);
  const response = createTheme(
    _.merge({}, data, {
      mixins: extendThemeWithMixins(data)
    })
  );
  return response;
}

export const selectContrastMainTheme = (bgColor) => {
  function isDark(color) {
    return getContrastRatio(color, '#ffffff') >= 3;
  }
  return isDark(bgColor) ? selectMainThemeDark : selectMainThemeLight;
};

function changeThemeMode(theme, mode) {
  const modes = {
    dark: {
      palette: {
        mode: 'dark',
        divider: 'rgba(241,245,249,.12)',
        background: {
          paper: '#1E2125',
          default: '#121212',
        },
        text: darkPaletteText,
      },
    },
    light: {
      palette: {
        mode: 'light',
        divider: '#e2e8f0',
        background: {
          paper: '#FFFFFF',
          default: '#F7F7F7',
        },
        text: lightPaletteText,
      },
    },
  };

  return _.merge({}, theme, modes[mode]);
}

export const selectDialogTheme = createSelector(
  [getDialogTheme],
  (theme) => generateMuiTheme(changeThemeMode(theme, 'dark'))
);

export const selectMainTheme = createSelector(
  [getMainTheme],
  (theme) => generateMuiTheme(theme)
);

export const selectMainThemeDark = createSelector(
  [getMainTheme],
  (theme) => generateMuiTheme(changeThemeMode(theme, 'dark'))
);

export const selectMainThemeLight = createSelector(
  [getMainTheme],
  (theme) => generateMuiTheme(changeThemeMode(theme, 'light'))
);

export const selectNavbarTheme = createSelector(
  [getNavbarTheme],
  (theme) => generateMuiTheme(theme)
);

export const selectToolbarTheme = createSelector(
  [getToolbarTheme],
  (theme) => generateMuiTheme(theme)
);

export const selectFooterTheme = createSelector(
  [getFooterTheme],
  (theme) => generateMuiTheme(theme)
);

export const selectFooterThemeDark = createSelector(
  [getFooterTheme],
  (theme) => generateMuiTheme(changeThemeMode(theme, 'dark'))
);

export const selectFooterThemeLight = createSelector(
  [getFooterTheme],
  (theme) => generateMuiTheme(changeThemeMode(theme, 'light'))
);

export const selectCurrentSettings = ({ adg }) => adg.settings.current;

export const selectCurrentLayoutConfig = ({ adg }) => adg.settings.current.layout.config;

export const selectDefaultSettings = ({ adg }) => adg.settings.defaults;

export const selectThemesSettings = ({ adg }) => adg.settings.themes;

export const { resetSettings, setInitialSettings, setSettings } = settingsSlice.actions;

export default settingsSlice.reducer;
