import SvgIcon from '@adg/core/SvgIcon';
import { IconButton, Input, MenuItem, Select, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import React, { useState } from 'react';

export const DataView = ({ data, rootValue, onChange, type = "text", list }) => {
    const [changeMode, setChangeMode] = useState(false);
    const [val, setVal] = useState(data);

    const onChangeValue = () => {
        onChange(val);
    }

    if (changeMode) {
        switch (type) {
            case "list":
                return (
                    <div className="flex items-center">
                        <Select fullWidth size='small' value={data} onChange={({ target: { value } }) => setVal(value)}>
                            {list.map((val) => (
                                <MenuItem key={val.value} value={val.value}>{val.name}</MenuItem>
                            ))}
                        </Select>
                        <IconButton className="" color="primary" size="sm"
                            onClick={() => {
                                setChangeMode(false);
                                onChangeValue();
                            }}>
                            <SvgIcon>
                                heroicons-outline:pencil-alt
                            </SvgIcon>
                        </IconButton>
                    </div>
                );

            case "datetime":
                return (
                    <div className="flex items-center">
                        <DatePicker
                            className='flex-1'
                            value={moment(val)}
                            onChange={(value) => setVal(value?.format("YYYY-MM-DD HH:mm:ss"))}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    fullWidth
                                />
                            )}
                        />
                        <IconButton className="" color="primary" size="sm"
                            onClick={() => {
                                setChangeMode(false);
                                onChangeValue();
                            }}>
                            <SvgIcon>
                                heroicons-outline:pencil-alt
                            </SvgIcon>
                        </IconButton>
                    </div>
                );
            default:
                return (
                    <div className="flex items-center">

                        <Input className="flex-1 rounded-0 text-dark py-0 h-auto " type={type}
                            value={val}
                            onChange={({ target: { value } }) => setVal(value)} />

                        <IconButton className="" color="primary" size="sm"
                            onClick={() => {
                                setChangeMode(false);
                                onChangeValue();
                            }}>
                            <SvgIcon>
                                heroicons-outline:pencil-alt
                            </SvgIcon>
                        </IconButton>
                    </div>
                );
        }
    } else {
        return (
            <div className="flex items-center">
                <div className="flex-1">
                    {data !== rootValue ?
                        <span className="text-green-600">
                            {type === "list" ? list.find(item => item.value === val).name :
                                type === "datetime" ? moment(val).format("DD/MM/YYYY")
                                    : val}
                        </span>
                        :
                        type === "list" ? list.find(item => item.value === val).name :
                            type === "datetime" ? moment(val).format("DD/MM/YYYY") :
                                type === "number" ? !!data && (data - 0).toLocaleString("vi-VN", { minimumFractionDigits: 0 }) :
                                    data
                    }
                </div>
                <IconButton className="" color="primary" size="sm"
                    onClick={() => {
                        setChangeMode(true);
                    }}>
                    <SvgIcon>
                        heroicons-outline:pencil
                    </SvgIcon>
                </IconButton>
            </div>
        )
    }

}