import axios from 'axios';
import { TelegramService } from './telegramService';
const HOST_API = "https://api.adg-internal.com";
const TTQT = "/international-payment";
const HSGN = "/disbursement";

const IMPORT_URL = TTQT + HSGN + '/${1}/import';
const EXPORT_URL = TTQT + HSGN + '/${1}/export';

const uploadData = async (bank, formData, dispatch) => {
    let url = IMPORT_URL.replace("${1}", bank);
    return axios.post(HOST_API + url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            "X-Requested-With": "*"
        },
        responseType: "json",
    }).catch((errRes) => {

        TelegramService.sendMessage(bank?.toUpperCase() + " - IMPORT - Failed");
        if (errRes?.response?.status === 500) {
            throw errRes.response;
        } else {
            axios.mapError(dispatch, errRes.response);
        }

    }).then((res) => {
        TelegramService.sendMessage(bank?.toUpperCase() + " - IMPORT - Successful");
        return res;
    });




}

const exportData = async (bank, data, dispatch = () => { }) => {
    let url = EXPORT_URL.replace("${1}", bank);
    return axios.post(HOST_API + url, data, {
        responseType: "blob"
    }).catch((errRes) => {
        TelegramService.sendMessage(bank?.toUpperCase() + " - EXPORT - Failed");
        axios.mapError(dispatch, errRes.response);
        throw errRes;
    }).then(res => {
        TelegramService.sendMessage(bank?.toUpperCase() + " - EXPORT - Successful");
        return res;
    });
}

const DocumentsService = {
    uploadData,
    exportData
}

export default DocumentsService;