import themesConfig from 'app/configs/themesConfig';

const settingsConfig = {
  layout: {
    config: {},
  },
  theme: {
    main: themesConfig.default,
    navbar: themesConfig.defaultDark,
    toolbar: themesConfig.default,
    footer: themesConfig.defaultDark,
  },
  loginRedirectUrl: '/',
};

export default settingsConfig;
