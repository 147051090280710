import React, { useState } from 'react';
import { Box, Button, Paper, TextField, Typography } from "@mui/material";
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import _ from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';

import SvgIcon from '@adg/core/SvgIcon';
import history from '@history';
import UserService from '@service/useService';
import { MESSAGE_STATUS, showMessageWithStatus } from 'app/store/adg/messageSlice';


const ChangePassword = () => {
    const dispatch = useDispatch();
    const [waiting, setWaiting] = useState(false);

    const changePass = yup.object().shape({
        oldPass: yup.string().required('Vui lòng nhập giá trị'),
        newPass: yup.string().required('Vui lòng nhập giá trị').matches(/([0-9a-zA-Z\.@#$%!&?]{8,})/, "Mật khẩu cần ít 8 kí tự"),
        rePass: yup.string().oneOf([yup.ref('newPass')], "Mật khẩu không trùng khớp").required('Vui lòng nhập giá trị'),
    });
    const formPassDefault = {
        oldPass: '',
        newPass: '',
        rePass: ''
    };
    const { control, formState, handleSubmit, setValue, setError, getValues, clearErrors } = useForm({
        mode: 'onChange',
        defaultValues: formPassDefault,
        resolver: yupResolver(changePass),
    });

    const { isValid, dirtyFields, errors } = formState;

    const onSubmit = (data) => {
        setWaiting(true);
        let body = {
            oldPassword: data.oldPass,
            newPassword: data.newPass,
        }
        UserService.changePass(dispatch, body).then(() => {
            dispatch(showMessageWithStatus("Đã thay đổi mật khẩu", MESSAGE_STATUS.success));
            history.replace("/");
        }).catch(() => {
            setError("oldPass", {
                message: "Mật khẩu không đúng",
                type: "value"
            });
        }).finally(() => {
            setWaiting(false);

        })
    }

    const onCancel = () => {
        history.replace("/");
    }

    return (
        <Box p={4} className="flex w-full h-full items-center justify-center">
            <Paper className="flex flex-col flex-auto p-24 shadow rounded-2xl overflow-hidden max-w-512">
                <Box component="form" className="flex flex-col"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Typography className="text-lg font-medium tracking-tight leading-6 truncate">
                        Đổi mật khẩu
                    </Typography>
                    <div >
                        <Controller
                            control={control}
                            name="oldPass"
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    className="mt-32"
                                    label="Mật khẩu cũ"
                                    type="password"
                                    error={!!errors.oldPass}
                                    helperText={errors?.oldPass?.message}
                                    variant="outlined"
                                    fullWidth
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="newPass"
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    onChange={(e) => {
                                        field.onChange(e);
                                        if (getValues("rePass") !== "") {
                                            if (e.target.value !== getValues("rePass")) {
                                                setError("rePass", {
                                                    message: "Mật khẩu không trùng khớp",
                                                    type: "value"
                                                });
                                            } else {
                                                clearErrors("rePass");
                                            }
                                        }
                                    }}
                                    className="mt-32"
                                    label="Mật khẩu mới"
                                    type="password"
                                    error={!!errors.newPass}
                                    helperText={errors?.newPass?.message}
                                    variant="outlined"
                                    fullWidth
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="rePass"
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    className="mt-32"
                                    label="Nhập lại mật khẩu"
                                    type="password"
                                    error={!!errors.rePass}
                                    helperText={errors?.rePass?.message}
                                    variant="outlined"
                                    fullWidth
                                />
                            )}
                        />
                    </div>
                    <div className='mt-12 flex justify-end'>
                        <Button variant='outlined' className='mr-8' onClick={onCancel}>
                            Hủy
                        </Button>
                        <Button variant='contained' color='primary' type='submit'
                            disabled={_.isEmpty(dirtyFields) || !isValid}
                        >
                            OK
                        </Button>
                    </div>
                </Box>
            </Paper>
        </Box>
    )
}

export default ChangePassword;