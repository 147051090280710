import SvgIcon from '@adg/core/SvgIcon';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, styled, TextField, Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import React, { useState, useEffect } from 'react';

const Root = styled("div")(({ theme }) => ({
    '& .bg-dark-theme': {
        backgroundColor: theme.palette.background.dark,
        color: theme.palette.text.light,
    },
    '& .bg-dark-theme:disabled': {
        backgroundColor: theme.palette.grey[400],
        color: theme.palette.text.light,
    }
}));

export const ChangeQuantityDialog = ({ item, onClose, onOk }) => {

    const [value, setValue] = useState({
        payRelease: "",
        errPayRelease: "",
        actutalRelease: "",
        errActutalRelease: "",
        isChanged: false,
    });

    useEffect(() => {
        if (item) {
            setValue({
                payRelease: "",
                errPayRelease: "",
                actutalRelease: "",
                errActutalRelease: "",
                isChanged: false,
            });
        }
    }, [item]);

    const onChange = (val, isActualRelease = false) => {
        if (isActualRelease) {
            value.actutalRelease = val;
            value.payRelease = item.actualTransactionReleaseQuantity / 1000 - val;
        } else {
            value.payRelease = val;
            value.actutalRelease = item.actualTransactionReleaseQuantity / 1000 - val;
        }
        if (val !== "") {
            if (!val.replaceAll(",", ".").match(/^([\d\.]{1,})$/) || val - 0 > item.actualTransactionReleaseQuantity / 1000 ||
                val - 0 < 0) {
                value.errActutalRelease = value.errPayRelease = "Giá trị không hợp lệ";
            } else {
                value.errActutalRelease = value.errPayRelease = "";
            }
        } else {
            if (isActualRelease) {
                value.errActutalRelease = "Vui lòng nhập giá trị";
            } else {
                value.errPayRelease = "Vui lòng nhập giá trị";
            }
        }

        value.isChanged = true;
        setValue({ ...value });
    }

    const checkOk = () => {
        return (
            value.actutalRelease !== "" &&
            value.payRelease !== "" &&
            value.errPayRelease === "" &&
            value.errActutalRelease === "" &&
            value.isChanged === true
        );
    }

    return (
        <Dialog
            open={item !== null}
            maxWidth='xl'
            onClose={onClose}
        >
            <Root>
                <DialogTitle className='bg-dark-theme flex space-x-84 min-w-640'>
                    <span className='flex-1 text-left'>
                        {item?.contractId} - {item?.productId}
                    </span>
                    <span className='text-right'>
                        {item?.sourceStorageName} &rarr; {item?.targetStorageName}
                    </span>
                </DialogTitle>
                <DialogContent>
                    <div className='p-16'>
                        <div className='flex space-x-52 mb-32'>
                            <div className='flex-1'>
                                <Typography className='text-xl'>
                                    Giải chấp:
                                    <span className='font-bold'> {(item?.actualTransactionReleaseQuantity / 1000).toLocaleString("vi-VN")} (Tấn)
                                    </span>
                                </Typography>
                            </div>
                            <div className='flex-1'>
                                <Typography className='text-xl'>
                                    Ngày tạo:
                                    <span className='font-bold'> {moment(item?.transactionCreatedAt).format("DD/MM/YYYY")}
                                    </span>
                                </Typography>
                            </div>
                        </div>
                        <div className='flex space-x-52'>
                            <FormControl className='flex-1' sx={{ w: 120 }}>
                                <TextField
                                    label="Trả giải chấp"
                                    type="number"
                                    InputProps={{ inputProps: { style: { textAlign: "right" }, } }}
                                    value={value.payRelease}
                                    onChange={({ target: { value } }) => onChange(value, false)}
                                    error={value.isChanged && value.errPayRelease !== ""}
                                    helperText={value.errPayRelease}
                                />
                            </FormControl>
                            <FormControl className='flex-1' sx={{ w: 120 }}>
                                <TextField
                                    label="Giải chấp thực tế"
                                    type="number"
                                    InputProps={{ inputProps: { style: { textAlign: "right" }, } }}
                                    value={value.actutalRelease}
                                    onChange={({ target: { value } }) => onChange(value, true)}
                                    error={value.isChanged && value.errActutalRelease !== ""}
                                    helperText={value.errActutalRelease}
                                />
                            </FormControl>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button color="primary"
                        onClick={onClose}
                    >
                        Hủy
                    </Button>
                    <Button color="primary" variant='contained' autoFocus
                        onClick={() => onOk(item.transactionId, value)}
                        disabled={!checkOk()}
                    >
                        Xác nhận
                    </Button>
                </DialogActions>
            </Root>
        </Dialog>
    )
};