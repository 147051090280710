import { Box, Button, Card, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import React, { createRef, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useDispatch } from 'react-redux';

import SvgIcon from '@adg/core/SvgIcon';
import history from '@history';
import OrderService from '@service/orderService';

import { getFileType, ItemIcon } from './components/FileItem';
import ReleasedTable from './tables/released';
import { fromJSON } from 'postcss';
import { MESSAGE_STATUS, showMessageWithStatus } from 'app/store/adg/messageSlice';
import StorageService from '@service/storageService';
import { closeDialog, openDialog } from 'app/store/adg/dialogSlice';

const repareDataRelease = (data) => {
    let newData = [];
    data.forEach(({ releaseTransactions, ...info }) => {
        let releaseTmp = [];
        let imcomeTmp = [];
        releaseTransactions.forEach((rt, index) => {
            if (rt.relatedOrders == null || rt.relatedOrders.length == 0) {
                releaseTmp.push(
                    {
                        orderId: "fail-" + index,
                        contractId: "(Dữ liệu không đúng)",
                        productId: rt.productId,
                        actualInventoryQuantity: 10000,
                        exportQuantity: rt.exportQuantity,
                        error: true,
                        errMessage: rt.errorMessage,
                        transactionType: rt.transactionType
                    },
                );
                imcomeTmp.push({
                    error: true
                });
            } else {
                releaseTmp.push(
                    ...rt.relatedOrders.map((o, i) => ({
                        ...o,
                        releaseQuantity: rt.incomingTransactions[i]?.releaseQuantity,
                        exportQuantity: rt.exportQuantity,
                        error: !rt.inventoryAvailable,
                        errMessage: rt.errorMessage,
                        transactionType: rt.transactionType
                    }))
                );
                imcomeTmp.push(
                    ...rt.incomingTransactions
                );
            }
        });
        newData.push({
            releaseTransactions: releaseTmp,
            incomingTransactions: imcomeTmp,
            ...info
        });
    });
    return newData;
}

export const DashboardRelease = () => {
    const dispatch = useDispatch();

    const inputRef = createRef();
    const [file, setFile] = useState(null);
    const [dataConfirm, setDataConfirm] = useState(null);
    const [dataConfirmOrigin, setDataConfirmOrigin] = useState();

    useEffect(() => {
        inputRef.current?.click();
    }, []);

    const onBack = () => {
        history.back();
    }

    const onSelectedFile = ({ target: { files } }) => {
        setFile(files[0]);
        // setDataConfirm(data.data);
        let type = getFileType(files[0].type);
        if (type === "ZIP") {
            let body = new FormData();
            body.append('file', files[0]);
            OrderService.importReleaseOrder(body, dispatch).then(({ data: { data: res } }) => {
                let dataView = repareDataRelease(res);
                setDataConfirm(dataView);
                setDataConfirmOrigin(res);
                if (res.length === 0) {
                    dispatch(showMessageWithStatus("Dữ liệu không hợp lệ", MESSAGE_STATUS.warning));
                }
            }).catch((err => {
                if (err.response?.status === 400) {
                    let dataView = repareDataRelease(err.response.data.data);
                    setDataConfirm(dataView);
                    setDataConfirmOrigin(err.response.data.data)
                    dispatch(openDialog({
                        children: (
                            <React.Fragment>
                                <DialogTitle>Lỗi nhập liệu</DialogTitle>
                                <DialogContent>
                                    <DialogContentText className='whitespace-pre'>
                                        {err.response?.data?.messages?.map(v => "- " + v).join("\n") ?? "Lỗi dữ liệu"}
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => dispatch(closeDialog())} color="primary" autoFocus>
                                        OK
                                    </Button>
                                </DialogActions>
                            </React.Fragment>
                        ),
                        maxWidth: "lg"
                    }));
                } else {
                    console.error(err);
                }
            }));
        } else {
            dispatch(showMessageWithStatus("Tệp không hợp lệ", MESSAGE_STATUS.warning));
        }
    };

    const onRemoveFile = () => {
        setFile(null);
        setDataConfirm(null);
        setDataConfirmOrigin(null);
        inputRef.current.value = "";
    }

    const onConfirmData = () => {
        let isOk = true;
        let body = {
            transactions: []
        }
        dataConfirm?.forEach(val => {
            val.releaseTransactions.forEach(rt => {
                if (rt.error) {
                    isOk = false;
                }
            });
            if (isOk) {
                body.transactions.push(
                    ...val.incomingTransactions.filter((val) => val.error !== true)
                );
            }
        })
        if (isOk && (dataConfirm ?? []).length !== 0) {
            StorageService.multipleReleaseStorageTransaction(body, dispatch).then(() => {
                dispatch(showMessageWithStatus("Đã giải chấp thành công", MESSAGE_STATUS.success));
                history.replace("/app/dashboard");
            });
        } else {
            dispatch(showMessageWithStatus("Dữ liệu hiện không hợp lệ", MESSAGE_STATUS.warning));
        }
    }

    return (
        <Box className='flex flex-col w-full' pt={2} height="calc(100vh - 50px)">
            <input ref={inputRef} type='file' className='hidden' accept='.zip'
                onChange={onSelectedFile}
            />
            <Box mb={2} px={4}>
                <Card>
                    <Box className='flex items-center m-4'>
                        <IconButton
                            onClick={onBack}>
                            <SvgIcon>
                                heroicons-outline:arrow-left
                            </SvgIcon>
                        </IconButton>
                        <Typography ml={2} variant='h6'>
                            Giải chấp
                        </Typography>
                        <Box className='flex flex-1 m-4 items-center justify-end'>
                            {file ?
                                <motion.div
                                    className='flex items-center'
                                    initial={{ opacity: 0, x: 30 }}
                                    animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}
                                >
                                    <ItemIcon size={32} type={file.type} textSize="text-10" />
                                    <Typography ml={3} fontWeight="bold">{file.name}</Typography>
                                    <IconButton onClick={onRemoveFile}>
                                        <SvgIcon>
                                            heroicons-outline:x
                                        </SvgIcon>
                                    </IconButton>
                                </motion.div>
                                :
                                <Button variant='outlined' color="primary"
                                    startIcon={<SvgIcon>heroicons-outline:upload</SvgIcon>}
                                    onClick={() => inputRef.current?.click()}
                                >
                                    Nhập
                                </Button>
                            }
                            {file &&
                                <Box pl={4}>
                                    <Button variant='contained' color='primary'
                                        onClick={onConfirmData}>
                                        Xác nhận
                                    </Button>
                                </Box>
                            }
                        </Box>
                    </Box>
                </Card>
            </Box>
            <Box px={4} className='flex-1 overflow-y-auto'>
                {dataConfirm?.map((val, index) =>
                    <Box key={index} mb={2}>
                        <Card>
                            <Box p={2} pb={1}>
                                <Typography variant='h6' className="font-bold  border-b-2 border-gray-300">
                                    {val.bankName} - {val.storageName}
                                </Typography>
                                <Box className='shadow-md' mb={2} mt={1}>
                                    <ReleasedTable data={val.releaseTransactions} />
                                </Box>
                            </Box>
                        </Card>
                    </Box>
                )}
                <Box >
                    {(!dataConfirm || dataConfirm.length === 0) && !!file &&
                        <Card>
                            <Box p={2}>
                                <Typography variant='h6' className="font-bold text-center">
                                    Không có dữ liệu
                                </Typography>
                            </Box>
                        </Card>
                    }
                </Box>
            </Box>

        </Box>
    )
}
