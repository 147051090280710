import SvgIcon from '@adg/core/SvgIcon';
import { Box, Icon, IconButton, Tab, Tabs, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectBank } from '../store/bankSlice';
import { selectStorages, setCurrentStorage, setStorageIndex } from '../store/storageSlice';

const tabProps = (index) => {
    return {
        id: `store-tab-${index}`,
        'aria-controls': `store-tabpanel-${index}`,
    };
}

export const TabPanelControl = ({ value, index, children }) => {

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`store-tabpanel-${index}`}
            aria-labelledby={`store-tab-${index}`}
        >
            {value === index && (
                children
            )}
        </div>
    );
}

export const TabHeaderControl = [
    { label: "Cảng", id: "port" },
    { label: "Lịch sử", id: "history" },
]

export const TabControl = ({ bankId, className }) => {
    const dispatch = useDispatch();
    const tabs = useSelector(selectStorages);

    useEffect(() => {
        if (bankId) {
            _updateLocalData();
        }
    }, [tabs.selectedList]);

    const _updateLocalData = () => {
        let bankStorage = localStorage.getItem("BANK_STORAGE");
        if (bankStorage) {
            bankStorage = JSON.parse(bankStorage);
        } else {
            bankStorage = {}
        }
        bankStorage[bankId] = tabs.selectedList?.map((val) => ({ id: val.id }));
        localStorage.setItem("BANK_STORAGE", JSON.stringify(bankStorage));
    }

    const onChange = (_, index) => {
        dispatch(setStorageIndex(index));
    }


    const onRemoveTab = (index) => {
        let list = _.merge([], tabs.list);
        let selectedList = _.merge([], tabs.selectedList);
        let tab = selectedList[index];
        list.forEach((val) => {
            if (val.id == tab.id) {
                val.display = false;
            }
        });
        selectedList.splice(index, 1);
        let tabIndex = tabs.index;
        if (tabIndex >= selectedList.length + 1) {
            tabIndex--;
        }
        dispatch(setCurrentStorage({
            index: tabIndex,
            list,
            selectedList,
            value: selectedList[index],
        }))
    }

    return (
        <Box className={className} sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabs.index} onChange={onChange} variant={tabs.selectedList.length == 0 ? null : "scrollable"} scrollButtons="auto">

                <Tab
                    key={0} focusRipple
                    label={TabHeaderControl[0].label}
                    {...tabProps(0)}
                />
                <Tab
                    key={1} focusRipple
                    label={TabHeaderControl[1].label}
                    {...tabProps(1)}
                />
                {tabs.selectedList?.map((val, index) => (
                    <Tab key={index}
                        label={
                            <Box className="flex items-center">
                                <Typography>
                                    {val.name}
                                </Typography>
                                <IconButton component="div" className='h-24 w-24 ml-4' onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    onRemoveTab(index);
                                }} >
                                    <SvgIcon size={16}>heroicons-outline:x</SvgIcon>
                                </IconButton>
                            </Box>
                        }
                        {...tabProps(index + 2)}
                    />
                ))}
            </Tabs>
        </Box>
    )
}