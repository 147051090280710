import React, { } from 'react';
import { DataView } from './DataView';
import { Table } from '@mui/material';

export const HD = ({ info, onChangeData = () => { } }) => {

    const onChange = (val, name) => {
        info[name] = val;
        onChangeData(info);
    }

    return (
        <Table className='mt-8'>
            <thead>
                <tr className='border-b-1 border-gray-700'>
                    <th className='p-10 min-w-160'>Hạng mục</th>
                    <th className="p-10 text-center">Thông tin</th>
                    <th className='p-10 min-w-160'>Hạng mục</th>
                    <th className="p-10 text-center">Thông tin</th>
                </tr>
            </thead>
            <tbody>
                <tr className='border-b-1 border-gray-700'>
                    <td className='font-bold'>Ngày hạch toán</td>
                    <td className="text-center">
                        <DataView data={info.ngayHachToan} rootValue={info.originData.ngayHachToan}
                            type="datetime"
                            onChange={(val) => onChange(val, "ngayHachToan")}
                        />
                    </td>
                    <td className='font-bold'>Số chứng từ</td>
                    <td className="text-center">
                        <DataView data={info.soChungTu} rootValue={info.originData.soChungTu}
                            onChange={(val) => onChange(val, "soChungTu")}
                        />
                    </td>
                </tr>
                <tr className='border-b-1 border-gray-700'>
                    <td className='font-bold'>Ngày chứng từ</td>
                    <td className="text-center">
                        <DataView data={info.ngayChungTu} rootValue={info.originData.ngayChungTu}
                            type="datetime"
                            onChange={(val) => onChange(val, "ngayChungTu")}
                        />
                    </td>
                    <td className='font-bold'>Số hóa đơn</td>
                    <td className="text-center">
                        <DataView data={info.soHoaDon} rootValue={info.originData.soHoaDon}
                            onChange={(val) => onChange(val, "soHoaDon")}
                        />
                    </td>
                </tr>
                <tr className='border-b-1 border-gray-700'>
                    <td className='font-bold'>Nhà cung cấp</td>
                    <td className="text-center">
                        <DataView data={info.nhaCungCap.split(" ").splice(-4).join(" ")} rootValue={info.originData.nhaCungCap.split(" ").splice(-4).join(" ")}
                            onChange={(val) => onChange(val, "nhaCungCap")}
                        />
                    </td>
                    <td className='font-bold'>Diễn giải</td>
                    <td className="text-center">
                        <DataView data={info.dienGiai} rootValue={info.originData.dienGiai}
                            onChange={(val) => onChange(val, "dienGiai")}
                        />
                    </td>
                </tr>
                <tr className='border-b-1 border-gray-700'>
                    <td className='font-bold'>Tổng tiền hàng</td>
                    <td className="text-center"
                    >
                        <DataView data={info.tongTienHang} rootValue={info.originData.tongTienHang}
                            type="number"
                            onChange={(val) => onChange(val, "tongTienHang")}
                        />
                    </td>
                    <td className='font-bold'>Tiền chiết khấu</td>
                    <td className="text-center">
                        <DataView data={info.tienChietKhau} rootValue={info.originData.tienChietKhau}
                            type="number"
                            onChange={(val) => onChange(val, "tienChietKhau")}
                        />
                    </td>
                </tr>
                <tr className='border-b-1 border-gray-700'>
                    <td className='font-bold'>Tiền thuế GTGT</td>
                    <td className="text-center">
                        <DataView data={info.tienThueGTGT} rootValue={info.originData.tienThueGTGT}
                            type="number"
                            onChange={(val) => onChange(val, "tienThueGTGT")}
                        />
                    </td>
                    <td className='font-bold'>Tổng tiền thanh toán</td>
                    <td className="text-center">
                        <DataView data={info.tongTienThanhToan} rootValue={info.originData.tongTienThanhToan}
                            type="number"
                            onChange={(val) => onChange(val, "tongTienThanhToan")}
                        />
                    </td>
                </tr>
                <tr className='border-b-1 border-gray-700'>
                    <td className='font-bold'>Chi phí mua hàng</td>
                    <td className="text-center">
                        <DataView data={info.chiPhiMuaHang} rootValue={info.originData.chiPhiMuaHang}
                            type="number"
                            onChange={(val) => onChange(val, "chiPhiMuaHang")}
                        />
                    </td>
                    <td className='font-bold'>Giá trị nhập kho</td>
                    <td className="text-center">
                        <DataView data={info.giaTriNhapKho} rootValue={info.originData.giaTriNhapKho}
                            type="number"
                            onChange={(val) => onChange(val, "giaTriNhapKho")}
                        />
                    </td>
                </tr>
                <tr className='border-b-1 border-gray-700'>
                    <td className='font-bold'>Nhận hóa đơn</td>
                    <td className="text-center">
                        <DataView data={info.nhanHoaĐon} rootValue={info.originData.nhanHoaĐon}
                            onChange={(val) => onChange(val, "nhanHoaĐon")}
                        />
                    </td>
                    <td className='font-bold'>Là chi phí mua hàng</td>
                    <td className="text-center">
                        <DataView data={info.laChiPhiMuaHang === "false" ? "false" : "true"}
                            type="list"
                            list={[
                                { name: "Đúng", value: "true" },
                                { name: "Không phải", value: "false" },
                            ]}
                            rootValue={info.originData.laChiPhiMuaHang === "false" ? "false" : "true"}
                            onChange={(val) => onChange(val, "laChiPhiMuaHang")}
                        />
                    </td>
                </tr>
                <tr className='border-b-1 border-gray-700'>
                    <td className='font-bold'>Loại chứng từ</td>
                    <td className="text-center">
                        <DataView data={info.loaiChungTu} rootValue={info.originData.loaiChungTu}
                            onChange={(val) => onChange(val, "loaiChungTu")}
                        />
                    </td>
                    <td className='font-bold'>Phí trước hải quan</td>
                    <td className="text-center">
                        <DataView data={info.phiTruocHaiQuan} rootValue={info.originData.phiTruocHaiQuan}
                            onChange={(val) => onChange(val, "phiTruocHaiQuan")}
                        />
                    </td>
                </tr>
                <tr className='border-b-1 border-gray-700'>
                    <td className='font-bold'>Tiền thuế NK</td>
                    <td className="text-center">
                        <DataView data={info.tienThueNK} rootValue={info.originData.tienThueNK}
                            type="number"
                            onChange={(val) => onChange(val, "tienThueNK")}
                        />
                    </td>
                    <td className='font-bold'>Tiền thuế TTĐB</td>
                    <td className="text-center">
                        <DataView data={info.tienThueTTĐB} rootValue={info.originData.tienThueTTĐB}
                            type="number"
                            onChange={(val) => onChange(val, "tienThueTTĐB")}
                        />
                    </td>
                </tr>
            </tbody>
        </Table>
    )
}