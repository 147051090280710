import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import PageCarded from '@adg/core/PageCarded';
import withReducer from 'app/store/withReducer';

import UserManangementHeader from './UserManangementHeader';
import UserManangementTable from './UserManangementTable';
import reducer from './store';

const UserPage = () => {

	const [updated, set_] = useState(true);


	const forceUpdate = () => {
		set_(!updated);
	}

	const dispatch = useDispatch();

	useEffect(() => {
	}, []);

	return (
		<PageCarded
			header={<UserManangementHeader forceUpdate={forceUpdate} />}
			content={<UserManangementTable updated={updated} forceUpdate={forceUpdate} />}
			scroll={'content'}
		/>
	);
}

export default withReducer('userManagement', reducer)(UserPage);
