import React, { useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogTitle, FormControl, styled, TextField, Typography } from "@mui/material";
import _ from 'lodash';

import Tabs from "@adg/core/Tabs";

import { InfoItem } from '../components/InfoItem';
import { StyledNav, StyledTab, StyledTabs } from '../components/styles/customTab';


const Root = styled("div")(({ theme }) => ({
    '&': {
        minHeight: "30vh"
    },
    '& .bg-dark-theme': {
        backgroundColor: theme.palette.background.dark,
        color: theme.palette.text.light,
    },
    '& .bg-dark-theme:disabled': {
        backgroundColor: theme.palette.grey[400],
        color: theme.palette.text.light,
    }
}));

export const ConfirmDialog = ({ bankSelected, item, closeDialog = () => { }, onOk = () => { } }) => {

    const [tabIndex, setTabIndex] = useState(0);

    if (item == null) return <></>;

    const handleChange = (_, newValue) => {
        setTabIndex(newValue);
    };

    const propsTab = (index) => {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,

        };
    }

    const TabPanel = (props) => {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`vertical-tabpanel-${index}`}
                aria-labelledby={`vertical-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ px: 3 }}>
                        {children}
                    </Box>
                )}
            </div>
        );
    }

    return (
        <Dialog onClose={closeDialog} open={true} maxWidth="md" fullWidth={true}
            classes={{ paper: 'rounded-8', }}>
            <Root>
                <DialogTitle className="bg-dark-theme">
                    <div className='flex flex-row justify-center'>
                        <p className='flex-1'>Xác nhận thông tin</p>
                    </div>
                </DialogTitle>
                <div className="flex flex-col justify-center w-full">
                    <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }} className="w-full"
                    >
                        <StyledNav>
                            <StyledTabs
                                orientation="vertical"
                                variant="scrollable"
                                indicatorColor="secondary"
                                value={tabIndex}
                                onChange={handleChange}
                                sx={{ borderRight: 1, borderColor: 'divider', flex: 1 }}
                            >
                                {item.purchaseOrders.map((val, index) => (
                                    <StyledTab sx={{ whiteSpace: "nowrap" }} key={index} label={val.code} {...propsTab(index)} />
                                ))}
                            </StyledTabs>
                            <div className='p-16'>
                                <Typography color="#FFF">
                                    Ngân hàng
                                </Typography>
                                <Typography fontWeight="bold" color="#FFF">
                                    {bankSelected?.bankName}
                                </Typography>
                            </div>
                        </StyledNav>
                        <div>
                            {item.purchaseOrders.map((val, index) => {
                                const list = val.children.map(val => ({
                                    label: val.productId,
                                    content: (item, other) => <InfoItem data={val} />
                                }));
                                return (
                                    <TabPanel key={index} value={tabIndex} index={index}>
                                        <Tabs prefix={<span className='font-bold mr-8'>Các mã hàng:</span>} list={list} closeDialog={closeDialog} onOk={onOk} />
                                    </TabPanel>
                                )
                            })}
                            <DialogActions>
                                <Button color="primary"
                                    onClick={closeDialog}
                                >
                                    Hủy
                                </Button>
                                <Button className='bg-dark-theme' color="primary" variant='contained' autoFocus
                                    onClick={() => onOk()}
                                >
                                    Xác nhận
                                </Button>
                            </DialogActions>
                        </div>
                    </Box>
                </div>
            </Root>
        </Dialog >
    )
};
