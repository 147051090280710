import SvgIcon from '@adg/core/SvgIcon';
import { Box, Button, Card, TextField, Typography } from '@mui/material';
import DocumentsService from '@service/documentsServices';
import OrderService from '@service/orderService';
import { selectUser } from 'app/store/userSlice';
import React, { useState, createRef } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TabGroup } from './components/TabGroup';

import FileItem from './components/FileItem';
import { DatePicker } from '@mui/x-date-pickers';
import { TimeCounter } from './components/TimeCounter';
import moment from 'moment';
import { hideMessage, MESSAGE_STATUS, showMessage, showMessageWithStatus } from 'app/store/adg/messageSlice';

let lastTmp = "";
let tmpBlobDownload = null;

const convertPNK = (pnkList) => {
    let list = [];
    pnkList?.map(pnk => {
        let isExist = false;

        list?.map((val, index, arr) => {
            if (pnk.soHoaDon === val.soHoaDon && pnk.nhaCungCap === val.nhaCungCap) {
                let tmp = { ...pnk };
                delete tmp.soHoaDon;
                delete tmp.nhaCungCap;
                arr[index].thongtin.push(tmp);
                isExist = true;
            }
        });

        if (!isExist) {
            let tmp = { soHoaDon: pnk.soHoaDon, nhaCungCap: pnk.nhaCungCap, thongtin: [] };
            delete pnk.soHoaDon;
            delete pnk.nhaCungCap;
            tmp.thongtin.push(pnk);
            list.push(tmp);
        }
    });
    return list;
}

const convertToOriginalPNK = (pnkList) => {
    let list = [];
    pnkList.map(pnk => {
        let infoMap = pnk.thongtin;
        infoMap?.map((val, index, arr) => {

            let tmp = val;
            tmp.soHoaDon = pnk.soHoaDon;
            tmp.nhaCungCap = pnk.nhaCungCap;
            list.push(tmp)

        });
    });
    return list;
}

// set focus tab
const setIndexForBill = (confirmData = { pnk: [], hd: [], tkhq: [] }) => {
    //convert PNk from Backend to PNK on UI
    let newPNK = [];
    if (confirmData.pnk?.length > 0) {
        newPNK = convertPNK(confirmData.pnk);

        newPNK?.map((val, index, arr) => {
            arr[index].type = "PNK";
            arr[index].index = index;
        });

    }


    confirmData.hd?.map((val, index, arr) => {
        arr[index].type = "HD";
        arr[index].index = index;
    });
    confirmData.hd = confirmData.hd || [];

    let toKhaiTab = {};
    toKhaiTab.list = confirmData.tkhq;
    toKhaiTab.type = "TKHQ";


    let arr = [...confirmData.hd, ...newPNK];

    if (confirmData.tkhq !== undefined) {
        arr.push(toKhaiTab);
    }
    return arr;
}

const DocumentPage = ({ bank }) => {
    const dispatch = useDispatch();
    const { info } = useSelector(selectUser);

    const [file, setFile] = useState(null);
    const [dataConfirm, setDataConfirm] = useState(null);
    const [contractDate, setContractDate] = useState(new Date());
    const [contractNumber, setContractNumber] = useState("1");
    const [download, setDownload] = useState(null);
    const inputRef = createRef();

    useEffect(() => {
        if (lastTmp !== bank) {
            lastTmp = bank;
            onReset();
        }
    }, [bank]);

    const onReset = () => {
        setFile(null);
        setDataConfirm(null);
        setDownload(null);
        if (inputRef.current) {
            inputRef.current.value = "";
        }
    }

    const onSelectedFile = ({ target: { files: f } }) => {
        setFile(f[0]);
    };

    //upload clicked 
    const onUploadFile = () => {
        const formData = new FormData();
        formData.append('file', file);
        setContractNumber("1");
        setContractDate(new Date());
        DocumentsService.uploadData(bank, formData, dispatch).then(({ data }) => {
            let newData = setIndexForBill(data.data);
            newData.forEach(val => {
                val.originData = { ...val }
            })
            setDataConfirm(newData);
            if (inputRef.current) {
                inputRef.current.value = "";
            }

        }).catch((err) => {
            dispatch(showMessageWithStatus("Vui lòng kiểm tra lại thông tin", MESSAGE_STATUS.warning));
        })
    };

    const onRemoveFile = () => {
        setFile(null);
        inputRef.current.value = "";
    }
    const onAddFile = () => {
        inputRef.current?.click();
    }

    const onCancel = () => {
        setDataConfirm(null);
        setFile(null);
        if (inputRef.current) {
            inputRef.current.value = "";
        }
    }

    const onConfirm = () => {
        if (!contractDate || !moment(contractDate).isValid() || contractNumber === "") {
            return;
        }
        //loading
        const body = {
            pnk: [],
            hd: [],
            tkhq: [],
            fileDate: moment(contractDate).format("DD/MM/YYYY"),
            contractNumber: contractNumber
        }
        dataConfirm.forEach(val => {
            delete val.index;
            if (val.type === "HD") {
                delete val.type;
                body.hd.push(val);
            } else if ((val.type === "TKHQ")) {
                delete val.type;
                val.list.map(tkhq => body.tkhq.push(tkhq));
            }
            else {
                delete val.type;
                body.pnk.push(val);
            }
        })


        let originalPNK = convertToOriginalPNK(body.pnk);
        body.pnk = originalPNK;
        body.bank = bank;

        DocumentsService.exportData(bank, { data: body }, dispatch).then((res) => {
            tmpBlobDownload = URL.createObjectURL(new Blob([res.data], { type: "application/zip" }));
            setDataConfirm(null);
            setFile(null);
            setDownload("Hồ sơ " + moment(contractDate).format("DD-MM-YYYY"));
            // stop loading
        }).catch(err => {
            alert('Có lỗi trong quá trình giải ngân hồ sơ !');
        });
    }
    return (
        <div className='h-full flex flex-col items-center justify-center'>
            {!download &&
                <Card className='p-8 mb-10'>
                    <input ref={inputRef} type='file' className='hidden' accept='.zip'
                        onChange={onSelectedFile}
                    />
                    {dataConfirm ?
                        <div className='flex items-center'>
                            <p className='flex-1 w-[40vw] p-8'>
                                Nhập từ tệp: <span className='font-bold'>{file.name}</span>
                            </p>

                            <Button className='bg-dark-theme w-160 text-grey-800 mr-8' variant="outlined" color="inherit"
                                onClick={onCancel} >
                                Hủy
                            </Button>
                            <Button className='bg-dark-theme w-160' variant="contained" color="secondary"
                                onClick={onConfirm} >
                                Xác nhận
                            </Button>
                        </div>
                        :
                        <div>
                            <div className="flex flex-wrap">
                                {file ? (
                                    <FileItem item={file} onRemove={onRemoveFile} />
                                ) :
                                    <FileItem isAddNew={true} onAdd={onAddFile} />
                                }

                            </div>
                            {file &&
                                <div className='flex justify-center p-8'>
                                    <Button
                                        className='bg-dark-theme w-160' variant="contained" color="secondary"
                                        startIcon={<SvgIcon>heroicons-outline:upload</SvgIcon>}
                                        onClick={onUploadFile}
                                    >
                                        Nhập
                                    </Button>

                                </div>
                            }
                        </div>
                    }

                </Card>
            }
            <Card className='transition-all w-[97vw] lg:w-10/12' style={{
                minHeight: dataConfirm ? "calc(100vh - 220px)" : "0px",
                padding: dataConfirm ? 8 : 0
            }}>
                {dataConfirm &&
                    <Box>
                        <Box className='flex mb-4 items-start px-8'>
                            <Typography className='flex-1 font-bold text-15 pt-20'>
                                Vui lòng xác nhận thông tin
                            </Typography>
                            <TextField
                                className='mr-16'
                                value={contractNumber}
                                onChange={({ target: { value } }) => setContractNumber(value)}
                                variant="standard"
                                label="Số hợp đồng"
                                error={contractNumber === ""}
                                helperText={contractNumber === "" ? "Vui lòng điền thông tin" : undefined}
                                size="small"
                                type="number"
                            />
                            <DatePicker
                                value={contractDate}
                                onChange={(value) => setContractDate(value)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Ngày giải ngân"
                                        variant="standard"
                                        error={params.error || contractDate == null}
                                        helperText={(params.error || contractDate == null) ? "Vui lòng chọn ngày" : undefined}
                                        size="small"
                                    />
                                )}
                            />
                        </Box>
                        <TabGroup originData={dataConfirm} onModifyData={setDataConfirm} />
                    </Box>
                }
            </Card>
            <Card className='transition-all duration-300 delay-200' style={{
                minHeight: download ? 160 : 0,
                width: download ? "51.2rem" : 0,
                padding: download ? 8 : 0
            }}>
                {download &&
                    <Box>
                        <Box className='flex items-center p-10'>
                            <span className='text-lg flex-1 px-10'>
                                Tải {download.replaceAll("-", "/").toLocaleLowerCase()}
                            </span>
                            <a className="p-52 hover:bg-grey-200 bg-grey-50 rounded-6" href={tmpBlobDownload}
                                download={download + ` (${bank})` + ".zip"}>
                                <SvgIcon size={40}>
                                    heroicons-outline:document-download
                                </SvgIcon>
                            </a>
                        </Box>
                        <Button fullWidth variant='text' color='info'
                            className='font-bold text-18'
                            onClick={onReset}>
                            Trở về
                        </Button>
                    </Box>
                }
            </Card>
            {download && <TimeCounter message="Sẽ tự động trở về sau" timeMax={180} onEndTime={onReset} />}
        </div>
    )
}

export default DocumentPage;
