import { combineReducers } from '@reduxjs/toolkit';

import role from './roleSlice';
import search from './searchSlice';

const reducer = combineReducers({
    role,
    search,
});

export default reducer;
