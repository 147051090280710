import { Navigate } from 'react-router-dom';

import Utils from '@adg/utils';
import Loading from '@adg/core/Loading';

import LoginConfig from '../main/login/LoginConfig';
import LogoutConfig from '../main/logout/LogoutConfig';
import DashboardConfig from '../main/app/dashboard/DashboardConfig';
import ErrorPagesConfig from '../main/error/ErrorPagesConfig';
import UserManangementConfig from '../main/app/user-management/UserManangementConfig';
import ChangePasswordConfig from '../main/app/change-password/ChangePasswordConfig';
import DocumentConfig from '../main/app/doccument/DocumentConfig';

const routeConfigs = [ErrorPagesConfig, LogoutConfig, LoginConfig, DashboardConfig, UserManangementConfig, ChangePasswordConfig, DocumentConfig];

const routes = [
  ...Utils.generateRoutesFromConfigs(routeConfigs),
  {
    path: '/',
    element: <Navigate to="app/dashboard" />,
  },
  {
    path: 'loading',
    element: <Loading />,
  },
  {
    path: '*',
    element: <Navigate to="error/404" replace />,
  },
];

export default routes;
