import React from 'react';
import { TableCell, TableHead, TableRow, TableSortLabel, Tooltip, lighten } from '@mui/material';
import clsx from 'clsx';

export const rows = [
  {
    id: 'username',
    display: true,
    align: 'left',
    disablePadding: true,
    label: 'username',
    sort: true,
    className: "",
    moreStyle: {},
    requireFilter: false,
    isConditionCol: true,
    valueType: 'text'
  },
  {
    id: 'fullName',
    display: true,
    align: 'left',
    disablePadding: true,
    label: 'Họ và tên',
    sort: true,
    className: "",
    requireFilter: false,
    moreStyle: {},
    isConditionCol: true,
    valueType: 'text'
  },
  {
    id: 'role',
    display: true,
    align: 'left',
    disablePadding: true,
    label: 'Role',
    sort: true,
    className: "",
    requireFilter: false,
    moreStyle: {},
    isConditionCol: true,
    valueType: 'text'
  },
  {
    id: 'userCreatedAt',
    display: true,
    align: 'left',
    disablePadding: true,
    label: 'Ngày tạo',
    sort: true,
    className: "",
    requireFilter: false,
    moreStyle: {},
    isConditionCol: true,
    valueType: 'datetime'
  },
  {
    id: 'action',
    display: true,
    align: 'center',
    disablePadding: false,
    label: 'Kích hoạt',
    sort: false,
    className: "",
    requireFilter: false,
    moreStyle: { width: 100 },
    isConditionCol: false,
    valueType: ''
  },
];

const TblHead = ({ onRequestSort, order }) => {

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow className="sticky top-0 h-40 z-20">
        {rows.map((row, index, arr) => (
          <TableCell
            sx={{
              backgroundColor: (theme) => lighten(theme.palette.background.default, 0.4),
            }}
            className={clsx("py-8 m-0 font-semibold", row.className != null ? "z-50" : "", row.className)}
            key={row.id}
            style={row.moreStyle}
            sortDirection={order.id === row.id ? order.direction : false}
            scope='row'
          >
            <div style={{ ...row.moreStyle, textAlign: row.align }}>
              {row.sort ?
                <Tooltip
                  title="Sắp xếp"
                  placement={row.align === 'right' ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={order.id === row.id}
                    direction={order.direction}
                    onClick={createSortHandler(row.id)}
                    hideSortIcon={true}
                  >
                    {row.label.split("").map((c, i) => c === "\n" ? <br key={i} /> : c)}
                    {!!row.sublabel && <><br />{row.sublabel}</>}
                  </TableSortLabel>
                </Tooltip>
                :
                row.label.split("").map((c, i) => c === "\n" ? <br key={i} /> : c)
              }
            </div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default TblHead;
