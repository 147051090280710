
import React, { } from 'react';
import { TableBody } from '@mui/material';

import ReleasedTableRow from './ReleasedTableRow';

const ReleasedTableBody = ({ data = [] }) => {

    if (data.length === 0) {
        return null;
    }

    return (
        <TableBody>
            {data.map((val) => {
                return (
                    <ReleasedTableRow key={val.orderId}
                        item={val}
                    />
                );
            })}
        </TableBody>
    );

}

export default ReleasedTableBody;
