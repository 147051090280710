import React from 'react';
import { TableCell, TableHead, TableRow, TableSortLabel, Tooltip, lighten } from '@mui/material';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { selectColFilter } from '../../store/colFilterSlice';
import { selectRowFilter } from '../../store/rowFilterSlice';
import { selectBank } from '../../store/bankSlice';

export const colsPort = [
  {
    id: 'id',
    display: true,
    align: 'left',
    disablePadding: true,
    label: '',
    sort: false,
    className: "sticky z-10",
    moreStyle: { width: 30, left: 0 },
    requireFilter: true,
    isConditionCol: false,
    valueType: ''
  },
  {
    id: 'contractId',
    display: true,
    align: 'left',
    disablePadding: true,
    label: 'Số KU',
    sort: true,
    className: "sticky z-10",
    moreStyle: { width: 160, left: 30 },
    requireFilter: true,
    isConditionCol: true,
    valueType: 'text'
  },
  {
    id: 'productId',
    display: true,
    align: 'center',
    disablePadding: true,
    label: 'Mã hàng',
    sort: true,
    className: "sticky z-10",
    requireFilter: true,
    moreStyle: { width: 230, left: 190, overflow: "hidden" },
    isConditionCol: true,
    valueType: 'text'
  },

  {
    id: 'receivedQuantity',
    display: true,
    align: 'center',
    disablePadding: true,
    label: 'Nhập',
    sublabel: '(Tấn)',
    sort: true,
    requireFilter: false,
    moreStyle: { width: 80 },
    isConditionCol: true,
    valueType: 'number',
    sum: true,
  },
  {
    id: 'totalReleaseQuantity',
    display: true,
    align: 'center',
    disablePadding: false,
    label: 'Xuất Bán',
    sublabel: '(Tấn)',
    sort: true,
    requireFilter: false,
    moreStyle: { width: 80 },
    isConditionCol: true,
    valueType: 'number',
    sum: true,
  },
  {
    id: 'totalInventoryQuantity',
    display: true,
    align: 'center',
    disablePadding: false,
    label: "SL tồn",
    sublabel: '(Tấn)',
    sort: true,
    requireFilter: false,
    moreStyle: { width: 80 },
    isConditionCol: true,
    valueType: 'number',
    sum: true,
  },
  {
    id: 'status',
    display: false,
    align: 'center',
    disablePadding: false,
    requireFilter: false,
    sort: false,
    moreStyle: { width: 120 },
    label: "Trạng thái",
    isConditionCol: true,
    valueType: 'text',
    customList: [
      {
        name: "Đang thực hiện",
        key: "IN_PROGRESS",
        iconClassName: "bg-blue"
      },
      {
        name: "Đã hoàn thành",
        key: "COMPLETED",
        iconClassName: "bg-green"
      },
      {
        name: "Đã hủy",
        key: "CANCELED",
        iconClassName: "bg-red"
      },
    ]
  },
  {
    id: 'lcDate',
    display: true,
    align: 'center',
    disablePadding: false,
    label: 'Ngày lấy\nchứng từ',
    sort: true,
    requireFilter: false,
    moreStyle: { width: 100 },
    isConditionCol: true,
    valueType: 'datetime'
  },
  {
    id: 'expiredDate',
    display: true,
    align: 'center',
    disablePadding: false,
    label: 'Ngày\nhết hạn',
    sort: false,
    requireFilter: false,
    moreStyle: { width: 100 },
    isConditionCol: false,
    valueType: 'datetime'
  },
  {
    id: 'unitPrice',
    display: true,
    align: 'center',
    disablePadding: false,
    label: "Đơn giá",
    sublabel: '($/Tấn)',
    sort: true,
    requireFilter: false,
    moreStyle: { width: 80 },
    isConditionCol: true,
    valueType: 'number'
  },
  {
    id: 'totalPrice',
    display: true,
    align: 'center',
    disablePadding: false,
    label: 'Trị giá',
    sublabel: '(VND)',
    sort: false,
    requireFilter: false,
    moreStyle: { width: 150 },
    isConditionCol: false,
    valueType: 'number',
    sum: true,
  },
  {
    id: 'totalDebt',
    display: true,
    align: 'center',
    disablePadding: false,
    label: "Nợ cảng",
    sort: false,
    requireFilter: false,
    moreStyle: { width: 150 },
    isConditionCol: false,
    valueType: 'number',
    sum: true,
  },
  {
    id: 'updatedBy',
    display: true,
    align: 'center',
    disablePadding: false,
    label: "Người cập nhật",
    sort: true,
    requireFilter: false,
    moreStyle: { width: 130 },
    isConditionCol: true,
    valueType: 'text'
  },
  {
    id: 'note',
    display: true,
    align: 'center',
    disablePadding: false,
    label: "Ghi chú",
    sort: false,
    requireFilter: false,
    moreStyle: { width: 250 },
    isConditionCol: true,
    valueType: 'text'
  },
];

const PortTableHead = (props) => {
  const { selected: bankSelected } = useSelector(selectBank);
  const filterCol = useSelector(selectColFilter("port-" + bankSelected?.bankId));
  const filterRow = useSelector(selectRowFilter("port-" + bankSelected?.bankId));

  const createSortHandler = (property) => (event) => {
    props.onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow className={clsx("sticky h-40 z-20", (filterRow ?? []).length > 0 ? "top-48" : "top-0")}>
        {colsPort.map((row, index, arr) => {
          if (!row.display) return null;
          if (filterCol != null && Object.keys(filterCol ?? {}).length > 0) {
            if (filterCol[row.id] !== true && row.requireFilter == false) return null;
          }
          return (
            <TableCell
              sx={{
                backgroundColor: (theme) => lighten(theme.palette.background.default, 0.4),
              }}
              className={clsx("py-4 px-0 m-0 font-semibold", row.className != null ? "z-50" : "", row.className)}
              key={row.id}
              style={row.moreStyle}
              sortDirection={props.order.id === row.id ? props.order.direction : false}
              scope='row'
            >
              <div style={{ ...row.moreStyle, textAlign: row.align }}>
                {row.sort ?
                  <Tooltip
                    title="Sắp xếp"
                    placement={row.align === 'right' ? 'bottom-end' : 'bottom-start'}
                    enterDelay={300}
                  >
                    <TableSortLabel
                      active={props.order.id === row.id}
                      direction={props.order.direction}
                      onClick={createSortHandler(row.id)}
                      hideSortIcon={true}
                    >
                      {row.label.split("").map((c, i) => c === "\n" ? <br key={i} /> : c)}
                      {!!row.sublabel && <><br />{row.sublabel}</>}
                    </TableSortLabel>
                  </Tooltip>
                  :
                  row.label.split("").map((c, i) => c === "\n" ? <br key={i} /> : c)
                }
              </div>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

export default PortTableHead;
