import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, IconButton, Popover } from '@mui/material';
import SvgIcon from '@adg/core/SvgIcon';
import StorageService from '@service/storageService';
import { selectStorages, setCurrentStorage, setStorages } from '../store/storageSlice';


export const SelectStorage = ({ bankId }) => {
    const storages = useSelector(selectStorages);
    const dispatch = useDispatch();

    const [popup, setPopup] = useState(false);

    const onOpen = (event) => {
        setPopup(event.currentTarget);
    };

    const onClose = () => {
        setPopup(null);
    };

    const selectOutOfTab = (tab) => {
        let currentValue = null;
        let tabs = _.merge([], storages.list);
        let tabsSelected = _.merge([], storages.selectedList);
        tabs.forEach((val) => {
            if (val.id === tab.id) {
                val.display = true;
                currentValue = val;
                tabsSelected.push(val);
            }
        });
        dispatch(setCurrentStorage({
            index: tabsSelected.length + 1,
            value: currentValue,
            list: tabs,
            selectedList: tabsSelected
        }));
    }

    if (storages.list.findIndex(val => !val.display && !val.port) < 0) return;

    return (
        <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}
        >
            <Button
                className='bg-gray-200 w-112 text-dark hover:text-white hover:bg-gray-800'
                variant="contained"
                color="inherit"
                startIcon={<SvgIcon>heroicons-outline:database</SvgIcon>}
                onClick={onOpen}
            >
                khác
            </Button>
            <Popover
                open={Boolean(popup)}
                anchorEl={popup}
                onClose={onClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                classes={{
                    paper: 'p-8',
                }}
            >
                {storages.list?.map(val => !val.display && !val.port && (
                    <Box key={val.id} sx={{ width: 175 }}>
                        <Button className='w-full'
                            onClick={() => {
                                selectOutOfTab({ label: val.name, id: val.id });
                                onClose();
                            }}
                        >
                            {val.name}
                        </Button>
                    </Box>
                ))}
            </Popover>
        </motion.div>
    )
}