import { ThemeProvider } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Hidden from '@mui/material/Hidden';
import Toolbar from '@mui/material/Toolbar';
import clsx from 'clsx';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentLayoutConfig, selectToolbarTheme } from 'app/store/adg/settingsSlice';
import { selectNavbar } from 'app/store/adg/navbarSlice';
import NavbarToggleButton from '../shared-components/NavbarToggleButton';
import UserMenu from '../shared-components/UserMenu';
import { Typography } from '@mui/material';
import { motion } from 'framer-motion';

function ToolbarLayout1(props) {
  const config = useSelector(selectCurrentLayoutConfig);
  const navbar = useSelector(selectNavbar);
  const toolbarTheme = useSelector(selectToolbarTheme);

  return (
    <ThemeProvider theme={toolbarTheme}>
      <AppBar
        id="adg-toolbar"
        className={clsx('flex relative z-20 shadow-md', props.className)}
        color="default"
        sx={{
          backgroundColor: (theme) => toolbarTheme.palette.background.paper,
        }}
        position="static"
      >
        <Toolbar className="p-0 min-h-48">
          <div className="flex flex-1 px-16 items-center">
            {config.navbar.display && (
              <>
                <Hidden lgDown>
                  {!navbar.open && (
                    <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />
                  )}
                </Hidden>

                <Hidden lgUp>
                  <NavbarToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
                </Hidden>
              </>
            )}

            <Typography
              component={motion.span}
              initial={{ x: -20 }}
              animate={{ x: 0, transition: { delay: 0.2 } }}
              delay={300}
              className="text-18 font-extrabold tracking-tight"
            >
              {config.title}
            </Typography>
          </div>

          <div className="flex items-center px-8 h-full overflow-x-auto">

            <UserMenu />
          </div>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}

export default memo(ToolbarLayout1);
