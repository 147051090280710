import SvgIcon from '@adg/core/SvgIcon';
import { Button, Typography } from '@mui/material';
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';

import { selectColFilter } from '../store/colFilterSlice';
import { selectRowFilter } from '../store/rowFilterSlice';
import StorageService from '@service/storageService';
import { colsPort } from '../tables/port/PortTableHead';
import { colsHistory } from '../tables/history/HistoryTableHead';
import { colsOther } from '../tables/other/OtherTableHead';
import { currentPortTableInfo } from '../tables/port';
import { currentHistoryTableInfo } from '../tables/history';
import { currentOtherTableInfo } from '../tables/other';
import moment from 'moment';
import OrderService from '@service/orderService';
import OrderTransactionService from '@service/orderTransactionService';
import { selectBank } from '../store/bankSlice';

export const ExportData = ({ id = "", rows = [] }) => {
    const dispatch = useDispatch();
    const filterCol = useSelector(selectColFilter(id));
    const filterRow = useSelector(selectRowFilter(id));
    const { selected: bankSelected } = useSelector(selectBank);

    const [waiting, setWaiting] = useState(false);

    const generateMessageFilter = (table = []) => {
        let messages = [];
        if (filterRow) {
            [...filterRow].forEach((val) => {
                let colName = table.find((r) => r.id == val.columnId).label;
                let values = val.condition.vals;
                if (val.columnType === "datetime") {
                    values = values.map(date => moment(date).format("DD/MM/YYYY"));
                } else if (val.columnType === "text") {
                    if (val.customList) {
                        values = values.map((key) => {
                            let newText = val.customList.filter(item => item.key == key)[0].name;
                            return `${newText}`;
                        });
                    } else {
                        values = values.map(text => `'${text}'`);
                    }
                } else if (val.columnType === "number") {
                    let unit = val.unit ? " " + val.unit : "";
                    values = values.map(num => `${num}${unit}`);
                }
                let mess = `${colName} ${val.condition.id} ${values.join(" và ")}`;
                messages.push(mess);
            });
        }
        return messages;
    }

    const onExportData = (event) => {
        let type = id.startsWith("port") ? "port" :
            id.startsWith("history") ? "history" : "storage";
        let table = id.startsWith("port") ? colsPort :
            id.startsWith("history") ? colsHistory :
                colsOther;
        let tableConfig = id.startsWith("port") ? currentPortTableInfo :
            id.startsWith("history") ? currentHistoryTableInfo :
                currentOtherTableInfo;
        let dataId = id.replace("port-", "").replace("history-", "");
        let filterParams = [];
        filterRow?.forEach(val => {
            filterParams.push({
                field: val.columnId,
                dataType: val.columnType == "text" ? "string" : val.columnType,
                operator: val.condition.operator,
                value: val.unit == "(Tấn)" ? val.condition.vals.map(v => v * 1000) : val.condition.vals
            });
        });
        let columns = [];
        table.forEach(val => {
            let addable = false;
            if (filterCol != null) {
                if (filterCol[val.id]) {
                    addable = true;
                }
            } else addable = true;
            if (val.requireFilter || !val.display) {
                addable = true;
            }
            if (addable && val.label != "") {
                columns.push({
                    name: val.id,
                    alias: val.label.replace("\n", " ") + "\n" + (val.sublabel ?? ""),
                    type: val.sublabel == "(Tấn)" ? "WEIGHT" :
                        ((val.valueType == "datetime" && type !== "port") ? "DATE" :
                            "TEXT"),
                    sum: val.sum == true,
                })
            }
        });
        let body = {
            sortField: tableConfig.id,
            sortDirection: tableConfig.direction?.toUpperCase(),
            filters: {
                and: filterParams
            },
            pageIndex: 0,
            pageSize: 9999999,
            columns: columns,
            uiFilter: generateMessageFilter(table)
        }
        setWaiting(true);
        switch (type) {
            case "port":
                body.portId = bankSelected.storageOfBank.id;
                OrderService.exportData(body, dispatch).then(({ data, headers }) => {
                    let blob = new Blob([data], { type: headers["content-type"] });
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = 'Báo cáo cảng ' + moment().format("YYYY-MM-DD HH-mm") + '.xlsx';
                    link.click();
                    setWaiting(false);
                });
                break;
            case "history":
                body.bankId = bankSelected.bankId;
                OrderTransactionService.exportData(bankSelected.bankId, body, dispatch).then(({ data, headers }) => {
                    let blob = new Blob([data], { type: headers["content-type"] });
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = 'Báo cáo lịch sử ' + moment().format("YYYY-MM-DD HH-mm") + '.xlsx';
                    link.click();
                    setWaiting(false);
                })
                break;
            case "storage":
                body.storageId = dataId;
                StorageService.exportData(body.storageId, body, dispatch).then(({ data, headers }) => {
                    let blob = new Blob([data], { type: headers["content-type"] });
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = 'Báo cáo kho ' + moment().format("YYYY-MM-DD HH-mm") + '.xlsx';
                    link.click();
                    setWaiting(false);
                });
                break;
            default:
                setWaiting(false);
                break;
        }

    };

    return (
        <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}
        >
            <Button
                className='bg-gray-200 w-112 text-dark hover:text-white hover:bg-gray-800'
                variant="contained"
                color="inherit"
                onClick={onExportData}
                disabled={waiting}
            >
                <SvgIcon>heroicons-outline:save</SvgIcon>
                <Typography ml={1}>
                    Excel
                </Typography>
            </Button>
        </motion.div>
    )
}