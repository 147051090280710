import { createSlice } from '@reduxjs/toolkit';

const defaultFilterPort = [{
    columnId: "status",
    columnType: "text",
    condition: {
        id: "là",
        numVal: 1,
        operator: "equal",
        vals: ['IN_PROGRESS'],
    },
    customList: [
        { name: 'Đang thực hiện', key: 'IN_PROGRESS', iconClassName: 'bg-blue' },
        { name: 'Đã hoàn thành', key: 'COMPLETED', iconClassName: 'bg-green' },
        { name: 'Đã hủy', key: 'CANCELED', iconClassName: 'bg-red' },
    ],
    unit: null
}];
const defaultFilterHistory = [];

const rowFilterSlice = createSlice({
    name: 'homeDashboard/rowFilter',
    initialState: {
    },
    reducers: {
        setRowFilter: (state, action) => {
            let id = action.payload.id
            state[id] = action.payload.list;
        },
        initFilterWithBank: (state, action) => {
            action.payload.forEach(id => {
                state["port-" + id] = defaultFilterPort;
                state["history-" + id] = defaultFilterHistory;
            })
        }
    },
});

export const setRowFilter = rowFilterSlice.actions.setRowFilter;
export const initFilterWithBank = rowFilterSlice.actions.initFilterWithBank;

export const selectRowFilter = (id) => ({ homeDashboard }) => homeDashboard.rowFilter[id];

export default rowFilterSlice.reducer;
